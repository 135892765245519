import React, { useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { X } from "lucide-react";
import OutsideGallary from "./OutsideGallary";
import SurroundingGallary from "./SurroundingGallary";

function OutSurrGallarySlider({
  isOutSurrGallaryOpen,
  id,
  selectedOutSurr,
  toggleOutSurrGallary,
}) {
  useEffect(() => {
    if (isOutSurrGallaryOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [isOutSurrGallaryOpen]);

  return (
    <>
      <AnimatePresence>
        {isOutSurrGallaryOpen && (
          <>
            {/* Backdrop */}
            <motion.div
              className="fixed hidden lg:block h-full w-full bg-black bg-opacity-50 top-0 left-0"
              onClick={toggleOutSurrGallary}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.4 }}
            />

            {/* Large Screen Animation */}
            <motion.div
              className="fixed hidden lg:block bottom-0 w-[90%] right-0 h-full bg-backgroundColor dark:bg-backgroundDark border-l border-gray-200 dark:border-gray-700 z-30 overflow-y-auto no-scrollbar shadow-2xl"
              role="dialog"
              aria-labelledby={
                selectedOutSurr === "outside"
                  ? "outside-gallary"
                  : "surrounding-gallary"
              }
              aria-modal="true"
              initial={{ x: "100%", opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: "100%", opacity: 0 }}
              transition={{ duration: 0.55, ease: "easeInOut" }}
            >
              <div className="fixed w-[90%] top-0 right-0 p-4 bg-backgroundColor dark:bg-backgroundDark border-b border-l border-gray-200 dark:border-gray-700">
                <div className="flex justify-end">
                  <button
                    onClick={toggleOutSurrGallary}
                    className="text-gray-600 dark:text-gray-400 hover:text-gray-800 dark:hover:text-gray-200 focus:outline-none"
                    aria-label="Close gallery"
                  >
                    <X className="w-6 h-6" />
                  </button>
                </div>
              </div>

              <div>
                {selectedOutSurr === "outside" ? (
                  <OutsideGallary postId={id} />
                ) : (
                  <SurroundingGallary postId={id} />
                )}
              </div>
            </motion.div>

            {/* Small Screen Animation */}
            <motion.div
              className="fixed block lg:hidden bottom-0 w-full lg:w-[90%] right-0 h-full bg-backgroundColor dark:bg-backgroundDark z-30 overflow-y-auto no-scrollbar shadow-2xl"
              role="dialog"
              aria-labelledby={
                selectedOutSurr === "outside"
                  ? "outside-gallary"
                  : "surrounding-gallary"
              }
              aria-modal="true"
              initial={{ y: "100%", opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: "100%", opacity: 0 }}
              transition={{ duration: 0.6, ease: "easeInOut" }}
            >
              <div className="fixed w-full lg:w-[90%] top-0 right-0 p-4 bg-backgroundColor dark:bg-backgroundDark border-b border-gray-200 dark:border-gray-700">
                <div className="flex justify-end">
                  <button
                    onClick={toggleOutSurrGallary}
                    className="text-gray-600 dark:text-gray-400 hover:text-gray-800 dark:hover:text-gray-200 focus:outline-none"
                    aria-label="Close gallery"
                  >
                    <X className="w-6 h-6" />
                  </button>
                </div>
              </div>

              <div>
                {selectedOutSurr === "outside" ? (
                  <OutsideGallary postId={id} />
                ) : (
                  <SurroundingGallary postId={id} />
                )}
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </>
  );
}

export default OutSurrGallarySlider;
