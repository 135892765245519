import { useCallback, useEffect, useRef, useState } from "react";
import DatePicker from "react-tailwindcss-datepicker";
import { ChevronDown, ChevronUp, X } from "lucide-react";
import { getLocalizedText } from "../../../hooks/langSeparator";

function ContactReservation({
  post,
  handleContactClick,
  selectedRooms,
  setSelectedRooms,
  removeRoom,
  t,
  language,
  currency,
  navigate,
  id,
}) {
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const [guests, setGuests] = useState({
    adults: 1,
    children: 0,
    infants: 0,
    pets: 0,
  });
  const [showGuests, setShowGuests] = useState(false);
  const [activeGuestRoomId, setActiveGuestRoomId] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [disabledDates, setDisabledDates] = useState([]);
  const dropdownRef = useRef(null);

  const HotelAndGuestHouses =
    post.category === "Hotels" || post.category === "Guest House";

  const BookingProperty = post.purchaseType === "Booking";

  const formatDate = (date) => {
    return (
      date &&
      new Date(date).toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
      })
    );
  };

  const handleGuestToggle = () => {
    setShowGuests((prev) => !prev);
  };

  const getBookingParamsFromURL = useCallback(() => {
    const params = new URLSearchParams(window.location.search);
    const startDate = params.get("startDate") || null;
    const endDate = params.get("endDate") || null;
    const adults = parseInt(params.get("adults"), 10) || 1;
    const children = parseInt(params.get("children"), 10) || 0;
    const infants = parseInt(params.get("infants"), 10) || 0;
    const pets = parseInt(params.get("pets"), 10) || 0;

    return {
      dateRange: { startDate, endDate },
      guests: { adults, children, infants, pets },
    };
  }, []);

  useEffect(() => {
    const { dateRange, guests } = getBookingParamsFromURL();
    setDateRange(dateRange);
    setGuests(guests);
  }, [getBookingParamsFromURL]);

  const updateURLParams = (newParams) => {
    const updatedParams = new URLSearchParams(window.location.search);

    Object.entries(newParams).forEach(([key, value]) => {
      if (value !== null) {
        updatedParams.set(key, value);
      } else {
        updatedParams.delete(key);
      }
    });

    window.history.replaceState(
      null,
      "",
      `${window.location.pathname}?${updatedParams.toString()}`
    );
  };

  const handleGuestChange = (type, operation) => {
    setGuests((prevGuests) => {
      const updatedGuests = {
        ...prevGuests,
        [type]: Math.max(
          type === "adults" ? 1 : 0,
          prevGuests[type] + operation
        ),
      };
      updateURLParams(updatedGuests); // Update URL immediately
      return updatedGuests;
    });
  };

  const handleHGHGuestChange = (type, operation, roomId) => {
    setSelectedRooms((prevSelectedRooms) => {
      const updatedRooms = prevSelectedRooms.map((room) =>
        room.roomId === roomId
          ? {
              ...room,
              guests: {
                ...room.guests,
                [type]: Math.max(
                  type === "adults" ? 1 : 0,
                  room.guests[type] + operation
                ),
              },
            }
          : room
      );

      return updatedRooms;
    });
  };

  const handleDateChange = (newDateRange) => {
    const { startDate, endDate } = newDateRange;
    if (startDate && endDate && endDate <= startDate) {
      const adjustedEndDate = new Date(startDate);
      adjustedEndDate.setDate(adjustedEndDate.getDate() + 1);
      setDateRange({ startDate: startDate, endDate: adjustedEndDate });
      updateURLParams({
        startDate: startDate
          ? new Date(startDate).toISOString().split("T")[0]
          : null,
        endDate: adjustedEndDate
          ? new Date(adjustedEndDate).toISOString().split("T")[0]
          : null,
      });
    } else {
      setDateRange({ startDate: startDate, endDate: endDate });
      updateURLParams({
        startDate: startDate
          ? new Date(startDate).toISOString().split("T")[0]
          : null,
        endDate: endDate ? new Date(endDate).toISOString().split("T")[0] : null,
      });
      setErrorMessage("");
    }
  };

  const calculateNights = () => {
    if (dateRange.startDate && dateRange.endDate) {
      const start = new Date(dateRange.startDate);
      const end = new Date(dateRange.endDate);
      const diffInTime = end.getTime() - start.getTime();
      return Math.ceil(diffInTime / (1000 * 3600 * 24));
    }
    return 0;
  };

  const calculateMinDays = () => {
    let minDays = 0;

    if (HotelAndGuestHouses) {
      minDays = Math.max(...selectedRooms.map((room) => room.roomMinDays || 0));
    } else if (BookingProperty) {
      minDays = post.bookingMinDays || 0;
    }

    return minDays;
  };

  const nights = calculateNights();
  const minDays = calculateMinDays();

  useEffect(() => {
    const generateDisabledDates = () => {
      if (!dateRange.startDate) return;

      const minDaysToAdd = calculateMinDays();
      const disabled = [];

      for (let i = 1; i < minDaysToAdd; i++) {
        const date = new Date(dateRange.startDate);
        date.setDate(date.getDate() + i);
        disabled.push(date);
      }

      setDisabledDates(disabled);
    };

    generateDisabledDates();
  }, [dateRange.startDate, setDisabledDates]);

  const guestSummary = (guests) => {
    return guests.adults === 1 &&
      guests.children === 0 &&
      guests.infants === 0 &&
      guests.pets === 0
      ? "1 Guest"
      : `${guests.adults} Adult${guests.adults > 1 ? "s" : ""} ${
          guests.children
            ? `, ${guests.children} Child${guests.children > 1 ? "ren" : ""}`
            : ""
        }${
          guests.infants
            ? `, ${guests.infants} Infant${guests.infants > 1 ? "s" : ""}`
            : ""
        }${
          guests.pets ? `, ${guests.pets} Pet${guests.pets > 1 ? "s" : ""}` : ""
        }`;
  };

  const toggleDropdown = (roomId) => {
    setActiveGuestRoomId((prev) => (prev === roomId ? null : roomId));
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setActiveGuestRoomId(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const gotoContactReservation = () => {
    const contactReservationSection = document.getElementById(
      "Contact-Reservation"
    );
    if (contactReservationSection) {
      const yOffset = -100;
      const y =
        contactReservationSection.getBoundingClientRect().top +
        window.scrollY +
        yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };

  const reserveCheck = (e) => {
    gotoContactReservation();
    handleBookingSubmit(e);
  };

  const handleClearDates = () => {
    setDateRange({ startDate: null, endDate: null });
    setErrorMessage("");
    updateURLParams({ startDate: null, endDate: null });
  };

  const totalPrice = HotelAndGuestHouses
    ? selectedRooms.reduce((total, room) => {
        const roomTotal =
          currency === "USD"
            ? room.roomPriceExchanged * nights
            : room.roomPrice * nights;
        return total + roomTotal;
      }, 0)
    : currency === "USD"
    ? post.bookingPriceExchanged * nights
    : post.bookingPrice * nights;

  const handleBookingSubmit = (e) => {
    e.preventDefault();

    if (HotelAndGuestHouses) {
      if (!selectedRooms || selectedRooms.length === 0) {
        setErrorMessage("Please select at least one room.");

        const roomsSection = document.getElementById("rooms");
        if (roomsSection) {
          const yOffset = -100;
          const y =
            roomsSection.getBoundingClientRect().top + window.scrollY + yOffset;
          window.scrollTo({ top: y, behavior: "smooth" });
        }
        return;
      }

      if (!dateRange.startDate || !dateRange.endDate) {
        setErrorMessage("Please select both check-in and check-out dates.");
        return;
      }

      const bookedRooms = selectedRooms.map((room) => ({
        roomId: room.roomId,
        roomPrice: room.roomPrice,
        roomPriceExchanged: room.roomPriceExchanged,
        roomType: room.roomType,
        guests: room.guests,
      }));

      const bookingData = {
        bookedRooms,
        checkIn: dateRange.startDate,
        checkOut: dateRange.endDate,
        postId: id,
        currency: currency,
      };

      const queryParams = new URLSearchParams({
        checkIn: dateRange.startDate,
        checkOut: dateRange.endDate,
        postId: id,
        currency: currency,
        bookedRooms: JSON.stringify(bookedRooms),
      });

      navigate(`/post/booking?${queryParams.toString()}`, {
        state: bookingData,
      });
    } else {
      if (!dateRange.startDate || !dateRange.endDate) {
        setErrorMessage("Please select both check-in and check-out dates.");
        return;
      }

      const bookingData = {
        checkIn: dateRange.startDate,
        checkOut: dateRange.endDate,
        guests: guests,
        postId: id,
        currency: currency,
        bookingPrice: post.bookingPrice,
        bookingPriceExchanged: post.bookingPriceExchanged,
      };

      const queryParams = new URLSearchParams({
        checkIn: dateRange.startDate,
        checkOut: dateRange.endDate,
        guests: JSON.stringify(guests),
        postId: id,
        currency: currency,
        bookingPrice: post.bookingPrice,
        bookingPriceExchanged: post.bookingPriceExchanged,
      });

      navigate(`/post/booking?${queryParams.toString()}`, {
        state: bookingData,
      });
    }
  };

  return (
    <div
      id="Contact-Reservation"
      className="relative md:sticky md:top-24 md:mb-0 mb-10 w-full h-fit md:w-[35%] shadow-lg p-4 rounded-2xl flex flex-col justify-center items-center border dark:border-gray-700"
    >
      {HotelAndGuestHouses || BookingProperty ? (
        <>
          <h3 className="text-lg mb-5 font-bold text-black dark:text-gray-300">
            Book a Reservation
          </h3>

          {/* Check-in and Check-out date selector */}
          <div className="flex justify-start items-center w-full text-black dark:text-gray-300 text-sm mb-1 px-2">
            Add dates
          </div>
          <div className="w-full mb-4" aria-live="polite">
            <label
              htmlFor="datepicker"
              className="w-full flex justify-evenly p-2 border border-gray-300 rounded-lg bg-backgroundColor dark:bg-backgroundDark dark:border-gray-700 text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700 cursor-pointer transition-colors duration-200"
              aria-expanded="true"
            >
              <div className="text-center">
                <div
                  className={`${
                    !dateRange.startDate ? "text-lg font-medium" : "text-sm"
                  } text-black dark:text-white`}
                >
                  Check-in
                </div>
                {dateRange.startDate && (
                  <div className="text-gray-600 dark:text-gray-400">
                    {formatDate(dateRange.startDate)}
                  </div>
                )}
              </div>
              <div className="border border-gray-400" />
              <div className="text-center">
                <div
                  className={`${
                    !dateRange.endDate ? "text-lg font-medium" : "text-sm"
                  } text-black dark:text-white`}
                >
                  Check-out
                </div>
                {dateRange.endDate && (
                  <div className="text-gray-600 dark:text-gray-400">
                    {formatDate(dateRange.endDate)}
                  </div>
                )}
              </div>
            </label>

            <DatePicker
              required
              inputId="datepicker"
              inputName="datepicker"
              primaryColor="sky"
              startWeekOn="mon"
              popoverDirection="down"
              value={dateRange}
              onChange={handleDateChange}
              inputClassName="h-0"
              containerClassName="font-semibold"
              toggleClassName="hidden"
              placeholder="Check-in - Check-out"
              minDate={new Date()}
              disabledDates={disabledDates}
            />

            <div
              className={`px-3 font-bold text-lg flex justify-between ${
                dateRange.startDate && dateRange.endDate && nights > 0
                  ? "block"
                  : "hidden"
              }`}
            >
              {dateRange.startDate && dateRange.endDate && nights > 0 && (
                <div className="text-gray-700 dark:text-gray-300">
                  {nights} night{nights > 1 ? "s" : ""}
                </div>
              )}
              <div
                onClick={handleClearDates}
                className="text-black hover:text-gray-600 dark:text-white hover:dark:text-gray-300 cursor-pointer"
              >
                <X />
              </div>
            </div>
          </div>

          {BookingProperty && (
            <>
              {/* Guests */}
              <div
                onClick={handleGuestToggle}
                className="w-full mb-2 flex justify-between items-center bg-backgroundColor text-black border border-gray-300 p-3 rounded-lg dark:bg-backgroundDark dark:text-white dark:border-gray-600 hover:bg-gray-50 dark:hover:bg-gray-700 cursor-pointer transition-colors duration-200"
                aria-expanded={showGuests}
              >
                <span className="font-medium">Guests</span>
                <span className="text-sm">{guestSummary(guests)}</span>
                {showGuests ? (
                  <ChevronUp className="w-5 h-5 text-gray-700 dark:text-gray-300" />
                ) : (
                  <ChevronDown className="w-5 h-5 text-gray-700 dark:text-gray-300" />
                )}
              </div>

              {/* Guests selection section */}
              <div
                className={`transition-all duration-300 ease-in-out w-full px-10 ${
                  showGuests ? "max-h-60" : "max-h-0 overflow-hidden"
                }`}
                aria-hidden={!showGuests}
              >
                <div className="flex flex-col space-y-2">
                  {["adults", "children", "infants", "pets"].map((type) => {
                    const isPetAllowed =
                      type === "pets" && post.bookingPetsAllowed === "No";

                    return (
                      <div
                        key={type}
                        className="flex justify-between items-center p-2 border border-gray-300 dark:border-gray-600 rounded-lg"
                      >
                        <span
                          className={`font-medium ${
                            isPetAllowed
                              ? "text-gray-400 dark:text-gray-500"
                              : "text-gray-700 dark:text-gray-300"
                          }`}
                        >
                          {type.charAt(0).toUpperCase() + type.slice(1)}
                          {isPetAllowed && (
                            <span className="text-xs ml-2">(Not Allowed)</span>
                          )}
                        </span>
                        <div className="flex items-center">
                          <button
                            type="button"
                            onClick={() => handleGuestChange(type, -1)}
                            className="w-8 h-8 border border-mainColor text-black dark:text-white rounded-full flex items-center justify-center hover:border-hovermainColor disabled:border-gray-400 disabled:text-gray-400 disabled:dark:border-gray-600 disabled:dark:text-gray-600"
                            disabled={
                              type === "adults"
                                ? guests[type] <= 1
                                : guests[type] === 0 || isPetAllowed
                            }
                          >
                            -
                          </button>
                          <span
                            className={`w-8 flex text-center justify-center ${
                              isPetAllowed
                                ? "text-gray-400 dark:text-gray-500"
                                : "text-gray-700 dark:text-gray-300"
                            }`}
                          >
                            {guests[type]}
                          </span>
                          <button
                            type="button"
                            onClick={() => handleGuestChange(type, 1)}
                            className="w-8 h-8 border border-mainColor text-black dark:text-white rounded-full flex items-center justify-center hover:border-hovermainColor disabled:border-gray-400 disabled:text-gray-400 disabled:dark:border-gray-600 disabled:dark:text-gray-600"
                            disabled={
                              isPetAllowed ||
                              (type !== "infants" &&
                                guests.adults + guests.children >=
                                  post.bookingMaxGuests)
                            }
                          >
                            +
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              {/* price */}
              {totalPrice > 0 && (
                <div className="w-full py-2">
                  <div className="flex justify-between items-center mt-4 text-sm text-gray-800 dark:text-gray-300 border-b border-gray-300 dark:border-gray-700 pb-1 px-4">
                    <div>
                      {currency === "USD"
                        ? new Intl.NumberFormat().format(
                            post.bookingPriceExchanged
                          )
                        : new Intl.NumberFormat().format(
                            post.bookingPrice
                          )}{" "}
                      {currency === "USD" ? "$" : t("birr")} X {nights} nights
                    </div>
                    <div>
                      {new Intl.NumberFormat().format(totalPrice)}{" "}
                      {currency === "USD" ? "$" : t("birr")}
                    </div>
                  </div>
                  <div className="flex justify-end px-4 mt-4 text-gray-800 dark:text-gray-200 space-x-2">
                    <strong>Total Price: </strong>
                    <span>
                      {new Intl.NumberFormat().format(totalPrice)}{" "}
                      {currency === "USD" ? "$" : t("birr")}
                    </span>
                  </div>
                  <div className="flex justify-end text-xs px-4 text-gray-800 dark:text-gray-200">
                    Includes tax and service fee
                  </div>
                </div>
              )}
            </>
          )}

          {HotelAndGuestHouses && (
            <>
              {selectedRooms.length > 0 ? (
                <div className="w-full">
                  <div>
                    <h2 className="flex justify-start items-center w-full text-black dark:text-gray-300 border-b border-gray-300 dark:border-gray-600 pt-4 pb-2 text-sm px-2">
                      Selected Room{selectedRooms.length > 1 ? "s" : ""}
                    </h2>
                  </div>
                  {selectedRooms.map((room, index) => {
                    const getRoomType = () =>
                      getLocalizedText(room.roomType, language);

                    return (
                      <div
                        key={index}
                        className="relative flex w-full justify-between items-center py-2 transition-all duration-200 border-b border-gray-300 dark:border-gray-600"
                      >
                        <h2 className="text-lg font-normal text-gray-800 dark:text-white">
                          {getRoomType()}
                        </h2>

                        <div
                          onClick={() => toggleDropdown(room.roomId)}
                          className="text-gray-700 dark:text-gray-300 cursor-pointer"
                        >
                          {guestSummary(room.guests)}
                        </div>

                        {activeGuestRoomId === room.roomId && (
                          <div
                            ref={dropdownRef}
                            className="absolute top-9 right-0 w-full bg-white dark:bg-gray-800 mt-2 border border-gray-400 dark:border-gray-500 rounded-alg p-4 z-20 shadow-2xl"
                          >
                            {["adults", "children", "infants", "pets"].map(
                              (type) => {
                                const isPetsDisabled =
                                  type === "pets" &&
                                  room.roomPetsAllowed === "No";
                                const maxGuestsReached =
                                  type !== "infants" &&
                                  room.guests.adults + room.guests.children >=
                                    parseInt(room.roomMaxGuests);

                                return (
                                  <div
                                    key={type}
                                    className="flex justify-between items-center p-2 border-b border-gray-300 dark:border-gray-600 last:border-b-0"
                                  >
                                    <span
                                      className={`font-medium ${
                                        isPetsDisabled
                                          ? "text-gray-400 dark:text-gray-500"
                                          : "text-gray-700 dark:text-gray-300"
                                      }`}
                                    >
                                      {type.charAt(0).toUpperCase() +
                                        type.slice(1)}
                                      {isPetsDisabled && (
                                        <span className="ml-2 text-sm text-gray-500">
                                          (Not allowed)
                                        </span>
                                      )}
                                    </span>
                                    <div className="flex items-center">
                                      <button
                                        type="button"
                                        onClick={() =>
                                          handleHGHGuestChange(
                                            type,
                                            -1,
                                            room.roomId
                                          )
                                        }
                                        className="w-8 h-8 border border-mainColor text-black dark:text-white rounded-full flex items-center justify-center hover:border-hovermainColor disabled:border-gray-400 disabled:text-gray-400 disabled:dark:border-gray-600 disabled:dark:text-gray-600"
                                        disabled={
                                          type === "adults"
                                            ? room.guests[type] <= 1
                                            : room.guests[type] === 0 ||
                                              isPetsDisabled
                                        }
                                      >
                                        -
                                      </button>
                                      <span className="w-8 text-gray-700 dark:text-gray-300 flex text-center justify-center">
                                        {room.guests[type]}
                                      </span>
                                      <button
                                        type="button"
                                        onClick={() =>
                                          handleHGHGuestChange(
                                            type,
                                            1,
                                            room.roomId
                                          )
                                        }
                                        className="w-8 h-8 border border-mainColor text-black dark:text-white rounded-full flex items-center justify-center hover:border-hovermainColor disabled:border-gray-400 disabled:text-gray-400 disabled:dark:border-gray-600 disabled:dark:text-gray-600"
                                        disabled={
                                          maxGuestsReached || isPetsDisabled
                                        }
                                      >
                                        +
                                      </button>
                                    </div>
                                  </div>
                                );
                              }
                            )}

                            <div className="flex justify-end py-1">
                              <div
                                onClick={() => toggleDropdown(room.roomId)}
                                className="flex justify-end text-black dark:text-white p-2 rounded-xl cursor-pointer"
                              >
                                Done
                              </div>
                            </div>
                          </div>
                        )}

                        <button
                          onClick={() => removeRoom(room.roomId)}
                          className="px-3 py-1 text-gray-700 dark:text-gray-500 rounded transition-all duration-200"
                        >
                          <X size={18} />
                        </button>
                      </div>
                    );
                  })}

                  <div>
                    {selectedRooms.map((room, index) => {
                      return (
                        <div
                          key={index}
                          className="flex justify-between items-center mt-4 text-sm text-gray-800 dark:text-gray-300 px-4"
                        >
                          <div>
                            {currency === "USD"
                              ? new Intl.NumberFormat().format(
                                  room.roomPriceExchanged
                                ) || room.roomPrice
                              : new Intl.NumberFormat().format(
                                  room.roomPrice
                                )}{" "}
                            {currency === "USD" ? "$" : t("birr")} X {nights}{" "}
                            nights
                          </div>
                          <div className="underline">
                            {currency === "USD"
                              ? new Intl.NumberFormat().format(
                                  (room.roomPriceExchanged * nights).toFixed(2)
                                ) || (room.roomPrice * nights).toFixed(2)
                              : new Intl.NumberFormat().format(
                                  (room.roomPrice * nights).toFixed(2)
                                )}{" "}
                            {currency === "USD" ? "$" : t("birr")}
                          </div>
                        </div>
                      );
                    })}

                    <div className="flex justify-end px-4 mt-4 text-gray-800 dark:text-gray-200 space-x-2">
                      <strong>Total Price: </strong>
                      <span>
                        {new Intl.NumberFormat().format(totalPrice)}{" "}
                        {currency === "USD" ? "$" : t("birr")}
                      </span>
                    </div>
                    <div className="flex justify-end text-xs text-gray-800 dark:text-gray-200">
                      Includes tax and service fee
                    </div>
                  </div>
                </div>
              ) : (
                <div className="w-full">
                  <div>
                    <h2 className="flex justify-center italic items-center w-full text-black dark:text-gray-300 pb-2 text-sm">
                      Select a Room to book
                    </h2>
                  </div>
                </div>
              )}
            </>
          )}

          {errorMessage && (
            <p className="text-red-500 text-sm mt-2">{errorMessage}</p>
          )}

          <button
            onClick={handleBookingSubmit}
            disabled={totalPrice === 0}
            className="w-full bg-mainColor text-white p-2 rounded-lg hover:bg-hovermainColor mt-4 disabled:bg-gray-400 dark:disabled:bg-gray-600 disabled:text-gray-100"
          >
            Reserve Now
          </button>

          {/* Mobile Bottom Navigation Bar */}
          {(selectedRooms.length > 0 || BookingProperty) && (
            <div className="fixed lg:hidden flex justify-between items-center bottom-[58px] left-0 w-[100%] bg-gray-200 dark:bg-gray-700 p-4 text-center h-16 z-50 border-y border-gray-300 dark:border-gray-600">
              {HotelAndGuestHouses && (
                <div
                  onClick={() => gotoContactReservation()}
                  className="ml-2 flex space-x-2 items-center"
                >
                  <div className="flex space-x-2 text-black dark:text-white">
                    <p className="text-3xl font-bold">{selectedRooms.length}</p>
                    <div className="flex flex-col text-sm font-semibold">
                      <div>
                        <p>Room{selectedRooms.length > 1 && "s"}</p>
                      </div>
                      <p>Selected</p>
                    </div>
                  </div>

                  {totalPrice > 0 && (
                    <div className="flex flex-col px-2 items-start border-l-2 border-gray-400 dark:border-gray-500">
                      <p className="text-gray-700 dark:text-gray-300 text-sm font-medium">
                        Total:
                      </p>
                      <p className="text-black dark:text-white text-lg font-semibold">
                        {new Intl.NumberFormat().format(totalPrice)}{" "}
                        {currency === "USD" ? "$" : t("birr")}
                      </p>
                    </div>
                  )}
                </div>
              )}

              {BookingProperty && (
                <div
                  onClick={() => gotoContactReservation()}
                  className="relative overflow-hidden"
                >
                  {totalPrice > 0 ? (
                    <div className="ml-2 flex flex-col items-start">
                      <p className="text-gray-700 dark:text-gray-300 text-sm font-medium">
                        Total:
                      </p>
                      <p className="text-black dark:text-white text-lg font-semibold">
                        {new Intl.NumberFormat().format(totalPrice)}{" "}
                        {currency === "USD" ? "$" : t("birr")}
                      </p>
                    </div>
                  ) : (
                    <p className="text-gray-500 dark:text-gray-300 text-lg font-medium">
                      Add Dates
                    </p>
                  )}
                </div>
              )}

              <button
                onClick={reserveCheck}
                className="p-3 bg-mainColor text-white rounded-xl flex justify-center items-center"
              >
                {BookingProperty &&
                  (totalPrice > 0 ? "Reserve Now" : "Check Availability")}
                {HotelAndGuestHouses && "Reserve Now"}
              </button>
            </div>
          )}
        </>
      ) : (
        <>
          <h3 className="text-lg mb-5 font-medium text-black dark:text-gray-300">
            Interested in this property?
          </h3>
          <button
            onClick={handleContactClick}
            className="w-full bg-mainColor text-white p-2 rounded-lg hover:bg-hovermainColor"
          >
            Contact Us
          </button>
        </>
      )}
    </div>
  );
}

export default ContactReservation;
