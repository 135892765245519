import React, { useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Sidebar from "./components/common/Sidebar";
import OverviewPage from "./pages/OverviewPage";
import PostsPage from "./pages/PostsPage";
import UsersPage from "./pages/UsersPage";
import SalesPage from "./pages/SalesPage";
import AddAdminPage from "./pages/AddAdminPage";
import { useAuth } from "../context/AuthContext";
import CreatePostPage from "./pages/CreatePostPage";
import RequestsPage from "./pages/RequestsPage";
import MessagesPage from "./pages/MessagesPage";
import ChatSpace from "./components/messages/ChatSpace";
import ContactsPage from "./pages/ContactsPage";
import ContactsChatSpace from "./components/contacts/ContactsChatSpace";
import EmployeeOverview from "./pages/EmployeeOverview";
import AdminNotifications from "./components/common/AdminNotifications";
import Bookings from "./pages/BookingsPage";

function Admin() {
  const { currentUser, role, firstName, lastName } = useAuth();
  const [unreadCounts, setUnreadCounts] = useState({
    conversations: 0,
    contacts: 0,
    requests: 0,
  });

  const isAuthorized =
    !!currentUser && (role === "employee" || role === "manager");

  return (
    <div className="flex h-screen bg-gray-900 text-gray-100 overflow-hidden">
      <div className="fixed inset-0 z-0">
        <div className="absolute inset-0 bg-gray-800 opacity-80" />
        <div className="absolute inset-0 backdrop-blur-sm" />
      </div>

      <AdminNotifications setUnreadCountsApp={setUnreadCounts} />
      {isAuthorized && <Sidebar role={role} unreadCounts={unreadCounts} />}

      <Routes>
        <Route
          path="/"
          element={
            isAuthorized && role === "manager" ? (
              <OverviewPage />
            ) : isAuthorized && role === "employee" ? (
              <EmployeeOverview
                firstName={firstName}
                unreadCounts={unreadCounts}
              />
            ) : (
              <Navigate to="/error-page" replace />
            )
          }
        />
        <Route
          path="posts"
          element={
            isAuthorized && role === "manager" ? (
              <PostsPage />
            ) : (
              <Navigate to="/error-page" replace />
            )
          }
        />
        <Route
          path="create-post"
          element={
            isAuthorized &&
            role === "manager" && (
              <CreatePostPage
                currentUser={currentUser}
                firstName={firstName}
                lastName={lastName}
              />
            )
          }
        />
        <Route
          path="users"
          element={
            isAuthorized && role === "manager" ? (
              <UsersPage role={role} />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          path="sales"
          element={
            isAuthorized ? <SalesPage /> : <Navigate to="/error-page" replace />
          }
        />
        <Route
          path="requests"
          element={
            isAuthorized ? (
              <RequestsPage />
            ) : (
              <Navigate to="/error-page" replace />
            )
          }
        />
        <Route
          path="messages"
          element={
            isAuthorized ? (
              <MessagesPage />
            ) : (
              <Navigate to="/error-page" replace />
            )
          }
        />
        <Route
          path="contact"
          element={
            isAuthorized ? (
              <ContactsPage />
            ) : (
              <Navigate to="/error-page" replace />
            )
          }
        />
        <Route
          path="messages/:chatId"
          element={
            isAuthorized ? (
              <ChatSpace
                currentUser={currentUser}
                firstName={firstName}
                lastName={lastName}
              />
            ) : (
              <Navigate to="/error-page" replace />
            )
          }
        />
        <Route
          path="contact/:uid"
          element={
            isAuthorized ? (
              <ContactsChatSpace
                currentUser={currentUser}
                firstName={firstName}
                lastName={lastName}
              />
            ) : (
              <Navigate to="/error-page" replace />
            )
          }
        />
        <Route
          path="Bookings"
          element={
            isAuthorized ? <Bookings /> : <Navigate to="/error-page" replace />
          }
        />
        <Route
          path="/*"
          element={!isAuthorized && <Navigate to="/error-page" replace />}
        />
      </Routes>
    </div>
  );
}

export default Admin;
