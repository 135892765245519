import React from "react";

function ETBCheckout() {
  return (
    <div className="flex flex-col items-center justify-center h-fit lg:py-32 py-32 px-6 sm:px-10 bg-transparent font-Quicksand select-none">
      <h1 className="text-3xl sm:text-4xl font-bold text-gray-800 dark:text-white mb-4 text-center">
        Complete Your Payment
      </h1>
      <p className="text-base sm:text-lg text-gray-600 dark:text-gray-300 mb-6 text-center">
        To proceed with your booking, please contact the provided phone number
        to make your payment via ETB. Our team is ready to assist you.
      </p>
      <div className="bg-gray-200 dark:bg-gray-900 p-6 rounded-lg shadow-lg text-center">
        <h2 className="text-lg sm:text-xl font-semibold text-gray-700 dark:text-gray-200 mb-2">
          Contact Phone Number:
        </h2>
        <p className="text-2xl sm:text-3xl font-bold text-gray-800 dark:text-white">
          +251 962 489 700
        </p>
        {/* <p className="text-sm sm:text-base text-gray-600 dark:text-gray-400 mt-4">
          Please provide your booking reference when making the payment.
        </p> */}
      </div>
    </div>
  );
}

export default ETBCheckout;
