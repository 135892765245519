import React, { useEffect, useState } from "react";
import { useAuth } from "../../../context/AuthContext";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { db } from "../../../firebase";
import toast from "react-hot-toast";
import { X } from "lucide-react";
import NotificationSound from "../../../assets/sounds/notification.mp3";

function AdminNotifications({ setUnreadCountsApp }) {
  const { currentUser, role } = useAuth();
  const [conversations, setConversations] = useState([]);
  const [unreadCounts, setUnreadCounts] = useState({
    conversations: 0,
    contacts: 0,
    requests: 0,
  });
  const isAuthorized =
    !!currentUser && (role === "employee" || role === "manager");

  useEffect(() => {
    if (isAuthorized) {
      const q1 = query(
        collection(db, "conversations"),
        where("customerLastMessageStatus", "==", "unread")
      );

      const q2 = query(
        collection(db, "contacts"),
        where("customerLastMessageStatus", "==", "unread")
      );

      const q3 = query(
        collection(db, "requests"),
        where("status", "==", "pending")
      );

      const unsubscribe1 = onSnapshot(q1, (snapshot) => {
        const convos = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          source: "conversations",
        }));
        setConversations((prevConvos) => [...prevConvos, ...convos]);

        setUnreadCounts((prevCounts) => ({
          ...prevCounts,
          conversations: convos.length,
        }));
      });

      const unsubscribe2 = onSnapshot(q2, (snapshot) => {
        const contacts = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          source: "contacts",
        }));
        setConversations((prevConvos) => [...prevConvos, ...contacts]);

        setUnreadCounts((prevCounts) => ({
          ...prevCounts,
          contacts: contacts.length,
        }));
      });

      const unsubscribe3 = onSnapshot(q3, (snapshot) => {
        const pendingConvos = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          source: "requests",
        }));
        setConversations((prevConvos) => [...prevConvos, ...pendingConvos]);

        setUnreadCounts((prevCounts) => ({
          ...prevCounts,
          requests: pendingConvos.length,
        }));
      });

      return () => {
        unsubscribe1();
        unsubscribe2();
        unsubscribe3();
      };
    }
  }, [isAuthorized]);

  useEffect(() => {
    setUnreadCountsApp(unreadCounts);
  }, [unreadCounts, setUnreadCountsApp]);

  useEffect(() => {
    if (conversations.length > 0) {
      const audio = new Audio(NotificationSound);
      audio.play().catch((error) => {
        console.error("Error playing sound:", error);
      });

      conversations.forEach((conversation) => {
        toast.custom((t) => (
          <div
            className={`${
              t.visible ? "animate-enter" : "animate-leave"
            } max-w-md w-full bg-white shadow-lg rounded-xl pointer-events-auto flex ring-1 ring-black ring-opacity-5 border border-gray-300 transition-transform transform duration-150 cursor-pointer`}
            onClick={() => toast.dismiss(t.id)}
          >
            <div className="flex-1 w-0 p-4">
              <div className="flex items-start">
                {conversation.source === "conversations" && (
                  <div className="flex-shrink-0 pt-0.5">
                    <img
                      className="h-14 w-14 rounded-full"
                      src={conversation.postImg}
                      alt="post"
                    />
                  </div>
                )}
                <div className="ml-3 flex-1">
                  <p className="text-sm font-medium text-mainColor">
                    New{" "}
                    {conversation.source === "conversations"
                      ? "Message"
                      : conversation.source === "requests"
                      ? "Request"
                      : conversation.source === "contacts"
                      ? "Contact Message"
                      : "Notification"}
                  </p>
                  {conversation.source === "conversations" && (
                    <p className="mt-1 text-xs text-gray-600">
                      {conversation.postName}
                    </p>
                  )}
                  <p className="mt-1 text-sm text-gray-600">
                    There is a new{" "}
                    {conversation.source === "conversations"
                      ? "message"
                      : conversation.source === "requests"
                      ? "request"
                      : conversation.source === "contacts"
                      ? "contact message"
                      : "notification"}
                    !
                  </p>
                </div>
              </div>
            </div>
            <div className="flex">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  toast.dismiss(t.id);
                }}
                className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-mainColor hover:bg-gray-200 transition-colors duration-150 focus:outline-none"
              >
                <X />
              </button>
            </div>
          </div>
        ));
      });
    }
  }, [conversations]);
  return null;
}

export default AdminNotifications;
