import React from "react";
import {
  FaFacebook,
  FaLinkedin,
  FaInstagram,
  FaTelegramPlane,
} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

function Footer() {
  return (
    <footer className="relative font-Quicksand bg-slate-300 dark:bg-gray-900 text-gray-600 dark:text-gray-300 transition-colors duration-300 py-10 px-6 sm:px-10 select-none">
      <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-10 text-[0.95rem]">
        <div className="space-y-4">
          <h2 className="text-2xl text-black dark:text-white font-bold">
            E-CyberNest
          </h2>
          <p className="text-black dark:text-gray-300">
            Building connections and opportunities for people around the world.
          </p>
          <div className="flex text-lg space-x-4">
            <a
              href="https://www.facebook.com/profile.php?id=61564945572068&mibextid=LQQJ4d"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebook className="text-gray-500 hover:text-mainColor cursor-pointer" />
            </a>

            <a
              href="https://twitter.com/ecybernest"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaXTwitter className="text-gray-500 hover:text-mainColor cursor-pointer" />
            </a>

            <a
              href="https://www.linkedin.com/ecybernest"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedin className="text-gray-500 hover:text-mainColor cursor-pointer" />
            </a>

            <a
              href="https://t.me/ecybernest"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaTelegramPlane className="text-gray-500 hover:text-mainColor cursor-pointer" />
            </a>

            <a
              href="https://www.instagram.com/ecybernest"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram className="text-gray-500 hover:text-mainColor cursor-pointer" />
            </a>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div>
            <h3 className="text-lg text-black dark:text-white mb-4">About</h3>
            <ul className="space-y-2">
              <li>
                <a href="#" className="hover:underline dark:hover:text-white">
                  Our Story
                </a>
              </li>
              <li>
                <a href="#" className="hover:underline dark:hover:text-white">
                  Careers
                </a>
              </li>
              <li>
                <a href="#" className="hover:underline dark:hover:text-white">
                  Blog
                </a>
              </li>
              <li>
                <a href="#" className="hover:underline dark:hover:text-white">
                  Press
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="text-lg text-black dark:text-white mb-4">Support</h3>
            <ul className="space-y-2">
              <li>
                <a href="#" className="hover:underline dark:hover:text-white">
                  Help Center
                </a>
              </li>
              <li>
                <a href="#" className="hover:underline dark:hover:text-white">
                  Feedback
                </a>
              </li>
              <li>
                <a href="#" className="hover:underline dark:hover:text-white">
                  Support
                </a>
              </li>
              <li>
                <a href="#" className="hover:underline dark:hover:text-white">
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="space-y-4">
          <h3 className="text-lg text-black dark:text-white mb-2">Legal</h3>
          <ul className="space-y-2">
            <li>
              <a href="#" className="hover:underline dark:hover:text-white">
                Privacy Policy
              </a>
            </li>
            <li>
              <a href="#" className="hover:underline dark:hover:text-white">
                Terms of Service
              </a>
            </li>
            <li>
              <a href="#" className="hover:underline dark:hover:text-white">
                Cookie Policy
              </a>
            </li>
            <li>
              <a href="#" className="hover:underline dark:hover:text-white">
                Legal Notices
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="max-w-7xl mx-auto border-t border-gray-400 dark:border-gray-600 mt-10 mb-10 lg:mb-0 pt-4">
        <div className="flex space-x-1 justify-center text-center text-sm text-gray-500 dark:text-gray-400 font-medium">
          <span className="font-semibold">&copy; </span>
          <span>
            {new Date().getFullYear()} E-CyberNest. All rights reserved.
          </span>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
