import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../components/common/Loader/Loader";
import { doc, getDoc, Timestamp } from "firebase/firestore";
import { db } from "../firebase";
import { motion } from "framer-motion";
import {
  CheckCircle,
  Phone,
  Mail,
  MapPin,
  Home,
  BookCheck,
  Hotel,
  Calendar,
  DollarSign,
  Bed,
  User,
  Clock,
  Tag,
  Banknote,
  Download,
} from "lucide-react";
import { useAuth } from "../context/AuthContext";
import { getLocalizedText } from "../hooks/langSeparator";
import { useTranslation } from "react-i18next";
import html2pdf from "html2pdf.js";

const SuccessPage = () => {
  const [bookingDetails, setBookingDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser } = useAuth();
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const query = new URLSearchParams(location.search);
  const id = query.get("id");

  useEffect(() => {
    const fetchPost = async () => {
      if (!id) {
        setError("Invalid booking ID. Please try again.");
        setLoading(false);
        return;
      }

      try {
        const docRef = doc(db, "Bookings", id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setBookingDetails(docSnap.data());
        } else {
          setError("Booking not found. Please contact support.");
        }
      } catch (err) {
        console.error("Error fetching booking details:", err);
        setError("An error occurred while fetching booking details.");
      } finally {
        setLoading(false);
      }
    };

    fetchPost();
  }, [id]);

  const HotelAndGuestHouses =
    bookingDetails?.bookingData.type === "HotelAndGuestHouses";
  const BookingProperty =
    bookingDetails?.bookingData.type === "BookingProperty";

  const formatDate = (date) => {
    const createdAt =
      date instanceof Timestamp ? date.toDate() : new Date(date);

    if (isNaN(createdAt)) {
      return "Invalid Date";
    }

    const formattedDate = createdAt.toLocaleString("en-GB", {
      weekday: "short",
      day: "2-digit",
      month: "long",
      year: "numeric",
    });

    return formattedDate;
  };

  const downloadPage = () => {
    const element = document.querySelector(".recipt");

    if (!element) {
      console.error("Element not found");
      return;
    }

    const options = {
      margin: 1,
      filename: "receipt.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { orientation: "landscape" },
    };

    html2pdf().set(options).from(element).save();
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Loader />
      </div>
    );
  }

  if (error) {
    return (
      <div className="h-screen text-center mt-20 text-lg font-medium text-red-500 flex flex-col items-center justify-center">
        <p>{error}</p>
        <button
          onClick={() => navigate("/")}
          className="mt-4 bg-mainColor text-white px-4 py-2 rounded-xl hover:bg-hovermainColor"
        >
          Go to Homepage
        </button>
      </div>
    );
  }

  if (currentUser.uid !== bookingDetails.bookingData.uid) {
    navigate("/login");
  }

  return (
    <div className="recipt min-h-screen flex flex-col lg:flex-row">
      {/* Left Section */}
      <div className="md:w-1/3 bg-sky-100 p-4 flex items-start justify-center">
        <div className="md:sticky md:top-10 bg-white mt-10 shadow-xl rounded-xl w-9/12">
          <div className="w-full h-44 p-4 rounded-lg">
            <img
              src={bookingDetails?.bookingData.postImg}
              alt={bookingDetails.bookingData.name}
              className="w-full h-full object-cover overflow-hidden rounded-lg"
            />
          </div>
          <div>
            <div className="px-4 mt-2">
              <p className="text-sm flex space-x-2 items-center">
                <Tag size={20} className="bg-gray-300 rounded-lg p-1" />
                <span className="text-gray-600 font-medium">Total paid:</span>
                <span className="text-base text-black font-medium">
                  {new Intl.NumberFormat().format(
                    (bookingDetails?.amountTotal / 100).toFixed(2)
                  )}
                  <span className="font-Poppins"> $</span>
                </span>
              </p>
              <div className="flex justify-between mt-3">
                <p className="flex flex-col text-sm">
                  <span className="text-xs text-gray-500">Check-in:</span>
                  <span className="text-black font-semibold">
                    {bookingDetails?.bookingData.checkIn
                      ? new Intl.DateTimeFormat("en-US", {
                          day: "2-digit",
                          month: "short",
                          year: "numeric",
                        }).format(new Date(bookingDetails?.bookingData.checkIn))
                      : "N/A"}
                  </span>
                </p>
                <p className="flex flex-col text-sm">
                  <span className="text-xs text-gray-500">Check-out:</span>
                  <span className="text-black font-semibold">
                    {bookingDetails?.bookingData.checkout
                      ? new Intl.DateTimeFormat("en-US", {
                          day: "2-digit",
                          month: "short",
                          year: "numeric",
                        }).format(
                          new Date(bookingDetails?.bookingData.checkout)
                        )
                      : "N/A"}
                  </span>
                </p>
              </div>
              <div className="p-3 my-4 bg-gray-200 rounded-md flex items-center justify-between">
                <div className="text-gray-600 flex flex-col">
                  <span className="text-xs">Confirmation</span>
                  <p className="text-sm font-medium text-green-600">
                    Booking confirmed!
                  </p>
                </div>
                <BookCheck size={20} className="text-green-600" />
              </div>
            </div>
            <div className="p-4 mt-4 border-t border-gray-300">
              <p className="text-sm font-semibold text-gray-700 flex items-center justify-between">
                <span>{bookingDetails?.bookingData.name}</span>
                <Hotel size={20} strokeWidth={1.5} className="text-gray-800" />
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Right Section */}
      <div className="md:w-3/4 bg-white py-12 px-8 md:px-20 overflow-y-auto">
        <div className="flex justify-center items-center overflow-visible mb-6 relative">
          <motion.svg
            className="text-green-500 w-16 h-16"
            style={{ overflow: "visible" }}
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.4, ease: "easeOut" }}
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <motion.circle
              cx="50"
              cy="50"
              r="48"
              stroke="currentColor"
              strokeWidth="6"
              strokeDasharray="301.59"
              fill="none"
              initial={{ strokeDashoffset: 301.59 }}
              animate={{ strokeDashoffset: 0 }}
              transition={{ duration: 0.7, ease: "easeOut" }}
            />
            <motion.path
              d="M25 50l15 15 30-30"
              stroke="currentColor"
              strokeWidth="6"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeDasharray="65"
              initial={{ strokeDashoffset: 65 }}
              animate={{ strokeDashoffset: 0 }}
              transition={{ duration: 0.6, ease: "easeOut" }}
            />
          </motion.svg>

          {/* Party Popper Sparkles */}
          <div className="absolute inset-0">
            {Array.from({ length: 10 }).map((_, i) => {
              const angle = (i * 360) / 10;
              const radians = (angle * Math.PI) / 180;

              return (
                <motion.div
                  key={i}
                  className="absolute bg-yellow-600 rounded-full"
                  style={{
                    width: "8px",
                    height: "8px",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                  initial={{
                    opacity: 1,
                    scale: 0,
                    x: 0,
                    y: 0,
                  }}
                  animate={{
                    opacity: 0,
                    scale: 1,
                    x: Math.cos(radians) * 100,
                    y: Math.sin(radians) * 100,
                  }}
                  transition={{
                    duration: 0.9,
                    delay: i * 0.1,
                    ease: "easeOut",
                  }}
                />
              );
            })}
          </div>
        </div>
        <h1 className="text-sm font-semibold text-gray-700 mb-3">
          Booking Details
        </h1>
        <h1 className="text-3xl font-bold text-gray-800 mb-8">
          {bookingDetails?.bookingData.paymentTitle}
        </h1>
        <div className="space-y-8 pl-2">
          {/* Booker's Information */}
          <section>
            <h2 className="text-sm font-semibold bg-gray-300 text-gray-700 py-2 px-6 rounded-md">
              Booker's Information
            </h2>

            <div className="mt-4 text-gray-600 flex pl-6 py-2 text-sm items-center lg:w-1/2">
              <div className="flex items-center w-1/3">
                <User size={18} className="mr-2 text-gray-500" />
                <span className="text-gray-500">Name</span>
              </div>
              <div className="flex justify-center">
                <span className="text-gray-800 font-semibold pl-6 text-left">
                  {`${bookingDetails.bookingData.bookerInfo.first_name} ${bookingDetails.bookingData.bookerInfo.last_name}`}
                </span>
              </div>
            </div>

            <div className="text-gray-600 flex pl-6 py-2 text-sm items-center lg:w-1/2">
              <span className="flex items-center w-1/3">
                <Mail size={18} className="mr-2 text-gray-500" />
                <span className="text-gray-500">Email</span>
              </span>
              <div className="flex justify-center">
                <span className="text-gray-800 font-semibold pl-6 text-left">
                  {bookingDetails.bookingData.bookerInfo.email}
                </span>
              </div>
            </div>

            <div className="text-gray-600 flex pl-6 py-2 text-sm items-center lg:w-1/2">
              <span className="flex items-center w-1/3">
                <Phone size={18} className="mr-2 text-gray-500" />
                <span className="text-gray-500">Phone</span>
              </span>
              <div className="flex justify-center">
                <span className="text-gray-800 font-semibold pl-6 text-left">
                  {bookingDetails.bookingData.bookerInfo.phone_number}
                </span>
              </div>
            </div>

            <div className="text-gray-600 flex pl-6 py-2 text-sm items-center lg:w-1/2">
              <span className="flex items-center w-1/3">
                <MapPin size={18} className="mr-2 text-gray-500" />
                <span className="text-gray-500">Location</span>
              </span>
              <div className="flex justify-center">
                <span className="text-gray-800 font-semibold pl-6 text-left">
                  {`${bookingDetails.bookingData.bookerInfo.city}, ${bookingDetails.bookingData.bookerInfo.country}`}
                </span>
              </div>
            </div>
          </section>

          {/* Hotel Information */}
          <section>
            <h2 className="text-sm font-semibold bg-gray-300 text-gray-700 py-2 px-6 rounded-md">
              Hotel Information
            </h2>
            <div className="mt-4 text-gray-600 flex pl-6 py-2 text-sm items-center lg:w-1/2">
              <span className="flex items-center w-1/3">
                <Bed size={18} className="mr-2 text-gray-500" />
                <span className="text-gray-500">Name</span>
              </span>
              <div className="flex justify-center">
                <span className="text-gray-800 font-semibold pl-6 text-center">
                  {bookingDetails.bookingData.name}
                </span>
              </div>
            </div>

            <div className="text-gray-600 flex pl-6 py-2 text-sm items-center lg:w-1/2">
              <span className="flex items-center w-1/3">
                <MapPin size={18} className="mr-2 text-gray-500" />
                <span className="text-gray-500">Location</span>
              </span>
              <div className="flex justify-center">
                <span className="text-gray-800 font-semibold pl-6 text-center">
                  {`${
                    bookingDetails.bookingData.location?.name || "Unknown"
                  }, ${bookingDetails.bookingData.location?.city || "Unknown"}`}
                </span>
              </div>
            </div>
          </section>

          {/* Booking Details */}
          <section>
            <h2 className="text-sm font-semibold bg-gray-300 text-gray-700 py-2 px-6 rounded-md">
              Booking Details
            </h2>
            <ul className="mt-4 text-gray-600 space-y-2 pl-6 text-sm">
              <li className="flex py-2 text-sm items-center lg:w-1/2">
                <span className="flex items-center w-1/3">
                  <Clock size={18} className="mr-2 text-gray-500" />
                  <span className="text-gray-500">Booked at</span>
                </span>
                <div className="flex justify-center">
                  <span className="text-gray-800 font-semibold pl-6 text-center">
                    {formatDate(bookingDetails.createdAt)}
                  </span>
                </div>
              </li>

              {BookingProperty && (
                <li className="flex py-2 text-sm items-center lg:w-1/2">
                  <span className="flex items-center w-1/3">
                    <User size={18} className="mr-2 text-gray-500" />
                    <span className="text-gray-500">Guests</span>
                  </span>
                  <div className="flex justify-center">
                    <span className="text-gray-800 font-semibold pl-6 text-center">
                      {[
                        bookingDetails?.bookingData.guests.adults > 0 &&
                          `${bookingDetails?.bookingData.guests.adults} Adults`,
                        bookingDetails?.bookingData.guests.children > 0 &&
                          `${bookingDetails?.bookingData.guests.children} Children`,
                        bookingDetails?.bookingData.guests.infants > 0 &&
                          `${bookingDetails?.bookingData.guests.infants} Infants`,
                        bookingDetails?.bookingData.guests.pets > 0 &&
                          `${bookingDetails?.bookingData.guests.pets} Pets`,
                      ]
                        .filter(Boolean)
                        .join(", ")}
                    </span>
                  </div>
                </li>
              )}

              {BookingProperty && (
                <li className="flex py-2 text-sm items-center lg:w-1/2">
                  <span className="flex items-center w-1/3">
                    <Banknote size={18} className="mr-2 text-gray-500" />
                    <span className="text-gray-500">Price</span>
                  </span>
                  <div className="flex justify-center">
                    <span className="text-gray-800 font-semibold pl-6 text-center">
                      {bookingDetails.bookingData.bookingPriceExchanged}
                    </span>
                  </div>
                </li>
              )}

              {HotelAndGuestHouses && (
                <li className="flex py-2 text-sm">
                  <span className="flex items-center md:w-1/6">
                    <Bed size={18} className="mr-2 text-gray-500" />
                    <span className="text-gray-500">Rooms</span>
                  </span>
                  <div className="grid grid-cols-1 lg:grid-cols-2 text-gray-800 font-semibold pl-6 gap-4 w-9/12">
                    {bookingDetails?.bookingData.bookedRooms.map(
                      (room, index) => {
                        const getRoomType = () =>
                          getLocalizedText(room?.roomType, language);

                        return (
                          <div
                            key={index}
                            className="flex flex-col bg-gray-200 p-4 rounded-lg w-full"
                          >
                            <div className="flex items-center mb-4">
                              <div className="h-10 w-10 flex items-center justify-center font-semibold text-base rounded-full bg-gray-400">
                                {index + 1}
                              </div>
                              <div className="ml-4">
                                <div className="font-semibold text-sm">
                                  {getRoomType()}
                                </div>
                                <div className="text-xs text-gray-500">
                                  Price: {room.roomPriceExchanged} $ per night
                                </div>
                              </div>
                            </div>

                            <div className="flex">
                              <span className="text-sm font-medium text-gray-700">
                                Guests
                              </span>
                              <div className="text-sm text-gray-500 ml-4">
                                {room.guests.adults > 0 && (
                                  <span>{room.guests.adults} Adults</span>
                                )}
                                {room.guests.children > 0 && (
                                  <>
                                    {room.guests.adults > 0 && ", "}
                                    <span>{room.guests.children} Children</span>
                                  </>
                                )}
                                {room.guests.infants > 0 && (
                                  <>
                                    {(room.guests.adults > 0 ||
                                      room.guests.children > 0) &&
                                      ", "}
                                    <span>{room.guests.infants} Infants</span>
                                  </>
                                )}
                                {room.guests.pets > 0 && (
                                  <>
                                    {(room.guests.adults > 0 ||
                                      room.guests.children > 0 ||
                                      room.guests.infants > 0) &&
                                      ", "}
                                    <span>{room.guests.pets} Pets</span>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </li>
              )}

              <li className="flex py-2 text-sm items-center lg:w-1/2">
                <span className="flex items-center w-1/3">
                  <Calendar size={18} className="mr-2 text-gray-500" />
                  <span className="text-gray-500">Check-in</span>
                </span>
                <div className="flex justify-center">
                  <span className="text-gray-800 font-semibold pl-6 text-center">
                    {formatDate(bookingDetails.bookingData.checkIn)}
                  </span>
                </div>
              </li>

              <li className="flex py-2 text-sm items-center lg:w-1/2">
                <span className="flex items-center w-1/3">
                  <Calendar size={18} className="mr-2 text-gray-500" />
                  <span className="text-gray-500">Check-out</span>
                </span>
                <div className="flex justify-center">
                  <span className="text-gray-800 font-semibold pl-6 text-center">
                    {formatDate(bookingDetails.bookingData.checkout)}
                  </span>
                </div>
              </li>

              <li className="flex py-2 text-sm items-center lg:w-1/2">
                <span className="flex items-center w-1/3">
                  <Calendar size={18} className="mr-2 text-gray-500" />
                  <span className="text-gray-500">Stay</span>
                </span>
                <div className="flex justify-center">
                  <span className="text-gray-800 font-semibold pl-6 text-center">
                    {bookingDetails.bookingData.nights} Nights
                  </span>
                </div>
              </li>

              <li className="flex py-2 text-sm items-center lg:w-1/2">
                <span className="flex items-center w-1/3">
                  <DollarSign size={18} className="mr-2 text-gray-500" />
                  <span className="text-gray-500">Total Paid</span>
                </span>
                <div className="flex justify-center">
                  <span className="text-gray-800 font-semibold pl-6 text-center">
                    {new Intl.NumberFormat().format(
                      (bookingDetails?.amountTotal / 100).toFixed(2)
                    )}{" "}
                    $
                  </span>
                </div>
              </li>

              <li className="flex py-2 text-sm items-center lg:w-1/2">
                <span className="flex items-center lg:w-1/3">
                  <Banknote size={18} className="mr-2 text-gray-500" />
                  <span className="text-gray-500">Payment Status</span>
                </span>
                <div className="flex justify-center">
                  <p className="text-green-500 font-semibold pl-6 text-center flex items-center">
                    <span>{bookingDetails.paymentStatus}</span>
                    <CheckCircle size={14} className="ml-2 text-green-500" />
                  </p>
                </div>
              </li>
            </ul>
          </section>
        </div>
        <div
          data-html2canvas-ignore
          className="flex justify-end items-end space-x-4 pb-10"
        >
          {/* Navigate Home Button */}
          <div className="relative group">
            <button
              onClick={() => navigate("/")}
              className="bg-gray-300 p-4 rounded-lg"
            >
              <Home size={18} />
            </button>
            <span className="absolute bottom-full mb-2 hidden group-hover:flex justify-center items-center px-3 py-1 text-xs text-white bg-black rounded-md">
              Go to Home
            </span>
          </div>

          {/* Download Page Button */}
          <div className="relative group">
            <button
              onClick={downloadPage}
              className="bg-gray-300 p-4 rounded-lg"
            >
              <Download size={18} />
            </button>
            <span className="absolute bottom-full mb-2 hidden group-hover:flex justify-center items-center px-3 py-1 text-xs text-white bg-black rounded-md">
              Download Page
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessPage;
