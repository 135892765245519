import React from "react";
import { Skeleton } from "antd";

function PostDetailLoader() {
  return (
    <div className="pt-20 mx-auto px-4 pb-8 max-w-7xl">
      <div className="justify-end flex mb-2">
        <Skeleton.Input
          active
          style={{ width: 120, height: 30 }}
          className="bg-gray-200 dark:bg-slate-700 rounded-sm"
        />
      </div>

      {/* Larger Screen Images */}
      <div className="hidden lg:flex relative flex-row space-x-4">
        <div className="flex-1 w-1/2">
          <div className="overflow-hidden rounded-md mb-0">
            <Skeleton.Node
              active
              style={{ width: 700, height: 400 }}
              className="bg-gray-200 dark:bg-slate-700 rounded-md"
            />
          </div>
        </div>

        <div className="flex-none w-1/2 grid grid-cols-2 gap-3">
          {[...Array(4)].map((_, index) => (
            <div key={index} className="overflow-hidden rounded-md">
              <Skeleton.Node
                active
                style={{ width: 300, height: 195 }}
                className="bg-gray-200 dark:bg-slate-700 rounded-md"
              />
            </div>
          ))}
        </div>
      </div>

      {/* Smaller Screen Images */}
      <div className="block lg:hidden">
        <div className="overflow-hidden w-full rounded-md mb-3">
          <Skeleton.Node
            active
            style={{ width: 400, height: 200 }}
            className="bg-gray-200 dark:bg-slate-700 rounded-md"
          />
        </div>

        <div className="grid grid-cols-2 gap-3 mt-3 w-full">
          {[...Array(4)].map((_, index) => (
            <div key={index} className="overflow-hidden rounded-md">
              <Skeleton.Node
                active
                style={{ width: 200, height: 150 }}
                className="bg-gray-200 dark:bg-slate-700 rounded-md"
              />
            </div>
          ))}
        </div>
      </div>

      <div className="flex flex-col gap-2 border-t mt-10 pt-10 dark:border-gray-700">
        <div className="w-[220px]">
          <Skeleton.Input
            active
            style={{ width: 220, height: 50 }}
            className="bg-gray-200 dark:bg-slate-700 rounded-sm"
          />
        </div>

        <div className="w-[100px]">
          <Skeleton.Input
            active
            style={{ width: 100, height: 30 }}
            className="bg-gray-200 dark:bg-slate-700 rounded-sm"
          />
        </div>
      </div>

      {/* Description Skeleton */}
      <div className="flex flex-col md:flex-row gap-8 border-t mt-12 pt-12 dark:border-gray-700">
        {/* Left Column */}
        <div className="">
          {[...Array(8)].map((_, index) => (
            <div
              key={index}
              className="mt-2 rounded-lg flex-1 overflow-hidden w-full flex-col"
            >
              <Skeleton.Input
                active
                style={{ width: 850, height: 20 }}
                className="bg-gray-200 dark:bg-slate-700 rounded-lg"
              />
            </div>
          ))}
        </div>

        {/* Larger screen Right Column */}
        <div className="hidden lg:flex">
          <Skeleton.Node
            active
            style={{ width: 370, height: 200, borderRadius: 16 }}
            className="bg-gray-200 dark:bg-slate-700 rounded-2xl h-fit"
          />
        </div>

        {/* Small screen right Column */}
        <div className="block lg:hidden">
          <div className="overflow-hidden w-full rounded-2xl mb-3">
            <Skeleton.Node
              active
              style={{ width: 400, height: 300, borderRadius: 16 }}
              className="bg-gray-200 dark:bg-slate-700 rounded-2xl"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PostDetailLoader;
