import React, { useEffect, useState } from "react";
import { useAuth } from "../context/AuthContext";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { db } from "../firebase";
import { CalendarIcon, CheckCircleIcon } from "lucide-react";
import { useNavigate } from "react-router-dom";
import Loader from "../components/common/Loader/Loader";

function BookingAndInvoices() {
  const { currentUser } = useAuth();
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const q = query(
          collection(db, "Bookings"),
          where("bookingData.uid", "==", currentUser?.uid),
          orderBy("createdAt", "desc")
        );
        const querySnapshot = await getDocs(q);
        const bookingsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setBookings(bookingsData);
      } catch (error) {
        console.error("Error fetching bookings:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBookings();
  }, [currentUser]);

  const handleBooking = (bookingId) => {
    navigate(`/success?id=${bookingId}`);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="font-Quicksand py-20 px-6 sm:px-10">
      <div className="flex items-center mb-10 lg:mx-5">
        <h1 className="text-2xl dark:text-gray-200 text-gray-800 font-bold ml-4 sm:ml-6">
          Bookings and Invoices
        </h1>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-7 lg:mx-5">
        {bookings.map((booking) => (
          <div
            key={booking.id}
            className="bg-white rounded-2xl shadow-lg overflow-hidden w-full max-w-2xl mx-auto cursor-pointer"
            role="article"
            aria-label={`Booking for ${booking.bookingData.name}`}
            onClick={() => handleBooking(booking.id)}
          >
            <div className="relative h-48">
              <img
                src={booking.bookingData.postImg}
                alt={`Image of ${booking.bookingData.name}`}
                className="w-full h-full object-cover"
                onError={(e) => {
                  e.target.src =
                    "https://images.unsplash.com/photo-1551882547-ff40c63fe5fa";
                  e.target.alt = "Fallback Hotel Image";
                }}
              />
              <div className="absolute inset-0 bg-black bg-opacity-20"></div>
            </div>

            <div className="p-6 space-y-6">
              <div className="flex justify-between items-start">
                <div>
                  <h2 className="text-2xl font-bold text-gray-800">
                    {booking.bookingData.name}
                  </h2>
                  <p className="text-gray-600 mt-1">Booking ID: {booking.id}</p>
                </div>
                <div className="bg-green-100 px-3 py-1 rounded-full flex items-center">
                  <CheckCircleIcon className="text-green-500 mr-2" size={16} />
                  <span className="text-green-700 font-medium">Paid</span>
                </div>
              </div>

              <div className="grid md:grid-cols-2 gap-6">
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-600">
                      Guest Name
                    </label>
                    <p className="mt-1 text-gray-800">
                      {`${booking.bookingData.bookerInfo.first_name} ${booking.bookingData.bookerInfo.last_name}`}
                    </p>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-600">
                      Total Amount
                    </label>
                    <p className="mt-1 text-gray-800">
                      ${(booking.amountTotal / 100).toFixed(2)}
                    </p>
                  </div>
                </div>

                <div className="space-y-4">
                  <div className="flex items-center space-x-4">
                    <CalendarIcon className="text-blue-500" size={16} />
                    <div>
                      <label className="block text-sm font-medium text-gray-600">
                        Check-In
                      </label>
                      <p className="mt-1 text-gray-800">
                        {new Date(
                          booking.bookingData.checkIn
                        ).toLocaleDateString()}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center space-x-4">
                    <CalendarIcon className="text-blue-500" size={16} />
                    <div>
                      <label className="block text-sm font-medium text-gray-600">
                        Check-Out
                      </label>
                      <p className="mt-1 text-gray-800">
                        {new Date(
                          booking.bookingData.checkout
                        ).toLocaleDateString()}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default BookingAndInvoices;
