import React, { useState } from "react";
import Header from "../components/common/Header";
import RoomForm from "../components/createPost/RoomsForm";
import SurroundingOutsideForm from "../components/createPost/SurroundngOutsideForm";
import LocationSelector from "../components/createPost/LocationSelector";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../firebase";
import CurrencyInput from "react-currency-input-field";
import { TailSpin } from "react-loader-spinner";
import toast from "react-hot-toast";
import Editor from "../components/createPost/Editor";

function CreatePostPage({ currentUser, firstName, lastName }) {
  const [propertyData, setPropertyData] = useState({
    name: "",
    category: "Apartments",
    purchaseType: "",
    rentPaymentType: "",
    rentPrice: "",
    salePrice: "",
    bookingPrice: "",
    bookingMaxGuests: 0,
    bookingPetsAllowed: "No",
    bookingMinDays: 0,
    rooms: [],
    outside: {
      normalImages: [],
      panoramaImage: "",
      outsideDescription: "",
    },
    surrounding: {
      normalImages: [],
      panoramaImage: "",
      surroundingDescription: "",
    },
    location: {
      name: "",
      city: "",
      coordinates: { lat: "", long: "" },
    },
    description: "",
    propertyOwner: {
      name: "",
      phone: "",
    },
  });
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const categories = [
    "Apartments",
    "Hotels",
    "Condominiums",
    "Offices & Shops",
    "Residence",
    "Villa",
    "Real Estate",
    "Warehouse",
    "Factory/Industry",
    "Guest House",
    "Event venue",
  ];

  const HotelAndGuestHouses =
    propertyData.category === "Hotels" ||
    propertyData.category === "Guest House";

  const nextRoomId = propertyData.rooms.length + 1;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPropertyData({ ...propertyData, [name]: value });
  };

  const handleRoomChange = (roomIndex, updatedRoom) => {
    const updatedRooms = propertyData.rooms.map((room, index) =>
      index === roomIndex ? updatedRoom : room
    );
    setPropertyData({ ...propertyData, rooms: updatedRooms });
  };

  const addRoom = () => {
    if (HotelAndGuestHouses) {
      setPropertyData({
        ...propertyData,
        rooms: [
          ...propertyData.rooms,
          {
            roomId: nextRoomId,
            roomType: "",
            roomInstancesCount: 0,
            instances: [],
            roomPrice: "",
            roomDescription: "",
            roomMaxGuests: 0,
            roomMinDays: 0,
            roomPetsAllowed: "No",
          },
        ],
      });
    } else {
      setPropertyData({
        ...propertyData,
        rooms: [
          ...propertyData.rooms,
          {
            roomId: nextRoomId,
            roomType: "",
            roomInstancesCount: 0,
            instances: [],
            roomDescription: "",
          },
        ],
      });
    }
  };

  const removeRoom = (roomIndex) => {
    const updatedRooms = propertyData.rooms.filter(
      (_, index) => index !== roomIndex
    );
    setPropertyData({ ...propertyData, rooms: updatedRooms });
  };

  const validateForm = () => {
    let errors = {};

    if (!propertyData.name.trim()) {
      errors.name = "Property name is required";
    }

    if (
      propertyData.purchaseType === "Rent" &&
      !propertyData.rentPaymentType.trim()
    ) {
      errors.rentPaymentType =
        "Rent payment type is required for rental properties";
    }

    if (
      propertyData.purchaseType === "Rent" &&
      !propertyData.rentPrice.trim()
    ) {
      errors.rentPrice = "Rent price is required for rental properties";
    }

    if (
      propertyData.purchaseType === "Sale" &&
      !propertyData.salePrice.trim()
    ) {
      errors.salePrice = "Sale price is required for properties on sale";
    }

    if (
      propertyData.purchaseType === "Booking" &&
      !propertyData.bookingPrice.trim()
    ) {
      errors.bookingPrice = "Booking price is required for booking properties";
    }

    if (
      propertyData.purchaseType === "Booking" &&
      !propertyData.bookingMaxGuests.trim()
    ) {
      errors.bookingMaxGuests =
        "Booking max guests is required for booking properties";
    }

    if (!propertyData.rooms.length) {
      errors.rooms = "At least one room is required";
    }

    if (
      !propertyData.outside.normalImages.length &&
      !propertyData.outside.panoramaImage.trim()
    ) {
      errors.outside =
        "At least one outside image and a panorama image is required";
    }

    if (
      !propertyData.surrounding.normalImages.length &&
      !propertyData.surrounding.panoramaImage.trim()
    ) {
      errors.surrounding =
        "At least one surrounding image and a panorama image is required";
    }

    if (!propertyData.location.name.trim()) {
      errors.locationName = "Location name is required";
    }
    if (!propertyData.location.city.trim()) {
      errors.city = "City is required";
    }

    if (!propertyData.description.trim()) {
      errors.description = "Description is required";
    }

    if (!propertyData.propertyOwner.name.trim()) {
      errors.ownerName = "Owner name is required";
    }
    if (!propertyData.propertyOwner.phone.trim()) {
      errors.ownerPhone = "Owner phone number is required";
    }

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = validateForm();

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      toast.error("Please fill in all the required fields.");
      return;
    } else {
      setFormErrors({});
      setLoading(true);

      const name = firstName + " " + lastName;

      try {
        const post = {
          ...propertyData,
          createdAt: new Date().toISOString(),
          postedBy: {
            name: name || "",
            employeeId: currentUser.uid,
          },
        };

        const docRef = await addDoc(collection(db, "posts"), post);
        console.log("Document written with ID: ", docRef.id);

        setPropertyData({
          name: "",
          category: "Apartments",
          purchaseType: "Rent",
          rentPaymentType: "",
          rentPrice: "",
          salePrice: "",
          bookingPrice: "",
          rooms: [],
          outside: {
            normalImages: [],
            panoramaImage: "",
            outsideDescription: "",
          },
          surrounding: {
            normalImages: [],
            panoramaImage: "",
            surroundingDescription: "",
          },
          location: {
            name: "",
            city: "",
            coordinates: { lat: "", long: "" },
          },
          description: "",
          propertyOwner: {
            name: "",
            phone: "",
          },
        });
        setLoading(false);
        toast.success("Post Created Succesfully");
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    }
  };

  return (
    <div className="flex-1 overflow-auto relative z-10 text-white">
      <Header title="Create Post" />
      <main className="max-w-6xl mx-auto py-6 px-4 lg:px-8">
        <form className="space-y-6">
          <div>
            <label
              htmlFor="name"
              className="block text-lg font-medium text-gray-200"
            >
              Property Name
            </label>
            <input
              type="text"
              name="name"
              id="name"
              value={propertyData.name}
              onChange={handleInputChange}
              className="mt-1 block w-full p-2 border border-gray-700 bg-gray-800 text-white rounded-md"
              placeholder="Enter property name"
            />
            {formErrors.name && (
              <p className="text-red-500 text-sm mt-1">{formErrors.name}</p>
            )}
          </div>

          <div>
            <label
              htmlFor="category"
              className="block text-lg font-medium text-gray-200"
            >
              Category
            </label>
            <select
              name="category"
              id="category"
              value={propertyData.category}
              onChange={handleInputChange}
              className="mt-1 block w-full p-2 border border-gray-700 bg-gray-800 text-white rounded-md"
            >
              {categories.map((category) => (
                <option key={category} value={category}>
                  {category}
                </option>
              ))}
            </select>
          </div>

          {/* Purchase Type */}
          {!HotelAndGuestHouses && (
            <>
              <div>
                <label className="block text-lg font-medium text-gray-200 mb-2">
                  Purchase Type
                </label>
                <div className="flex space-x-6">
                  {["Rent", "Sale", "Booking"].map((type) => (
                    <label
                      key={type}
                      className="flex items-center space-x-2 cursor-pointer"
                    >
                      <input
                        type="radio"
                        name="purchaseType"
                        value={type}
                        checked={propertyData.purchaseType === type}
                        onChange={handleInputChange}
                        className="hidden peer"
                      />
                      <span
                        className="w-5 h-5 rounded-full border border-gray-300 flex items-center justify-center
          peer-checked:border-mainColor peer-checked:bg-mainColor transition-all duration-300"
                      >
                        <span className="w-2.5 h-2.5 rounded-full bg-transparent peer-checked:bg-white"></span>
                      </span>
                      <span className="text-gray-300 peer-checked:text-mainColor">
                        {type}
                      </span>
                    </label>
                  ))}
                </div>
              </div>

              {propertyData.purchaseType === "Rent" && (
                <>
                  <div>
                    <label className="block text-lg font-medium text-gray-200 mb-2">
                      Rent Payment Type
                    </label>
                    <div className="flex space-x-6">
                      {["monthly", "yearly"].map((type) => (
                        <label
                          key={type}
                          className="flex items-center space-x-2 cursor-pointer"
                        >
                          <input
                            type="radio"
                            name="rentPaymentType"
                            value={type}
                            checked={propertyData.rentPaymentType === type}
                            onChange={handleInputChange}
                            className="hidden peer"
                          />
                          <span
                            className="w-5 h-5 rounded-full border border-gray-300 flex items-center justify-center
          peer-checked:border-mainColor peer-checked:bg-mainColor transition-all duration-300"
                          >
                            <span className="w-2.5 h-2.5 rounded-full bg-transparent peer-checked:bg-white"></span>
                          </span>
                          <span className="text-gray-300 peer-checked:text-mainColor capitalize">
                            {type}
                          </span>
                        </label>
                      ))}
                    </div>
                    {formErrors.rentPaymentType && (
                      <p className="text-red-500 text-sm mt-1">
                        {formErrors.rentPaymentType}
                      </p>
                    )}
                  </div>

                  <div>
                    <label
                      htmlFor="rentPrice"
                      className="block text-lg font-medium text-gray-200"
                    >
                      Rent Price
                    </label>
                    <CurrencyInput
                      name="rentPrice"
                      id="rentPrice"
                      value={propertyData.rentPrice}
                      decimalsLimit={2}
                      onValueChange={(value) =>
                        setPropertyData({ ...propertyData, rentPrice: value })
                      }
                      className="mt-1 block w-full p-2 border border-gray-700 bg-gray-800 text-white rounded-md"
                      placeholder="Enter rent price"
                      prefix="Birr "
                      intlConfig={{ locale: "en-ET", currency: "ETB" }}
                    />
                    {formErrors.rentPrice && (
                      <p className="text-red-500 text-sm mt-1">
                        {formErrors.rentPrice}
                      </p>
                    )}
                  </div>
                </>
              )}

              {propertyData.purchaseType === "Sale" && (
                <div>
                  <label
                    htmlFor="salePrice"
                    className="block text-lg font-medium text-gray-200"
                  >
                    Sale Price
                  </label>
                  <CurrencyInput
                    name="salePrice"
                    id="salePrice"
                    value={propertyData.salePrice}
                    decimalsLimit={2}
                    onValueChange={(value) =>
                      setPropertyData({ ...propertyData, salePrice: value })
                    }
                    className="mt-1 block w-full p-2 border border-gray-700 bg-gray-800 text-white rounded-md"
                    placeholder="Enter sale price"
                    prefix="Birr "
                    intlConfig={{ locale: "en-ET", currency: "ETB" }}
                  />
                  {formErrors.salePrice && (
                    <p className="text-red-500 text-sm mt-1">
                      {formErrors.salePrice}
                    </p>
                  )}
                </div>
              )}

              {propertyData.purchaseType === "Booking" && (
                <div>
                  <label
                    htmlFor="bookingPrice"
                    className="block text-lg font-medium text-gray-200"
                  >
                    Price per night
                  </label>
                  <CurrencyInput
                    name="bookingPrice"
                    id="bookingPrice"
                    value={propertyData.bookingPrice}
                    decimalsLimit={2}
                    onValueChange={(value) =>
                      setPropertyData({ ...propertyData, bookingPrice: value })
                    }
                    className="mt-1 block w-full p-2 border border-gray-700 bg-gray-800 text-white rounded-md"
                    placeholder="Enter booking price"
                    prefix="Birr "
                    intlConfig={{ locale: "en-ET", currency: "ETB" }}
                  />

                  <div className="mt-10 space-y-6">
                    <div className="flex space-x-6">
                      <div className="flex-1">
                        <span className="text-sm font-medium text-gray-300">
                          Minimum number of booking days
                        </span>
                        <input
                          type="number"
                          value={propertyData.bookingMinDays}
                          onChange={(e) =>
                            setPropertyData({
                              ...propertyData,
                              bookingMinDays: e.target.value,
                            })
                          }
                          className="mt-2 block w-full p-3 border border-gray-600 bg-gray-800 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                      </div>

                      <div className="flex-1">
                        <span className="text-sm font-medium text-gray-300">
                          Maximum number of guests (not including infants)
                        </span>
                        <input
                          type="number"
                          value={propertyData.bookingMaxGuests}
                          onChange={(e) =>
                            setPropertyData({
                              ...propertyData,
                              bookingMaxGuests: e.target.value,
                            })
                          }
                          className="mt-2 block w-full p-3 border border-gray-600 bg-gray-800 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                      </div>
                    </div>

                    <div>
                      <span className="text-sm font-medium text-gray-300">
                        Pets Allowed
                      </span>
                      <div className="flex items-center mt-2">
                        <label
                          htmlFor="bookingPetsAllowed"
                          className="relative inline-block w-12 mr-2 hover:cursor-pointer"
                        >
                          <input
                            type="checkbox"
                            id="bookingPetsAllowed"
                            checked={propertyData.bookingPetsAllowed === "Yes"}
                            onChange={(e) =>
                              setPropertyData({
                                ...propertyData,
                                bookingPetsAllowed: e.target.checked
                                  ? "Yes"
                                  : "No",
                              })
                            }
                            className="sr-only"
                          />
                          <span
                            className={`block w-10 h-6 rounded-full transition-all duration-300 ${
                              propertyData.bookingPetsAllowed === "Yes"
                                ? "bg-mainColor"
                                : "bg-gray-600"
                            }`}
                          ></span>
                          <span
                            className={`absolute left-0 top-0 w-6 h-6 bg-white rounded-full transition-all duration-300 transform ${
                              propertyData.bookingPetsAllowed === "Yes"
                                ? "translate-x-4"
                                : "-translate-x-1"
                            }`}
                          ></span>
                        </label>
                        <span
                          className={`text-sm ml-2 ${
                            propertyData.bookingPetsAllowed === "Yes"
                              ? "text-green-500"
                              : "text-red-500"
                          }`}
                        >
                          {propertyData.bookingPetsAllowed}
                        </span>
                      </div>
                    </div>
                  </div>

                  {formErrors.bookingPrice && (
                    <p className="text-red-500 text-sm mt-1">
                      {formErrors.bookingPrice}
                    </p>
                  )}
                </div>
              )}
            </>
          )}

          {/* Rooms */}
          <div className="p-4 border rounded-xl border-gray-500">
            <label className="block text-lg font-medium mb-4 text-gray-200">
              Rooms
            </label>
            {propertyData.rooms.map((room, index) => (
              <div key={room.roomId} className="relative">
                <RoomForm
                  room={room}
                  onChange={(updatedRoom) =>
                    handleRoomChange(index, updatedRoom)
                  }
                  HotelAndGuestHouses={HotelAndGuestHouses}
                />
                <button
                  type="button"
                  onClick={() => removeRoom(index)}
                  className="absolute -top-4 right-0 bg-red-500 text-white rounded p-2"
                >
                  Remove Room
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={addRoom}
              className="mt-2 p-2 bg-blue-500 text-white rounded"
            >
              Add Room
            </button>
            {formErrors.rooms && (
              <p className="text-red-500 text-sm mt-1">{formErrors.rooms}</p>
            )}
          </div>

          {/* Outside */}
          <div className="p-4 border rounded-xl border-gray-500">
            <label className="block text-lg font-medium mb-4 text-gray-200">
              Outside Images
            </label>
            <SurroundingOutsideForm
              outside={propertyData.outside}
              onChange={(updatedOutside) =>
                setPropertyData({ ...propertyData, outside: updatedOutside })
              }
            />

            <div className="mt-4">
              <span>Outside Description</span>

              <Editor
                value={propertyData.outsideDescription}
                onChange={(value) =>
                  setPropertyData({
                    ...propertyData,
                    outsideDescription: value,
                  })
                }
              />
            </div>
            {formErrors.outside && (
              <p className="text-red-500 text-sm mt-1">{formErrors.outside}</p>
            )}
          </div>

          {/* Surrounding */}
          <div className="p-4 border rounded-xl border-gray-500 mt-6">
            <label className="block text-lg font-medium mb-4 text-gray-200">
              Surrounding Images
            </label>
            <SurroundingOutsideForm
              outside={propertyData.surrounding}
              onChange={(updatedSurrounding) =>
                setPropertyData({
                  ...propertyData,
                  surrounding: updatedSurrounding,
                })
              }
            />

            <div className="mt-4">
              <span>Surrounding Description</span>
              <Editor
                value={propertyData.surroundingDescription}
                onChange={(value) =>
                  setPropertyData({
                    ...propertyData,
                    surroundingDescription: value,
                  })
                }
              />
            </div>
            {formErrors.surrounding && (
              <p className="text-red-500 text-sm mt-1">
                {formErrors.surrounding}
              </p>
            )}
          </div>

          {/* Location */}
          <div className="mt-6">
            <h2 className="text-lg font-medium text-gray-200">Location</h2>
            <LocationSelector
              location={propertyData.location}
              onChange={(updatedLocation) =>
                setPropertyData({ ...propertyData, location: updatedLocation })
              }
            />
            {formErrors.locationName && (
              <p className="text-red-500 text-sm mt-1">
                {formErrors.locationName}
              </p>
            )}

            {formErrors.city && (
              <p className="text-red-500 text-sm mt-1">{formErrors.city}</p>
            )}
          </div>

          {/* Description Input */}
          <div className="mb-10">
            <label
              htmlFor="description"
              className="block text-lg font-medium text-gray-200"
            >
              Description
            </label>
            <Editor
              value={propertyData.description}
              onChange={(value) =>
                setPropertyData({ ...propertyData, description: value })
              }
            />

            {formErrors.description && (
              <p className="text-red-500 text-sm mt-1">
                {formErrors.description}
              </p>
            )}
          </div>

          {/* Property Owner Inputs */}
          <div>
            <label
              htmlFor="propertyOwnerName"
              className="block text-lg font-medium text-gray-200"
            >
              Property Owner Name
            </label>
            <input
              type="text"
              name="propertyOwnerName"
              id="propertyOwnerName"
              value={propertyData.propertyOwner.name}
              onChange={(e) =>
                setPropertyData({
                  ...propertyData,
                  propertyOwner: {
                    ...propertyData.propertyOwner,
                    name: e.target.value,
                  },
                })
              }
              className="mt-1 block w-full p-2 border border-gray-700 bg-gray-800 text-white rounded-md"
              placeholder="Enter owner name"
            />

            {formErrors.ownerName && (
              <p className="text-red-500 text-sm mt-1">
                {formErrors.ownerName}
              </p>
            )}
          </div>

          <div>
            <label
              htmlFor="propertyOwnerPhone"
              className="block text-lg font-medium text-gray-200"
            >
              Property Owner Phone
            </label>
            <input
              type="tel"
              name="propertyOwnerPhone"
              id="propertyOwnerPhone"
              value={propertyData.propertyOwner.phone}
              onChange={(e) =>
                setPropertyData({
                  ...propertyData,
                  propertyOwner: {
                    ...propertyData.propertyOwner,
                    phone: e.target.value,
                  },
                })
              }
              className="mt-1 block w-full p-2 border border-gray-700 bg-gray-800 text-white rounded-md"
              placeholder="Enter owner phone number"
            />

            {formErrors.ownerPhone && (
              <p className="text-red-500 text-sm mt-1">
                {formErrors.ownerPhone}
              </p>
            )}
          </div>

          {/* Submit Button */}
          <div>
            <button
              onClick={handleSubmit}
              disabled={loading}
              type="submit"
              className="w-full p-2 bg-mainColor text-white rounded"
            >
              {loading ? (
                <div className="flex justify-center items-center text-center text-white space-x-3">
                  <TailSpin
                    visible={loading}
                    height="20"
                    width="20"
                    color="#00cafc"
                    ariaLabel="loading"
                  />{" "}
                  <span>Creating...</span>
                </div>
              ) : (
                "Create Post"
              )}
            </button>
          </div>
        </form>
      </main>
    </div>
  );
}

export default CreatePostPage;
