import React, { useEffect } from "react";
import toast from "react-hot-toast";
import NotificationSound from "../../assets/sounds/notification.mp3";
import { X } from "lucide-react";

const UserNotification = ({ conversations }) => {
  useEffect(() => {
    if (conversations.length > 0) {
      const audio = new Audio(NotificationSound);
      audio.play().catch((error) => {
        console.error("Error playing sound:", error);
      });

      conversations.forEach((conversation) => {
        toast.custom((t) => (
          <div
            className={`${
              t.visible ? "animate-enter" : "animate-leave"
            } max-w-md w-full bg-white shadow-lg rounded-xl pointer-events-auto flex ring-1 ring-black ring-opacity-5 border border-gray-300 transition-transform transform duration-150 cursor-pointer`}
            onClick={() => toast.dismiss(t.id)}
          >
            <div className="flex-1 w-0 p-4">
              <div className="flex items-start">
                <div className="flex-shrink-0 pt-0.5">
                  <img
                    className="h-14 w-14 rounded-full"
                    src={conversation.postImg}
                    alt="post"
                  />
                </div>
                <div className="ml-3 flex-1">
                  <p className="text-sm font-medium text-mainColor">
                    New Message
                  </p>
                  <p className="mt-1 text-xs text-gray-600">
                    {conversation.postName}
                  </p>
                  <p className="mt-1 text-sm text-gray-600">
                    You have a new message from the admin!
                  </p>
                </div>
              </div>
            </div>
            <div className="flex">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  toast.dismiss(t.id);
                }}
                className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-mainColor hover:bg-gray-200 transition-colors duration-150 focus:outline-none"
              >
                <X />
              </button>
            </div>
          </div>
        ));
      });
    }
  }, [conversations]);

  return null;
};

export default UserNotification;
