import React, { useContext } from "react";
import { ThemeContext } from "../../../context/ThemeContext";
import { bouncy } from "ldrs";

bouncy.register();

function Loader() {
  const { theme } = useContext(ThemeContext);
  const isDarkMode = theme === "dark";

  return (
    <div className="h-screen pt-20 flex justify-center items-center w-full bg-backgroundColor dark:bg-backgroundDark">
      <l-bouncy
        size="50"
        speed="1"
        color={isDarkMode ? "#ffffff" : "#000"}
      ></l-bouncy>
    </div>
  );
}

export default Loader;
