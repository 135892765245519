export const getLocalizedText = (text, language) => {
  if (!text) return "";

  const parts = text.split("|");

  const languageMap = {
    en: parts[0] || "",
    am: parts[1] || "",
    ar: parts[2] || "",
  };

  return languageMap[language] || parts[0] || "";
};
