import React from "react";
import { FaAngleLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { getLocalizedText } from "../../../hooks/langSeparator";

function SmallScreenNavbar({ post, language }) {
  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate(-1);
  };

  const getName = () => getLocalizedText(post?.name, language);

  return (
    <div className="block md:hidden fixed top-0 left-0 w-full bg-gray-100 dark:bg-gray-800 border-b border-gray-300 dark:border-gray-700 z-20">
      <div className="flex items-center justify-between px-4 py-3">
        <button
          onClick={handleBackClick}
          aria-label="Go back"
          className="flex items-center justify-center p-3 rounded-full text-gray-900 dark:text-gray-100 hover:bg-gray-300 dark:hover:bg-gray-600"
        >
          <FaAngleLeft />
        </button>

        <h1 className="absolute left-1/2 transform -translate-x-1/2 text-lg font-semibold text-gray-900 dark:text-gray-100">
          {getName() || "Post"}
        </h1>
      </div>

      {/* sections */}
      {/* <div>
        <hr className="border-t border-gray-300 dark:border-gray-700" />
        phots
      </div> */}
    </div>
  );
}

export default SmallScreenNavbar;
