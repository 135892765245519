import React from "react";
import { useNavigate } from "react-router-dom";
import { Home } from "lucide-react";

function CancelPage() {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col justify-center items-center h-screen">
      <div className="p-8 text-center">
        <h1 className="text-2xl font-semibold text-red-600">
          Booking Cancelled
        </h1>
        <p className="mt-4 text-gray-600 dark:text-gray-400">
          Your booking has been successfully cancelled. If this was a mistake,
          please reach out to support or try booking again.
        </p>
        <div className="flex justify-center mt-6">
          <button
            onClick={() => navigate("/")}
            className="bg-mainColor text-white px-6 py-2 rounded-lg hover:bg-hovermainColor flex items-center justify-center space-x-2"
          >
            <Home size={18} />
            <span>Go Back Home</span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default CancelPage;
