import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import Loader from "../common/Loader/Loader";
import * as PANOLENS from "panolens";
import { useTranslation } from "react-i18next";
import SmallScreenNavbar from "./post-detail/SmallScreenNavbar";

function SphereView() {
  const { postId, roomId } = useParams();
  const [post, setPost] = useState(null);
  const [room, setRoom] = useState(null);
  const [loading, setLoading] = useState(true);
  const panoramaRefs = useRef([]);
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  useEffect(() => {
    const fetchPostAndRoom = async () => {
      try {
        const postRef = doc(db, "posts", postId);
        const postSnap = await getDoc(postRef);

        if (postSnap.exists()) {
          const fetchedPost = postSnap.data();
          setPost(fetchedPost);

          const roomData = fetchedPost.rooms.find(
            (room) => room.roomId === parseInt(roomId)
          );

          if (roomData) {
            setRoom(roomData);
          } else {
            console.error("Room not found!");
          }
        } else {
          console.error("Post not found!");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPostAndRoom();
  }, [postId, roomId]);

  useEffect(() => {
    if (room) {
      room.instances.forEach((instance, index) => {
        if (instance.panoramaImage) {
          const panorama = new PANOLENS.ImagePanorama(
            Array.isArray(instance.panoramaImage)
              ? instance.panoramaImage[0]
              : instance.panoramaImage
          );
          const viewer = new PANOLENS.Viewer({
            container: panoramaRefs.current[index],
            autoRotate: true,
            autoRotateSpeed: 0.5,
            controlBar: true,
          });

          viewer.add(panorama);
          return () => viewer.dispose();
        }
      });
    }
  }, [room]);

  if (loading) {
    return <Loader />;
  }

  if (!post) {
    return (
      <div className="h-screen text-center text-xl font-bold py-20 flex justify-center items-center dark:text-gray-200 text-black">
        Post not found
      </div>
    );
  }

  if (!room) {
    return (
      <div className="h-screen text-center text-xl font-bold py-20 flex justify-center items-center dark:text-gray-200 text-black">
        Room not found
      </div>
    );
  }

  return (
    <div className="max-w-7xl pt-20 mx-auto px-4">
      <SmallScreenNavbar post={post} language={language} />
      <h1 className="text-3xl font-bold mb-4 text-gray-900 dark:text-gray-100">
        {room.roomType} 3D Tour
      </h1>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
        {room.instances.map((instance, index) => (
          <div
            key={instance.instanceId}
            className="rounded-lg p-4 bg-gray-100 dark:bg-gray-700 shadow-sm"
          >
            <h3 className="text-xl font-medium mb-2 text-gray-900 dark:text-gray-100">
              {room.roomType} {instance.instanceId}
            </h3>

            <div
              className="relative w-full h-96"
              ref={(el) => (panoramaRefs.current[index] = el)}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default SphereView;
