import { doc, getDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { db } from "../../../firebase";
import YetAnotherLightbox from "yet-another-react-lightbox";
import {
  Counter,
  Fullscreen,
  Zoom,
  Thumbnails,
} from "yet-another-react-lightbox/plugins";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/counter.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import Loader from "../../common/Loader/Loader";
import { useTranslation } from "react-i18next";

function SurroundingGallary({ postId }) {
  const [postData, setPostData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  useEffect(() => {
    const fetchPostData = async () => {
      try {
        const postRef = doc(db, "posts", postId);
        const postDoc = await getDoc(postRef);

        if (postDoc.exists) {
          setPostData(postDoc.data());
        } else {
          console.error("No such post!");
        }
      } catch (error) {
        console.error("Error fetching post data: ", error);
      }
      setLoading(false);
    };

    fetchPostData();
  }, [postId]);

  if (loading) {
    return <Loader />;
  }

  if (!postData) {
    return (
      <div className="h-screen text-center text-xl font-bold py-20 flex justify-center items-center dark:text-gray-200 text-black">
        Post not found
      </div>
    );
  }

  const getName = () => {
    const Names = postData.name.split("|");

    const languageMap = {
      en: Names[0],
      am: Names[1],
      ar: Names[2],
    };

    return languageMap[language] || Names[0];
  };

  const { surrounding } = postData;
  const allSlides = surrounding.normalImages.map((img) => ({
    src: img,
  }));

  const openLightbox = (index) => {
    setCurrentImageIndex(index);
    setLightboxOpen(true);
  };

  return (
    <div className="max-w-7xl mx-auto px-4 py-20 lg:pb-5">
      <div className="flex justify-between mb-6">
        <h1 className="text-3xl font-bold dark:text-white max-w-52 md:max-w-none">
          Surrounding of the {getName()}
        </h1>

        <div className="flex justify-center items-center">
          <Link
            to={`/post/${postId}/surrounding/3D-tour`}
            className="bg-mainColor text-white px-4 py-2 rounded-lg shadow-md hover:bg-hovermainColor transition duration-300"
          >
            3D tour
          </Link>
        </div>
      </div>

      {surrounding?.normalImages?.length > 0 ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          {surrounding.normalImages.map((imgUrl, index) => (
            <img
              key={index}
              src={imgUrl}
              alt={`surrounding-${index}`}
              className="w-full h-60 object-cover rounded-lg shadow-sm dark:shadow-md cursor-pointer"
              onClick={() => openLightbox(index)}
              loading="lazy"
            />
          ))}
        </div>
      ) : (
        <p className="text-gray-500 dark:text-gray-400 h-60 flex justify-center items-center">
          No surrounding images available.
        </p>
      )}

      {lightboxOpen && (
        <YetAnotherLightbox
          plugins={[Counter, Fullscreen, Zoom, Thumbnails]}
          open={lightboxOpen}
          close={() => setLightboxOpen(false)}
          slides={allSlides}
          index={currentImageIndex}
          styles={{ root: { "--yarl__color_backdrop": "rgba(0, 0, 0, .8)" } }}
        />
      )}
    </div>
  );
}

export default SurroundingGallary;
