import React from "react";
import { Skeleton } from "antd";

function PostCardLoader() {
  return (
    <div className="w-full max-w-md mx-auto overflow-hidden">
      <div className="relative">
        <Skeleton.Node
          active
          style={{
            width: 448,
            height: 256,
            borderTopLeftRadius: 10,
            borderBottomRightRadius: 10,
          }}
          className="w-full h-64 object-cover bg-gray-200 dark:bg-slate-700 rounded-tl-[10px] rounded-br-[10px]"
        />

        <div className="absolute bottom-0 left-0">
          <Skeleton.Input active style={{ width: 120, height: 30 }} />
        </div>

        <div className="absolute top-0 right-0">
          <Skeleton.Input active style={{ width: 80, height: 30 }} />
        </div>
      </div>

      <div>
        <Skeleton.Input
          active
          style={{ width: 140 }}
          className="w-[140px] bg-gray-200 dark:bg-gray-700 rounded-sm"
        />
      </div>
    </div>
  );
}

export default PostCardLoader;
