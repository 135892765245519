import React, { useContext } from "react";
import { Button, Modal } from "antd";
import { Clipboard, Mail, Send } from "lucide-react";
import { FaXTwitter } from "react-icons/fa6";
import { BsWhatsapp } from "react-icons/bs";
import { FaFacebookSquare, FaInstagram, FaTimes } from "react-icons/fa";
import toast from "react-hot-toast";
import { ThemeContext } from "../../../context/ThemeContext";

function ShareModal({ setIsShareModalVisible, isShareModalVisible }) {
  const { theme } = useContext(ThemeContext);
  const isDarkMode = theme === "dark";
  const copyToClipboard = () => {
    navigator.clipboard.writeText(window.location.href);
    toast.success("Link copied to clipboard!");
    setIsShareModalVisible(false);
  };

  const handleShareModalClose = () => {
    setIsShareModalVisible(false);
  };

  return (
    <div>
      <Modal
        centered
        open={isShareModalVisible}
        onCancel={handleShareModalClose}
        closeIcon={
          <FaTimes className="text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100" />
        }
        footer={null}
        styles={{
          content: {
            backgroundColor: isDarkMode ? "#1f2937" : "#ffffff",
            borderRadius: "25px",
          },
        }}
      >
        <h1 className="text-xl py-3 font-bold leading-tight tracking-tight text-gray-900 dark:text-gray-100 md:text-2xl">
          Share this post
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 my-5">
          <Button
            className="flex justify-center space-x-2 px-3 py-6 border dark:bg-backgroundDark border-gray-400 dark:border-gray-600 hover:dark:bg-gray-700 rounded-xl font-semibold dar dark:text-white"
            onClick={copyToClipboard}
          >
            <Clipboard className="text-mainColor text-xl" />
            <span>Copy Link</span>
          </Button>
          <Button
            className="flex justify-center space-x-2 px-3 py-6 border dark:bg-backgroundDark border-gray-400 dark:border-gray-600 hover:dark:bg-gray-700 rounded-xl font-semibold dark:text-white"
            href={`mailto:?body=${window.location.href}`}
            target="_blank"
          >
            <Mail className="text-mainColor text-xl" />
            <span>Share via Email</span>
          </Button>
          <Button
            className="flex justify-center space-x-2 px-3 py-6 border dark:bg-backgroundDark border-gray-400 dark:border-gray-600 hover:dark:bg-gray-700 rounded-xl font-semibold dark:text-white"
            href={`https://t.me/share/url?url=${window.location.href}`}
            target="_blank"
          >
            <Send className="text-mainColor text-xl" />
            <span>Share on Telegram</span>
          </Button>
          <Button
            className="flex justify-center space-x-2 px-3 py-6 border dark:bg-backgroundDark border-gray-400 dark:border-gray-600 hover:dark:bg-gray-700 rounded-xl font-semibold dark:text-white"
            href={`https://twitter.com/intent/tweet?url=${window.location.href}`}
            target="_blank"
          >
            <FaXTwitter className="text-mainColor text-xl" />
            <span>Share on X (Twitter)</span>
          </Button>
          <Button
            className="flex justify-center space-x-2 px-3 py-6 border dark:bg-backgroundDark border-gray-400 dark:border-gray-600 hover:dark:bg-gray-700 rounded-xl font-semibold dark:text-white"
            href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
            target="_blank"
          >
            <FaFacebookSquare className="text-mainColor text-xl" />
            <span>Share on Facebook</span>
          </Button>
          <Button
            className="flex justify-center space-x-2 px-3 py-6 border dark:bg-backgroundDark border-gray-400 dark:border-gray-600 hover:dark:bg-gray-700 rounded-xl font-semibold dark:text-white"
            href={`https://www.instagram.com/?url=${window.location.href}`}
            target="_blank"
          >
            <FaInstagram className="text-mainColor text-xl" />
            <span>Share on Instagram</span>
          </Button>
          <Button
            className="flex justify-center space-x-2 px-3 py-6 border dark:bg-backgroundDark border-gray-400 dark:border-gray-600 hover:dark:bg-gray-700 rounded-xl font-semibold dark:text-white"
            href={`https://wa.me/?text=${window.location.href}`}
            target="_blank"
          >
            <BsWhatsapp className="text-mainColor text-xl" />
            <span>Share on WhatsApp</span>
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default ShareModal;
