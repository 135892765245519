import {
  DollarSign,
  FileImage,
  MessageCircleMore,
  UserPlus,
  Users,
  House,
  ClipboardList,
  PanelRightOpen,
  PanelLeftClose,
  SquareChartGantt,
  Mail,
  Book,
} from "lucide-react";
import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Link } from "react-router-dom";

const SIDEBAR_ITEMS = [
  {
    name: "Overview",
    icon: SquareChartGantt,
    color: "#6366f1",
    href: "/admin",
  },
  {
    name: "Posts",
    icon: FileImage,
    color: "#8B5CF6",
    href: "/admin/posts",
    show: (role) => role === "manager",
  },
  {
    name: "Users",
    icon: Users,
    color: "#EC4899",
    href: "/admin/users",
    show: (role) => role === "manager",
  },
  {
    name: "Sales",
    icon: DollarSign,
    color: "#10B981",
    href: "/admin/sales",
    show: (role) => role === "manager",
  },
  {
    name: "Messages",
    icon: MessageCircleMore,
    color: "#F59E0B",
    href: "/admin/messages",
  },
  {
    name: "Requests",
    icon: ClipboardList,
    color: "#cc3300",
    href: "/admin/Requests",
  },

  {
    name: "Bookings",
    icon: Book,
    color: "#0066ff",
    href: "/admin/Bookings",
  },

  {
    name: "Contact messages",
    icon: Mail,
    color: "#00a3cc",
    href: "/admin/Contact",
  },

  {
    name: "Go to Home",
    icon: House,
    color: "#6EE7B7",
    href: "/",
  },
];

const Sidebar = ({ role, unreadCounts }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  return (
    <motion.div
      className={`relative z-10 transition-all duration-300 ease-in-out flex-shrink-0 ${
        isSidebarOpen ? "w-64" : "w-20"
      }`}
      animate={{ width: isSidebarOpen ? 256 : 80 }}
    >
      <div className="h-full bg-gray-800 bg-opacity-50 backdrop-blur-md p-4 flex flex-col border-r border-gray-700">
        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
          className="p-2 rounded-full hover:bg-gray-700 transition-colors max-w-fit"
        >
          {isSidebarOpen ? (
            <PanelLeftClose size={24} />
          ) : (
            <PanelRightOpen size={24} />
          )}
        </motion.button>

        <nav className="mt-8 flex-grow">
          {SIDEBAR_ITEMS.map((item) => {
            if (item.show && !item.show(role)) {
              return null;
            }
            return (
              <Link key={item.href} to={item.href}>
                <motion.div className="flex items-center p-4 text-sm font-medium rounded-lg hover:bg-gray-700 transition-colors mb-2">
                  <item.icon
                    size={20}
                    style={{ color: item.color, minWidth: "20px" }}
                  />
                  {!isSidebarOpen && (
                    <div className="relative ml-2">
                      {item.name === "Messages" &&
                        unreadCounts.conversations > 0 && (
                          <span className="absolute top-0 right-0 w-4 h-4 flex text-center justify-center bg-red-600 text-white text-xs font-semibold px-1 rounded-full">
                            {unreadCounts.conversations}
                          </span>
                        )}
                      {item.name === "Requests" &&
                        unreadCounts.requests > 0 && (
                          <span className="absolute top-0 right-0 w-4 h-4 flex text-center justify-center bg-red-600 text-white text-xs font-semibold px-1 rounded-full">
                            {unreadCounts.requests}
                          </span>
                        )}
                      {item.name === "Contact messages" &&
                        unreadCounts.contacts > 0 && (
                          <span className="absolute top-0 right-0 w-4 h-4 flex text-center justify-center bg-red-600 text-white text-xs font-semibold px-1 rounded-full">
                            {unreadCounts.contacts}
                          </span>
                        )}
                    </div>
                  )}

                  <AnimatePresence>
                    {isSidebarOpen && (
                      <motion.span
                        className="ml-4 flex-1 whitespace-nowrap flex justify-between"
                        initial={{ opacity: 0, width: 0 }}
                        animate={{ opacity: 1, width: "auto" }}
                        exit={{ opacity: 0, width: 0 }}
                        transition={{ duration: 0.2, delay: 0.3 }}
                      >
                        {item.name}
                        {item.name === "Messages" &&
                          unreadCounts.conversations > 0 && (
                            <span className="ml-2 w-4 h-4 flex text-center justify-center bg-red-600 text-white text-xs font-semibold px-1 rounded-full">
                              {unreadCounts.conversations}
                            </span>
                          )}
                        {item.name === "Requests" &&
                          unreadCounts.requests > 0 && (
                            <span className="ml-2 w-4 h-4 flex text-center justify-center bg-red-600 text-white text-xs font-semibold px-1 rounded-full">
                              {unreadCounts.requests}
                            </span>
                          )}
                        {item.name === "Contact messages" &&
                          unreadCounts.contacts > 0 && (
                            <span className="ml-2 w-4 h-4 flex text-center justify-center bg-red-600 text-white text-xs font-semibold px-1 rounded-full">
                              {unreadCounts.contacts}
                            </span>
                          )}
                      </motion.span>
                    )}
                  </AnimatePresence>
                </motion.div>
              </Link>
            );
          })}
        </nav>
      </div>
    </motion.div>
  );
};

export default Sidebar;
