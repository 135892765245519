import { Dropdown } from "antd";
import { ChevronDown, ChevronUp } from "lucide-react";
import React, { useEffect, useState } from "react";
import { FaDollarSign } from "react-icons/fa";
import { bouncy } from "ldrs";
import { getLocalizedText } from "../../../hooks/langSeparator";

bouncy.register();

function DetailSection({
  post,
  toggleRoomGallary,
  toggleOutSurrGallary,
  addRoom,
  t,
  language,
  exchangeRate,
  setPost,
  selectedRooms,
  currency,
  setCurrency,
  currencyLoading,
  isDarkMode,
}) {
  const [isRoomExpanded, setIsRoomExpanded] = useState({});
  const [isOutsideExpanded, setIsOutsideExpanded] = useState(false);
  const [isSurroundingExpanded, setIsSurroundingExpanded] = useState(false);

  const toggleRoomDescription = (roomId) => {
    setIsRoomExpanded((prev) => ({
      ...prev,
      [roomId]: !prev[roomId],
    }));
  };

  ///////////////////////////////////////////////////////////////////////////////
  const HotelAndGuestHouses =
    post.category === "Hotels" || post.category === "Guest House";

  const BookingProperty = post.purchaseType === "Booking";
  const RentProperty = post.purchaseType === "Rent";
  const SaleProperty = post.purchaseType === "Sale";

  ///////////////////////////////////////////////////////////////////////////////

  const getDescription = () => getLocalizedText(post?.description, language);

  const getName = () => getLocalizedText(post?.name, language);

  const getOutsideDescription = () =>
    getLocalizedText(post?.outside.outsideDescription, language);

  const getSurroundingDescription = () =>
    getLocalizedText(post?.surrounding.surroundingDescription, language);

  ///////////////////////////////////////////////////////////////////////////////

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const year = date.getUTCFullYear();

    return `${day}/${month}/${year}`;
  };

  const handleCurrencyChange = (currency) => {
    setCurrency(currency);

    const currentUrl = new URL(window.location.href);

    const params = new URLSearchParams(currentUrl.search);
    params.set("currency", currency);

    const newUrl = `${currentUrl.pathname}?${params.toString()}`;
    window.history.replaceState({}, "", newUrl);
  };

  useEffect(() => {
    const calculatePrice = (price) =>
      (parseFloat(price) / exchangeRate).toFixed(2);

    const updatedFields = {};

    if (HotelAndGuestHouses && post.rooms) {
      updatedFields.rooms = post.rooms.map((room) => ({
        ...room,
        roomPriceExchanged: calculatePrice(room.roomPrice),
      }));
    } else {
      const priceFieldMap = {
        RentProperty: "rentPrice",
        SaleProperty: "salePrice",
        BookingProperty: "bookingPrice",
      };

      const activeField = Object.entries(priceFieldMap).find(([key]) =>
        eval(key)
      );

      if (activeField) {
        const [key, fieldName] = activeField;
        updatedFields[`${fieldName}Exchanged`] = calculatePrice(
          post[fieldName]
        );
      }
    }

    setPost((prevPost) => ({
      ...prevPost,
      ...updatedFields,
    }));
  }, [
    HotelAndGuestHouses,
    RentProperty,
    SaleProperty,
    BookingProperty,
    exchangeRate,
    setPost,
  ]);

  ///////////////////////////////////////////////////////////////////////////////

  const menuItems = [
    {
      key: "ETB",
      label: (
        <button
          className="flex items-center w-full px-2 py-1 text-left space-x-2"
          onClick={() => handleCurrencyChange("ETB")}
        >
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/7/71/Flag_of_Ethiopia.svg"
            alt="Ethiopian Flag"
            className="w-4 h-3 rounded-sm"
          />
          <span>ETB</span>
        </button>
      ),
    },
    {
      key: "USD",
      label: (
        <button
          className="flex items-center w-full px-2 py-1 text-left space-x-2"
          onClick={() => handleCurrencyChange("USD")}
        >
          <FaDollarSign className="text-green-500" />
          <span>USD</span>
        </button>
      ),
    },
  ];

  return (
    <div className="flex-1">
      <h1 className="text-3xl text-black dark:text-gray-300 font-medium mb-2">
        {getName()}
      </h1>
      <p className="text-gray-600 dark:text-gray-300 text-lg mb-4">
        {post.location.name}
      </p>

      {!HotelAndGuestHouses && (
        <div className="flex items-center mb-4 justify-between">
          {RentProperty && (
            <div className="flex lg:justify-center lg:items-center space-x-3">
              <p className="text-xl font-semibold text-gray-900 dark:text-gray-100">
                {currencyLoading ? (
                  <l-bouncy
                    size="35"
                    speed="1"
                    color={isDarkMode ? "#ffffff" : "#000"}
                  ></l-bouncy>
                ) : (
                  <>
                    <span>
                      {currency === "USD"
                        ? new Intl.NumberFormat().format(
                            post.rentPriceExchanged
                          )
                        : new Intl.NumberFormat().format(post.rentPrice)}{" "}
                      {currency === "USD" ? "$" : t("birr")}
                    </span>{" "}
                    <span className="text-base font-medium">
                      /{" "}
                      {post.rentPaymentType === "yearly"
                        ? t("yearly")
                        : t("monthly")}
                    </span>
                  </>
                )}
              </p>
              <p className="text-black font-semibold dark:text-gray-200 py-1 px-2 dark:bg-gray-500 bg-gray-400 rounded-lg flex justify-center items-center text-center">
                {t("for_rent")}
              </p>
            </div>
          )}

          {SaleProperty && (
            <div className="flex lg:justify-center lg:items-center space-x-3">
              <p className="text-xl font-semibold text-gray-900 dark:text-gray-100">
                {currencyLoading ? (
                  <l-bouncy
                    size="35"
                    speed="1"
                    color={isDarkMode ? "#ffffff" : "#000"}
                  ></l-bouncy>
                ) : (
                  <span>
                    {currency === "USD"
                      ? new Intl.NumberFormat().format(post.salePriceExchanged)
                      : new Intl.NumberFormat().format(post.salePrice)}{" "}
                    {currency === "USD" ? "$" : t("birr")}
                  </span>
                )}
              </p>
              <p className="text-black font-semibold dark:text-gray-200 py-1 px-2 dark:bg-gray-500 bg-gray-400 rounded-lg flex justify-center items-center text-center">
                {t("for_sale")}
              </p>
            </div>
          )}

          {BookingProperty && (
            <div className="flex lg:justify-center lg:items-center space-x-3">
              <p className="text-xl font-semibold text-gray-900 dark:text-gray-100">
                {currencyLoading ? (
                  <l-bouncy
                    size="35"
                    speed="1"
                    color={isDarkMode ? "#ffffff" : "#000"}
                  ></l-bouncy>
                ) : (
                  <span>
                    {currency === "USD"
                      ? new Intl.NumberFormat().format(
                          post.bookingPriceExchanged
                        )
                      : new Intl.NumberFormat().format(post.bookingPrice)}{" "}
                    {currency === "USD" ? "$" : t("birr")}
                  </span>
                )}
              </p>
              <p className="text-black font-semibold dark:text-gray-200 py-1 px-2 dark:bg-gray-500 bg-gray-400 rounded-lg flex justify-center items-center text-center">
                per night
              </p>
            </div>
          )}

          <div className="flex items-center space-x-3 text-gray-900 dark:text-gray-200">
            <p className="text-sm lg:flex hidden">Currency</p>
            <Dropdown menu={{ items: menuItems }} trigger={["click"]}>
              <button className="font-medium rounded-lg text-sm border border-gray-300 dark:border-gray-700 px-2 py-1">
                {currency}
              </button>
            </Dropdown>
          </div>
        </div>
      )}

      <div id="about" className="my-14">
        <h2 className="text-xl border p-6 border-gray-300 dark:border-gray-700 text-black font-semibold dark:text-gray-300 mb-3 rounded-lg">
          About this Property
        </h2>
        <p
          className="p-4 text-gray-700 dark:text-gray-300"
          dangerouslySetInnerHTML={{ __html: getDescription() }}
        ></p>
        {!HotelAndGuestHouses && (
          <p className="text-gray-600 dark:text-gray-400 mt-2">
            Listed At - {formatDate(post.createdAt)}
          </p>
        )}
      </div>

      <div id="rooms" className="my-14 py-4">
        <div className="flex justify-between items-center border p-6 border-gray-300 dark:border-gray-700 text-gray-900 dark:text-gray-200 rounded-lg">
          <div className="text-xl font-semibold">
            {HotelAndGuestHouses ? "Rooms and Suites" : "Room Details"}
          </div>

          {HotelAndGuestHouses && (
            <div className="flex items-center space-x-3">
              <p className="text-sm flex">Currency</p>
              <Dropdown menu={{ items: menuItems }} trigger={["click"]}>
                <button className="font-medium rounded-lg text-sm border border-gray-300 dark:border-gray-700 px-2 py-1">
                  {currency}
                </button>
              </Dropdown>
            </div>
          )}
        </div>
        <ul className="space-y-6">
          {post.rooms.map((room) => {
            const totalImagesCount = room.instances.reduce(
              (acc, instance) => acc + instance.normalImages.length,
              0
            );
            const moreImagesCount = totalImagesCount - 2;

            const getRoomType = () =>
              getLocalizedText(room?.roomType, language);

            const getRoomDescription = () =>
              getLocalizedText(room?.roomDescription, language);

            return (
              <li
                key={room.roomId}
                className="mb-5 pb-10 px-6 border-b-2 border-gray-300 dark:border-gray-700 p-4 flex flex-col space-y-4"
              >
                <div className="flex flex-col lg:flex-row lg:items-center justify-between space-y-4 lg:space-y-0 lg:space-x-6">
                  <div className="flex-1">
                    <div className="flex items-center">
                      <p className="text-lg font-semibold text-gray-900 dark:text-gray-100">
                        {!HotelAndGuestHouses && (
                          <>
                            <span className="text-xl font-bold">
                              {room.roomInstancesCount}
                            </span>{" "}
                          </>
                        )}
                        {getRoomType()}
                      </p>
                    </div>
                  </div>

                  <div
                    onClick={() => toggleRoomGallary(room.roomId)}
                    className="grid grid-cols-2 gap-4 lg:w-1/2 relative"
                  >
                    {room.instances[0].normalImages
                      .slice(0, 2)
                      .map((image, index) => (
                        <div
                          key={`${room.instances[0].instanceId}-${index}`}
                          className="relative"
                        >
                          <img
                            src={image}
                            alt={`${room.roomType} pic ${index + 1}`}
                            className="w-full h-32 object-cover rounded-lg shadow-sm dark:shadow-md cursor-pointer"
                          />

                          {index === 1 && moreImagesCount > 0 && (
                            <div className="absolute inset-0 bg-black bg-opacity-50 rounded-lg flex items-center justify-center cursor-pointer">
                              <span className="text-white text-lg font-bold">
                                +{moreImagesCount} more
                              </span>
                            </div>
                          )}
                        </div>
                      ))}
                  </div>
                </div>

                {room.roomDescription && (
                  <div className="text-gray-700 dark:text-gray-300 mt-4 flex">
                    <p
                      className={`transition-all ${
                        isRoomExpanded[room.roomId]
                          ? "line-clamp-none"
                          : "line-clamp-2"
                      }`}
                      dangerouslySetInnerHTML={{ __html: getRoomDescription() }}
                    ></p>
                    <button
                      onClick={() => toggleRoomDescription(room.roomId)}
                      className="mt-2 text-blue-600 dark:text-blue-400 font-semibold"
                    >
                      {isRoomExpanded[room.roomId] ? (
                        <ChevronUp className="w-5 h-5 text-gray-700 dark:text-gray-300" />
                      ) : (
                        <ChevronDown className="w-5 h-5 text-gray-700 dark:text-gray-300" />
                      )}
                    </button>
                  </div>
                )}

                {HotelAndGuestHouses && (
                  <div className="flex flex-raw lg:flex-col items-start lg:items-end justify-between lg:space-y-4 mt-6 lg:mt-8">
                    <div className="text-xl font-semibold text-gray-900 dark:text-gray-100">
                      {currencyLoading ? (
                        <div className="my-2">
                          <l-bouncy
                            size="35"
                            speed="1"
                            color={isDarkMode ? "#ffffff" : "#000"}
                          ></l-bouncy>
                        </div>
                      ) : (
                        <>
                          {currency === "USD"
                            ? new Intl.NumberFormat().format(
                                room.roomPriceExchanged
                              ) || room.roomPrice
                            : new Intl.NumberFormat().format(
                                room.roomPrice
                              )}{" "}
                          {currency === "USD" ? "$" : t("birr")}{" "}
                          <span className="text-base font-normal text-gray-500 dark:text-gray-400">
                            per night
                          </span>
                        </>
                      )}
                    </div>

                    <button
                      onClick={() => addRoom(room.roomId)}
                      className="px-4 py-2 bg-mainColor text-white font-bold rounded-lg hover:bg-hovermainColor lg:ml-4"
                    >
                      Book
                    </button>
                  </div>
                )}
              </li>
            );
          })}
        </ul>
      </div>

      <div id="outside-and-surrounding" className="my-8 md:my-14 py-4">
        <h2 className="text-xl border p-6 border-gray-300 dark:border-gray-700 font-semibold text-gray-900 dark:text-gray-200 rounded-lg">
          Outside and Surrounding Details
        </h2>
        <ul className="space-y-6">
          <li className="mb-5 pb-10 px-6 border-b border-gray-300 dark:border-gray-700 p-4 flex flex-col space-y-4">
            <div className="flex flex-col lg:flex-row lg:items-center justify-between space-y-4 lg:space-y-0 lg:space-x-6">
              <div className="flex-1">
                <div className="flex items-center space-x-4">
                  <p className="text-lg font-semibold text-gray-900 dark:text-gray-100">
                    Outside
                  </p>
                </div>
              </div>

              <div
                onClick={() => toggleOutSurrGallary("outside")}
                className="grid grid-cols-2 gap-4 lg:w-1/2 relative"
              >
                {post.outside.normalImages.slice(0, 2).map((image, index) => (
                  <div key={`outside-${index}`} className="relative">
                    <img
                      src={image}
                      alt={`Outside pic ${index + 1}`}
                      className="w-full h-32 object-cover rounded-lg shadow-sm dark:shadow-md cursor-pointer"
                    />

                    {index === 1 && post.outside.normalImages.length > 2 && (
                      <div className="absolute inset-0 bg-black bg-opacity-50 rounded-lg flex items-center justify-center cursor-pointer">
                        <span className="text-white text-lg font-bold">
                          +{post.outside.normalImages.length - 2} more
                        </span>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>

            {post.outside.outsideDescription && (
              <div className="text-gray-700 dark:text-gray-300 mt-4 flex">
                <p
                  className={`transition-all ${
                    isOutsideExpanded ? "line-clamp-none" : "line-clamp-2"
                  }`}
                  dangerouslySetInnerHTML={{ __html: getOutsideDescription() }}
                ></p>
                <button
                  onClick={() => setIsOutsideExpanded((prev) => !prev)}
                  className="mt-2 text-blue-600 dark:text-blue-400 font-semibold"
                >
                  {isOutsideExpanded ? (
                    <ChevronUp className="w-5 h-5 text-gray-700 dark:text-gray-300" />
                  ) : (
                    <ChevronDown className="w-5 h-5 text-gray-700 dark:text-gray-300" />
                  )}
                </button>
              </div>
            )}
          </li>

          <li className="mb-5 pb-10 px-6 border-b border-gray-300 dark:border-gray-700 p-4 flex flex-col space-y-4">
            <div className="flex flex-col lg:flex-row lg:items-center justify-between space-y-4 lg:space-y-0 lg:space-x-6">
              <div className="flex-1">
                <div className="flex items-center space-x-4">
                  <p className="text-lg font-semibold text-gray-900 dark:text-gray-100">
                    Surrounding
                  </p>
                </div>
              </div>

              <div
                onClick={() => toggleOutSurrGallary("surrounding")}
                className="grid grid-cols-2 gap-4 lg:w-1/2 relative"
              >
                {post.surrounding.normalImages
                  .slice(0, 2)
                  .map((image, index) => (
                    <div key={`surrounding-${index}`} className="relative">
                      <img
                        src={image}
                        alt={`Surrounding pic ${index + 1}`}
                        className="w-full h-32 object-cover rounded-lg shadow-sm dark:shadow-md cursor-pointer"
                      />

                      {index === 1 &&
                        post.surrounding.normalImages.length > 2 && (
                          <div className="absolute inset-0 bg-black bg-opacity-50 rounded-lg flex items-center justify-center cursor-pointer">
                            <span className="text-white text-lg font-bold">
                              +{post.surrounding.normalImages.length - 2} more
                            </span>
                          </div>
                        )}
                    </div>
                  ))}
              </div>
            </div>

            {post.surrounding.surroundingDescription && (
              <div className="text-gray-700 dark:text-gray-300 mt-4 flex">
                <p
                  className={`transition-all ${
                    isSurroundingExpanded ? "line-clamp-none" : "line-clamp-2"
                  }`}
                  dangerouslySetInnerHTML={{
                    __html: getSurroundingDescription(),
                  }}
                ></p>
                <button
                  onClick={() => setIsSurroundingExpanded((prev) => !prev)}
                  className="mt-2 text-blue-600 dark:text-blue-400 font-semibold"
                >
                  {isSurroundingExpanded ? (
                    <ChevronUp className="w-5 h-5 text-gray-700 dark:text-gray-300" />
                  ) : (
                    <ChevronDown className="w-5 h-5 text-gray-700 dark:text-gray-300" />
                  )}
                </button>
              </div>
            )}
          </li>
        </ul>
      </div>
    </div>
  );
}

export default DetailSection;
