import { BedDouble, Goal, Image, MapPin, Text } from "lucide-react";
import React, { useEffect, useState } from "react";

function PostDetailSideBar() {
  const [activeSection, setActiveSection] = useState("");

  useEffect(() => {
    const sections = [
      "images",
      "about",
      "rooms",
      "outside-and-surrounding",
      "location",
    ];
    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: 0.6,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id);
        }
      });
    }, observerOptions);

    sections.forEach((sectionId) => {
      const sectionElement = document.getElementById(sectionId);
      if (sectionElement) observer.observe(sectionElement);
    });

    return () => {
      sections.forEach((sectionId) => {
        const sectionElement = document.getElementById(sectionId);
        if (sectionElement) observer.unobserve(sectionElement);
      });
    };
  }, []);

  const handleScroll = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      const yOffset = -100;
      const y = section.getBoundingClientRect().top + window.scrollY + yOffset;

      window.scrollTo({
        top: y,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="hidden lg:flex fixed top-0 left-0 py-4 px-2 w-fit h-screen z-20 transition-all duration-300 scale-50 hover:scale-100">
      <ul className="space-y-2 flex flex-col justify-center items-start w-full">
        {[
          { id: "images", label: "Images", icon: <Image size={20} /> },
          { id: "about", label: "About", icon: <Text size={20} /> },
          { id: "rooms", label: "Rooms", icon: <BedDouble size={20} /> },
          {
            id: "outside-and-surrounding",
            label: "Outside and surrounding",
            icon: <Goal size={20} />,
          },
          { id: "location", label: "Location", icon: <MapPin size={20} /> },
        ].map((section) => (
          <li key={section.id} className="relative flex items-center w-full">
            <button
              onClick={() => handleScroll(section.id)}
              className={`flex items-center group transition-all duration-200 ease-in-out scale-90 hover:scale-105 py-4 text-xs ${
                activeSection === section.id
                  ? "text-mainColor font-semibold underline"
                  : "text-gray-400 dark:text-gray-500"
              }`}
            >
              <div
                className={`w-2 h-2 p-1 rounded-full transition-all duration-200 group-hover:opasity-0 ${
                  activeSection === section.id
                    ? "bg-gray-700 dark:bg-gray-100"
                    : "bg-gray-400 dark:bg-gray-500"
                }`}
              ></div>
              <div
                className={`absolute -left-1 top-1 hidden group-hover:block group-hover:scale-100 p-1 font-normal rounded-full shadow-xl ${
                  activeSection === section.id
                    ? "bg-gray-600 dark:bg-gray-100 text-gray-300 dark:text-gray-500"
                    : "bg-gray-400 dark:bg-gray-500 text-gray-600 dark:text-gray-300"
                }`}
              >
                {section.icon}
              </div>
              <div className="absolute left-7 top-1/2 -translate-y-1/2 px-3 py-1 bg-gray-300 dark:bg-gray-700 text-gray-800 dark:text-gray-100 font-normal rounded-md shadow-md hidden scale-75 group-hover:block group-hover:scale-100 transition-all duration-300">
                {section.label}
              </div>
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default PostDetailSideBar;
