import { useState, useEffect, createContext, useContext } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { doc, onSnapshot } from "firebase/firestore";
import { auth, db } from "../firebase";
import logoIcon from "../assets/image/logo.png";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [role, setRole] = useState("user");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const minimumLoadingTime = 100;
    const startLoadingTime = Date.now();

    const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);
        const userDocRef = doc(db, "users", user.uid);
        const unsubscribeUser = onSnapshot(
          userDocRef,
          (snapshot) => {
            if (snapshot.exists()) {
              const userData = snapshot.data();
              setRole(userData.role);
              setFirstName(userData.firstName);
              setLastName(userData.lastName);
            } else {
              setRole("user");
              setFirstName("");
              setLastName("");
            }
            const timeElapsed = Date.now() - startLoadingTime;
            const remainingTime = minimumLoadingTime - timeElapsed;

            setTimeout(() => {
              setLoading(false);
            }, Math.max(remainingTime, 0));
          },
          (error) => {
            console.error("Error fetching user document: ", error);
            setLoading(false);
          }
        );

        return () => unsubscribeUser();
      } else {
        setCurrentUser(null);
        setRole("user");
        setFirstName("");
        setLastName("");
        const timeElapsed = Date.now() - startLoadingTime;
        const remainingTime = minimumLoadingTime - timeElapsed;

        setTimeout(() => {
          setLoading(false);
        }, Math.max(remainingTime, 0));
      }
    });

    return () => {
      unsubscribeAuth();
    };
  }, []);

  if (loading) {
    return (
      <div
        itemType="loading"
        className="relative h-screen pt-10 flex flex-col justify-center items-center text-center text-xl font-bold w-full bg-gray-200 space-y-4 font-Quicksand"
      >
        <div>
          <img className="h-20" src={logoIcon} alt="logo" />
        </div>
        <div className="flex justify-center items-center">
          <l-bouncy size="50" speed="1" color="#00a3cc"></l-bouncy>
        </div>
        <div className="absolute flex flex-col justify-center items-center bottom-10 select-none">
          <span className="flex justify-center items-center text-xs text-gray-700">
            from
          </span>
          <span className="text-mainColor">E-CyberNest</span>
        </div>
      </div>
    );
  }

  return (
    <AuthContext.Provider
      value={{ currentUser, role, loading, firstName, lastName }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
