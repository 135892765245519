import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import {
  Search,
  Check,
  CheckCircle,
  Phone,
  Mail,
  MapPin,
  Home,
  BookCheck,
  Hotel,
  Calendar,
  DollarSign,
  Bed,
  User,
  Clock,
  Tag,
  Banknote,
  Download,
} from "lucide-react";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import { Modal } from "antd";

function BookingsTable({ data }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredBookings, setFilteredBookings] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState(null);

  useEffect(() => {
    const sortedBookings = [...data].sort((a, b) => {
      if (a.status === "pending" && b.status !== "pending") {
        return -1;
      }
      if (a.status !== "pending" && b.status === "pending") {
        return 1;
      }
      return 0;
    });

    setFilteredBookings(sortedBookings);
  }, [data]);

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);

    const filtered = data.filter((booking) => {
      const first_name = booking.bookingData.bookerInfo.first_name
        ? booking.bookingData.bookerInfo.first_name.toLowerCase()
        : "";
      const last_name = booking.bookingData.bookerInfo.last_name
        ? booking.bookingData.bookerInfo.last_name.toLowerCase()
        : "";
      const phone_number = booking.bookingData.bookerInfo.phone_number
        ? booking.bookingData.bookerInfo.phone_number.toLowerCase()
        : "";

      return (
        first_name.includes(term) ||
        last_name.includes(term) ||
        phone_number.includes(term)
      );
    });

    setFilteredBookings(filtered);
  };

  const handleBookingOpen = (booking) => {
    setSelectedBooking(booking);
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setSelectedBooking(null);
  };

  const handleCompleteBooking = async (e, id) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      const bookingRef = doc(db, "Bookings", id);
      await updateDoc(bookingRef, {
        status: "completed",
        completedAt: new Date().toISOString(),
      });

      setFilteredBookings((prevBookings) =>
        prevBookings.map((booking) =>
          booking.id === id ? { ...booking, status: "completed" } : booking
        )
      );
    } catch (error) {
      console.error("Error updating request:", error);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const year = date.getUTCFullYear();

    return `${day}/${month}/${year}`;
  };

  const detailedDateFormat = (date) => {
    const formattedDate = new Date(date).toLocaleString("en-GB", {
      weekday: "short",
      day: "2-digit",
      month: "long",
      year: "numeric",
    });

    return formattedDate;
  };

  return (
    <motion.div
      className="bg-gray-800 bg-opacity-50 backdrop-blur-md shadow-lg rounded-xl p-6 border border-gray-700"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.4 }}
    >
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-semibold text-gray-100">Bookings List</h2>
        <div className="relative">
          <input
            type="text"
            placeholder="Search requests..."
            className="bg-gray-700 text-white placeholder-gray-400 rounded-lg pl-10 pr-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={searchTerm}
            onChange={handleSearch}
          />
          <Search className="absolute left-3 top-2.5 text-gray-400" size={18} />
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-700">
          <thead>
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                Booker
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                Property Name
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                Status
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                Rooms and guests
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                Dates
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                Hotel contacted
              </th>
            </tr>
          </thead>

          <tbody className="divide divide-gray-700">
            {filteredBookings.map((booking) => {
              const HotelAndGuestHouses =
                booking.bookingData.type === "HotelAndGuestHouses";
              const BookingProperty =
                booking.bookingData.type === "BookingProperty";

              return (
                <motion.tr
                  key={booking.id}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.3 }}
                  className="border-t border-gray-700 hover:bg-gray-800"
                  onClick={() => handleBookingOpen(booking)}
                >
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-100">
                    <div className="text-sm font-semibold text-gray-200">
                      <p>
                        <span className="text-sm text-gray-400">Name: </span>
                        <span>
                          {booking.bookingData.bookerInfo.first_name}{" "}
                          {booking.bookingData.bookerInfo.last_name}
                        </span>
                      </p>
                      <p>
                        <span className="text-sm text-gray-400">Email: </span>
                        <span>{booking.bookingData.bookerInfo.email}</span>
                      </p>
                      <p>
                        <span className="text-sm text-gray-400">Phone: </span>
                        <span>
                          {booking.bookingData.bookerInfo.phone_number}
                        </span>
                      </p>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-100">
                    {booking.bookingData.name}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm">
                    <span
                      className={`px-2 py-1 inline-flex text-xs font-semibold rounded-full ${
                        booking.status === "completed"
                          ? "bg-green-400/30 text-green-300"
                          : "bg-yellow-400/30 text-yellow-300"
                      }`}
                    >
                      {booking.status.charAt(0).toUpperCase() +
                        booking.status.slice(1)}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                    {HotelAndGuestHouses && (
                      <div className="mt-4">
                        <p className="text-gray-400 font-semibold text-sm mb-2">
                          Rooms:
                        </p>
                        <ul className="ml-6 list-disc list-inside text-gray-200 space-y-2">
                          {booking.bookingData.bookedRooms.map(
                            (room, roomIndex) => (
                              <li key={roomIndex}>
                                <span className="font-medium text-gray-300">
                                  {room.roomType.split("|")[0]}
                                </span>
                                <ul className="ml-4 mt-2 list-circle list-inside text-gray-400 space-y-1">
                                  {[
                                    room.guests.adults > 0 &&
                                      `${room.guests.adults} Adults`,
                                    room.guests.children > 0 &&
                                      `${room.guests.children} Children`,
                                    room.guests.infants > 0 &&
                                      `${room.guests.infants} Infants`,
                                    room.guests.pets > 0 &&
                                      `${room.guests.pets} Pets`,
                                  ]
                                    .filter(Boolean)
                                    .map((guest, guestIndex) => (
                                      <li key={guestIndex} className="text-sm">
                                        {guest}
                                      </li>
                                    ))}
                                </ul>
                              </li>
                            )
                          )}
                        </ul>
                      </div>
                    )}

                    {BookingProperty && (
                      <div>
                        <p className="text-gray-400 font-semibold">Guests:</p>
                        <ul className="ml-4 list-disc list-inside text-gray-200">
                          {[
                            booking?.bookingData.guests.adults > 0 &&
                              `${booking?.bookingData.guests.adults} Adults`,
                            booking?.bookingData.guests.children > 0 &&
                              `${booking?.bookingData.guests.children} Children`,
                            booking?.bookingData.guests.infants > 0 &&
                              `${booking?.bookingData.guests.infants} Infants`,
                            booking?.bookingData.guests.pets > 0 &&
                              `${booking?.bookingData.guests.pets} Pets`,
                          ]
                            .filter(Boolean)
                            .map((guest, index) => (
                              <li key={index}>{guest}</li>
                            ))}
                        </ul>
                      </div>
                    )}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                    {booking.bookingData.checkIn &&
                      booking.bookingData.checkout && (
                        <div className="mt-4 space-y-2">
                          <div className="flex items-center">
                            <p className="text-gray-400 font-semibold text-sm">
                              Check-In:
                            </p>
                            <p className="text-gray-200 ml-2 text-sm">
                              {formatDate(booking.bookingData.checkIn)}
                            </p>
                          </div>
                          <div className="flex items-center">
                            <p className="text-gray-400 font-semibold text-sm">
                              Check-Out:
                            </p>
                            <p className="text-gray-200 ml-2 text-sm">
                              {formatDate(booking.bookingData.checkout)}
                            </p>
                          </div>
                        </div>
                      )}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                    {booking.status === "completed" ? (
                      <div className="text-xs text-gray-400">
                        <span>No Action Available</span>
                      </div>
                    ) : (
                      <button
                        className="text-green-300 hover:text-green-200 px-3 py-2 border border-green-300 rounded-lg shadow hover:bg-green-700/10 transition"
                        onClick={(e) => handleCompleteBooking(e, booking.id)}
                        disabled={booking.status === "completed"}
                      >
                        <Check size={18} />
                      </button>
                    )}
                  </td>
                </motion.tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <Modal
        centered
        title="Booking Details"
        open={isModalVisible}
        onCancel={handleModalClose}
        footer={null}
        width="75%"
      >
        {selectedBooking &&
          (() => {
            const HotelAndGuestHouses =
              selectedBooking.bookingData.type === "HotelAndGuestHouses";
            const BookingProperty =
              selectedBooking.bookingData.type === "BookingProperty";

            return (
              <div className="bg-white py-12 px-8 md:px-20 overflow-y-auto">
                <h1 className="text-sm font-semibold text-gray-700 mb-3">
                  Booking Details
                </h1>
                <h1 className="text-3xl font-bold text-gray-800 mb-8">
                  {selectedBooking?.bookingData.paymentTitle}
                </h1>
                <div className="space-y-8 pl-2">
                  {/* Booker's Information */}
                  <section>
                    <h2 className="text-sm font-semibold bg-gray-300 text-gray-700 py-2 px-6 rounded-md">
                      Booker's Information
                    </h2>
                    <div className="mt-4 text-gray-600 flex pl-6 py-2 text-sm items-center lg:w-1/2">
                      <div className="flex items-center w-1/3">
                        <User size={18} className="mr-2 text-gray-500" />
                        <span className="text-gray-500">Name</span>
                      </div>
                      <div className="flex justify-center">
                        <span className="text-gray-800 font-semibold pl-6 text-left">
                          {`${selectedBooking.bookingData.bookerInfo.first_name} ${selectedBooking.bookingData.bookerInfo.last_name}`}
                        </span>
                      </div>
                    </div>
                    <div className="text-gray-600 flex pl-6 py-2 text-sm items-center lg:w-1/2">
                      <span className="flex items-center w-1/3">
                        <Mail size={18} className="mr-2 text-gray-500" />
                        <span className="text-gray-500">Email</span>
                      </span>
                      <div className="flex justify-center">
                        <span className="text-gray-800 font-semibold pl-6 text-left">
                          {selectedBooking.bookingData.bookerInfo.email}
                        </span>
                      </div>
                    </div>
                    <div className="text-gray-600 flex pl-6 py-2 text-sm items-center lg:w-1/2">
                      <span className="flex items-center w-1/3">
                        <Phone size={18} className="mr-2 text-gray-500" />
                        <span className="text-gray-500">Phone</span>
                      </span>
                      <div className="flex justify-center">
                        <span className="text-gray-800 font-semibold pl-6 text-left">
                          {selectedBooking.bookingData.bookerInfo.phone_number}
                        </span>
                      </div>
                    </div>
                    <div className="text-gray-600 flex pl-6 py-2 text-sm items-center lg:w-1/2">
                      <span className="flex items-center w-1/3">
                        <MapPin size={18} className="mr-2 text-gray-500" />
                        <span className="text-gray-500">Location</span>
                      </span>
                      <div className="flex justify-center">
                        <span className="text-gray-800 font-semibold pl-6 text-left">
                          {`${selectedBooking.bookingData.bookerInfo.city}, ${selectedBooking.bookingData.bookerInfo.country}`}
                        </span>
                      </div>
                    </div>
                  </section>

                  {/* Hotel Information */}
                  <section>
                    <h2 className="text-sm font-semibold bg-gray-300 text-gray-700 py-2 px-6 rounded-md">
                      Hotel Information
                    </h2>
                    <div className="mt-4 text-gray-600 flex pl-6 py-2 text-sm items-center lg:w-1/2">
                      <span className="flex items-center w-1/3">
                        <Bed size={18} className="mr-2 text-gray-500" />
                        <span className="text-gray-500">Name</span>
                      </span>
                      <div className="flex justify-center">
                        <span className="text-gray-800 font-semibold pl-6 text-center">
                          {selectedBooking.bookingData.name}
                        </span>
                      </div>
                    </div>
                    <div className="text-gray-600 flex pl-6 py-2 text-sm items-center lg:w-1/2">
                      <span className="flex items-center w-1/3">
                        <MapPin size={18} className="mr-2 text-gray-500" />
                        <span className="text-gray-500">Location</span>
                      </span>
                      <div className="flex justify-center">
                        <span className="text-gray-800 font-semibold pl-6 text-center">
                          {`${
                            selectedBooking.bookingData.location?.name ||
                            "Unknown"
                          }, ${
                            selectedBooking.bookingData.location?.city ||
                            "Unknown"
                          }`}
                        </span>
                      </div>
                    </div>
                  </section>

                  {/* Booking Details */}
                  <section>
                    <h2 className="text-sm font-semibold bg-gray-300 text-gray-700 py-2 px-6 rounded-md">
                      Booking Details
                    </h2>
                    <ul className="mt-4 text-gray-600 space-y-2 pl-6 text-sm">
                      <li className="flex py-2 text-sm items-center lg:w-1/2">
                        <span className="flex items-center w-1/3">
                          <Clock size={18} className="mr-2 text-gray-500" />
                          <span className="text-gray-500">Booked at</span>
                        </span>
                        <div className="flex justify-center">
                          <span className="text-gray-800 font-semibold pl-6 text-center">
                            {detailedDateFormat(selectedBooking.createdAt)}
                          </span>
                        </div>
                      </li>

                      {BookingProperty && (
                        <li className="flex py-2 text-sm items-center lg:w-1/2">
                          <span className="flex items-center w-1/3">
                            <User size={18} className="mr-2 text-gray-500" />
                            <span className="text-gray-500">Guests</span>
                          </span>
                          <div className="flex justify-center">
                            <span className="text-gray-800 font-semibold pl-6 text-center">
                              {[
                                selectedBooking.bookingData.guests.adults > 0 &&
                                  `${selectedBooking.bookingData.guests.adults} Adults`,
                                selectedBooking.bookingData.guests.children >
                                  0 &&
                                  `${selectedBooking.bookingData.guests.children} Children`,
                                selectedBooking.bookingData.guests.infants >
                                  0 &&
                                  `${selectedBooking.bookingData.guests.infants} Infants`,
                                selectedBooking.bookingData.guests.pets > 0 &&
                                  `${selectedBooking.bookingData.guests.pets} Pets`,
                              ]
                                .filter(Boolean)
                                .join(", ")}
                            </span>
                          </div>
                        </li>
                      )}

                      {/* Price */}
                      {BookingProperty && (
                        <li className="flex py-2 text-sm items-center lg:w-1/2">
                          <span className="flex items-center w-1/3">
                            <Banknote
                              size={18}
                              className="mr-2 text-gray-500"
                            />
                            <span className="text-gray-500">Price</span>
                          </span>
                          <div className="flex justify-center">
                            <span className="text-gray-800 font-semibold pl-6 text-center">
                              {
                                selectedBooking.bookingData
                                  .bookingPriceExchanged
                              }{" "}
                              $
                            </span>
                          </div>
                        </li>
                      )}

                      {/* Rooms for Hotels */}
                      {HotelAndGuestHouses && (
                        <li className="flex py-2 text-sm">
                          <span className="flex items-center md:w-1/6">
                            <Bed size={18} className="mr-2 text-gray-500" />
                            <span className="text-gray-500">Rooms</span>
                          </span>
                          <div className="grid grid-cols-1 lg:grid-cols-2 text-gray-800 font-semibold pl-6 gap-4 w-9/12">
                            {selectedBooking.bookingData.bookedRooms.map(
                              (room, index) => (
                                <div
                                  key={index}
                                  className="flex flex-col bg-gray-200 p-4 rounded-lg w-full"
                                >
                                  <div className="flex items-center mb-4">
                                    <div className="h-10 w-10 flex items-center justify-center font-semibold text-base rounded-full bg-gray-400">
                                      {index + 1}
                                    </div>
                                    <div className="ml-4">
                                      <div className="font-semibold text-sm">
                                        {room.roomType.split("|")[0]}
                                      </div>
                                      <div className="text-xs text-gray-500">
                                        Price: {room.roomPriceExchanged} $ per
                                        night
                                      </div>
                                    </div>
                                  </div>

                                  <div className="flex">
                                    <span className="text-sm font-medium text-gray-700">
                                      Guests
                                    </span>
                                    <div className="text-sm text-gray-500 ml-4">
                                      {room.guests.adults > 0 && (
                                        <span>{room.guests.adults} Adults</span>
                                      )}
                                      {room.guests.children > 0 && (
                                        <>
                                          {room.guests.adults > 0 && ", "}
                                          <span>
                                            {room.guests.children} Children
                                          </span>
                                        </>
                                      )}
                                      {room.guests.infants > 0 && (
                                        <>
                                          {(room.guests.adults > 0 ||
                                            room.guests.children > 0) &&
                                            ", "}
                                          <span>
                                            {room.guests.infants} Infants
                                          </span>
                                        </>
                                      )}
                                      {room.guests.pets > 0 && (
                                        <>
                                          {(room.guests.adults > 0 ||
                                            room.guests.children > 0 ||
                                            room.guests.infants > 0) &&
                                            ", "}
                                          <span>{room.guests.pets} Pets</span>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </li>
                      )}

                      <li className="flex py-2 text-sm items-center lg:w-1/2">
                        <span className="flex items-center w-1/3">
                          <Calendar size={18} className="mr-2 text-gray-500" />
                          <span className="text-gray-500">Check-in</span>
                        </span>
                        <div className="flex justify-center">
                          <span className="text-gray-800 font-semibold pl-6 text-center">
                            {detailedDateFormat(
                              selectedBooking.bookingData.checkIn
                            )}
                          </span>
                        </div>
                      </li>

                      <li className="flex py-2 text-sm items-center lg:w-1/2">
                        <span className="flex items-center w-1/3">
                          <Calendar size={18} className="mr-2 text-gray-500" />
                          <span className="text-gray-500">Check-out</span>
                        </span>
                        <div className="flex justify-center">
                          <span className="text-gray-800 font-semibold pl-6 text-center">
                            {detailedDateFormat(
                              selectedBooking.bookingData.checkout
                            )}
                          </span>
                        </div>
                      </li>

                      <li className="flex py-2 text-sm items-center lg:w-1/2">
                        <span className="flex items-center w-1/3">
                          <DollarSign
                            size={18}
                            className="mr-2 text-gray-500"
                          />
                          <span className="text-gray-500">Total Paid</span>
                        </span>
                        <div className="flex justify-center">
                          <span className="text-gray-800 font-semibold pl-6 text-center">
                            {new Intl.NumberFormat().format(
                              (selectedBooking?.amountTotal / 100).toFixed(2)
                            )}{" "}
                            $
                          </span>
                        </div>
                      </li>

                      <li className="flex py-2 text-sm items-center lg:w-1/2">
                        <span className="flex items-center lg:w-1/3">
                          <Banknote size={18} className="mr-2 text-gray-500" />
                          <span className="text-gray-500">Payment Status</span>
                        </span>
                        <div className="flex justify-center">
                          <p className="text-green-500 font-semibold pl-6 text-center flex items-center">
                            <span>{selectedBooking.paymentStatus}</span>
                            <CheckCircle
                              size={14}
                              className="ml-2 text-green-500"
                            />
                          </p>
                        </div>
                      </li>
                    </ul>
                  </section>
                </div>
              </div>
            );
          })()}
      </Modal>
    </motion.div>
  );
}

export default BookingsTable;
