import React, { useEffect, useState, useMemo } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  Navigate,
} from "react-router-dom";
import Registration from "./components/common/registration/Registration";
import Home from "./pages/Home";
import Login from "./components/common/login/Login";
import Profile from "./pages/Profile";
import Navbar from "./components/common/NavBar/NavBar";
import FooterNav from "./components/common/NavBar/FooterNav";
import Footer from "./components/common/Footer/Footer";
import ErrorPage from "./components/common/error-page/ErrorPage";
import Saved from "./pages/Saved";
import ForgotPassword from "./components/common/forgot-password/ForgotPassword";
import { useAuth } from "./context/AuthContext";
import { ThemeProvider } from "./context/ThemeContext";
import PostDetail from "./components/posts/PostDetail";
import SphereView from "./components/posts/SphereView";
import Admin from "./admin/Admin";
import SearchPage from "./pages/SearchPage";
import Explore from "./pages/Explore";
import Messages from "./pages/Messages";
import RequestFormPage from "./pages/RequestFormPage";
import OutsideSphereView from "./components/posts/OutsideSphereView";
import SurroundingSphereView from "./components/posts/SurroundingSphereView";
import ContactButton from "./components/common/contact-button/ContactButton";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { db } from "./firebase";
import { Toaster } from "react-hot-toast";
import UserNotification from "./components/Notifications/UserNotification";
import Booking from "./components/posts/booking/Booking";
import SuccessPage from "./pages/SuccessPage";
import CancelPage from "./pages/CancelPage";
import BookingAndInvoices from "./pages/BookingAndInvoices";
import ETBCheckout from "./components/posts/booking/ETBCheckout";

const pagesToHide = {
  navbar: [
    "/login",
    "/register",
    "/forgot-password",
    "/admin",
    "/messages",
    "/request-form",
    "/post/booking",
    "/success",
  ],
  footer: ["/admin", "/messages", "/post/booking", "/success"],
  footerNav: ["/admin", "/messages"],
  contactButton: [
    "/login",
    "/register",
    "/forgot-password",
    "/admin",
    "/messages",
    "/request-form",
    "/post",
    "/success",
  ],
};

const isHiddenOnCurrentPage = (paths, pathname) =>
  paths.some((path) => pathname.startsWith(path));

const Layout = ({ children }) => {
  const [conversations, setConversations] = useState([]);
  const { currentUser } = useAuth();
  const location = useLocation();

  useEffect(() => {
    if (currentUser) {
      const q = query(
        collection(db, "conversations"),
        where("customerId", "==", currentUser.uid),
        where("adminLastMessageStatus", "==", "unread")
      );

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const unreadConvos = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setConversations(unreadConvos);
      });

      return () => unsubscribe();
    }
  }, [currentUser]);

  const unreadCount = useMemo(
    () =>
      conversations.filter(
        (conversation) => conversation.adminLastMessageStatus === "unread"
      ).length,
    [conversations]
  );

  const shouldHideNavbar = useMemo(
    () => isHiddenOnCurrentPage(pagesToHide.navbar, location.pathname),
    [location.pathname]
  );

  const shouldHideFooter = useMemo(
    () => isHiddenOnCurrentPage(pagesToHide.footer, location.pathname),
    [location.pathname]
  );

  const shouldHideFooterNav = useMemo(
    () => isHiddenOnCurrentPage(pagesToHide.footerNav, location.pathname),
    [location.pathname]
  );

  const shouldHideContactButton = useMemo(
    () => isHiddenOnCurrentPage(pagesToHide.contactButton, location.pathname),
    [location.pathname]
  );

  return (
    <>
      {!shouldHideNavbar && <Navbar unreadCount={unreadCount} />}
      <UserNotification conversations={conversations} />
      {children}
      {!shouldHideFooter && <Footer />}
      {!shouldHideContactButton && <ContactButton />}
      {!shouldHideFooterNav && <FooterNav unreadCount={unreadCount} />}
    </>
  );
};

function App() {
  const { currentUser } = useAuth();

  return (
    <ThemeProvider>
      <div className="relative bg-backgroundColor dark:bg-backgroundDark transition-colors duration-300 font-Quicksand">
        <Toaster />
        <Router>
          <Layout>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Registration />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/" element={<Home />} />
              <Route path="/explore" element={<Explore />} />
              <Route path="/post/:id" element={<PostDetail />} />
              <Route path="/search/:searchWord" element={<SearchPage />} />
              <Route
                path="/messages"
                element={currentUser ? <Messages /> : <Navigate to="/login" />}
              />
              <Route
                path="/request-form"
                element={
                  currentUser ? <RequestFormPage /> : <Navigate to="/login" />
                }
              />
              <Route
                path="/saved"
                element={currentUser ? <Saved /> : <Navigate to="/login" />}
              />
              <Route
                path="/post/:postId/:roomId/3D-tour"
                element={<SphereView />}
              />
              <Route
                path="/post/:postId/outside/3D-tour"
                element={<OutsideSphereView />}
              />
              <Route
                path="/post/:postId/surrounding/3D-tour"
                element={<SurroundingSphereView />}
              />
              <Route path="/post/booking" element={<Booking />} />
              <Route path="/ETB-checkout" element={<ETBCheckout />} />
              <Route
                path="/profile"
                element={currentUser ? <Profile /> : <Navigate to="/login" />}
              />
              <Route
                path="/success"
                element={
                  currentUser ? <SuccessPage /> : <Navigate to="/login" />
                }
              />
              <Route
                path="/cancel"
                element={
                  currentUser ? <CancelPage /> : <Navigate to="/login" />
                }
              />
              <Route
                path="/booking-and-invoices"
                element={
                  currentUser ? (
                    <BookingAndInvoices />
                  ) : (
                    <Navigate to="/login" />
                  )
                }
              />

              {/* Admin routes */}
              <Route path="/admin/*" element={<Admin />} />
              <Route path="/*" element={<ErrorPage />} />
            </Routes>
          </Layout>
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
