import { useEffect, useState } from "react";
import { CheckCircle, Clock } from "lucide-react";
import { motion } from "framer-motion";
import {
  collection,
  onSnapshot,
  query,
  orderBy,
  limit,
  Timestamp,
} from "firebase/firestore";

import Header from "../components/common/Header";
import StatCard from "../components/common/StatCard";
import BookingsTable from "../components/bookings/BookingsTable";
import { db } from "../../firebase";

const Bookings = () => {
  const [stats, setStats] = useState({
    newBookings: 0,
    completedBookings: 0,
  });

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const q = query(
      collection(db, "Bookings"),
      orderBy("createdAt", "desc"),
      limit(40)
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      let newBookings = 0;
      let completedBookings = 0;
      const allData = [];

      snapshot.docs.forEach((doc) => {
        const docData = doc.data();
        const createdAt =
          docData.createdAt instanceof Timestamp
            ? docData.createdAt.toDate().toISOString()
            : docData.createdAt;

        allData.push({
          id: doc.id,
          ...docData,
          createdAt,
        });

        if (docData.status === "pending") {
          newBookings += 1;
        } else if (docData.status === "completed") {
          completedBookings += 1;
        }
      });

      setStats({
        newBookings,
        completedBookings,
      });

      setData(allData);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  return (
    <div className="flex-1 relative z-10 overflow-auto">
      <Header title={"Bookings"} />

      <main className="max-w-7xl mx-auto py-6 px-4 lg:px-8">
        <motion.div
          className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4 mb-8"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          <StatCard
            name="New Bookings"
            icon={Clock}
            value={stats.newBookings}
            color="#F59E0B"
          />
          <StatCard
            name="Completed Bookings"
            icon={CheckCircle}
            value={stats.completedBookings}
            color="#10B981"
          />
        </motion.div>

        <div>
          <BookingsTable data={data} loading={loading} />
        </div>
      </main>
    </div>
  );
};

export default Bookings;
