import { doc, getDoc } from "firebase/firestore";
import { Check, ChevronLeft, CreditCard, DollarSign } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { db } from "../../../firebase";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { getLocalizedText } from "../../../hooks/langSeparator";
import Loader from "../../common/Loader/Loader";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../firebase";
import { loadStripe } from "@stripe/stripe-js";
import { useAuth } from "../../../context/AuthContext";
import { squircle } from "ldrs";
import LoginModal from "../../common/login-modal/LoginModal";

squircle.register();

function Booking() {
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [chosenCurrency, setChosenCurrency] = useState("ETB");
  const [bookerInfo, setBookerInfo] = useState({
    first_name: "",
    last_name: "",
    phone_number: "",
    email: "",
    country: "",
    city: "",
  });
  const [errorMessage, setErrorMessage] = useState({});
  const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const { currentUser } = useAuth();
  const { state: bookingData } = location;
  const navigate = useNavigate();

  const params = useParams();

  const fallbackData = {
    checkIn: params.checkIn || "",
    checkOut: params.checkOut || "",
    guests: JSON.parse(params.guests || "{}"),
    bookedRooms: JSON.parse(params.bookedRooms || "[]"),
    postId: params.postId || null,
    currency: params.currency || "ETB",
    bookingPrice: params.bookingPrice || 0,
    bookingPriceExchanged: params.bookingPriceExchanged || 0,
  };

  const {
    checkIn,
    checkOut,
    bookedRooms = [],
    guests = {},
    postId,
    currency,
    bookingPrice,
    bookingPriceExchanged,
  } = bookingData || fallbackData;

  useEffect(() => {
    const fetchPost = async () => {
      const docRef = doc(db, "posts", postId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setPost(docSnap.data());
      } else {
        toast.error("Post Not Found!");
      }
      setLoading(false);
    };

    fetchPost();
  }, [postId]);

  useEffect(() => {
    if (currency === "USD") {
      setSelectedPaymentMethod("USD_Payment_Options");
    } else {
      setSelectedPaymentMethod("ETB_Payment_Options");
    }
  }, [currency]);

  useEffect(() => {
    setChosenCurrency(currency);
  }, [currency]);

  const handlePaymentMethodSelection = (method) => {
    if (method === "USD_Payment_Options") {
      setChosenCurrency("USD");
    } else {
      setChosenCurrency("ETB");
    }
    setSelectedPaymentMethod(method);
  };

  const handleCheckboxChange = () => {
    if (!isChecked) {
      setShowModal(true);
    } else {
      setIsChecked(!isChecked);
    }
  };
  const HotelAndGuestHouses =
    post?.category === "Hotels" || post?.category === "Guest House";

  const BookingProperty = post?.purchaseType === "Booking";

  const calculateNights = () => {
    if (checkIn && checkOut) {
      const start = new Date(checkIn).getTime();
      const end = new Date(checkOut).getTime();
      if (!isNaN(start) && !isNaN(end)) {
        const diffInTime = end - start;
        return Math.ceil(diffInTime / (1000 * 3600 * 24));
      }
    }
    return 0;
  };

  const nights = calculateNights();

  const getName = () => getLocalizedText(post?.name, language);

  const totalPrice = HotelAndGuestHouses
    ? bookedRooms.reduce((total, room) => {
        const roomTotal =
          chosenCurrency === "USD"
            ? room.roomPriceExchanged * nights
            : room.roomPrice * nights;
        return total + roomTotal;
      }, 0)
    : (chosenCurrency === "USD" ? bookingPriceExchanged : bookingPrice) *
      nights;

  const guestSummary = (guests) => {
    return guests.adults === 1 &&
      guests.children === 0 &&
      guests.infants === 0 &&
      guests.pets === 0
      ? "1 Guest"
      : `${guests.adults} Adult${guests.adults > 1 ? "s" : ""} ${
          guests.children
            ? `, ${guests.children} Child${guests.children > 1 ? "ren" : ""}`
            : ""
        }${
          guests.infants
            ? `, ${guests.infants} Infant${guests.infants > 1 ? "s" : ""}`
            : ""
        }${
          guests.pets ? `, ${guests.pets} Pet${guests.pets > 1 ? "s" : ""}` : ""
        }`;
  };

  const paymentTitle = `${nights || 0} ${
    nights === 1 ? "Night" : "Nights"
  } at ${getName() || "Your Booking"}`;

  const validateForm = () => {
    let errors = {};
    if (!bookerInfo.first_name.trim()) {
      errors.first_name = "Please enter your first name.";
    }

    if (!bookerInfo.last_name.trim()) {
      errors.last_name = "Please enter your last name.";
    }

    if (!bookerInfo.email.trim()) {
      errors.email = "Please enter your email.";
    }

    if (!bookerInfo.phone_number.trim()) {
      errors.phone_number = "Please enter your phone number.";
    }

    if (!bookerInfo.country.trim()) {
      errors.country = "Please enter your country.";
    }

    if (!bookerInfo.city.trim()) {
      errors.city = "Please enter your city.";
    }

    return errors;
  };

  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
  );

  const handleBook = async (e) => {
    e.preventDefault();

    if (!currentUser) {
      setIsLoginModalVisible(true);
    } else {
      const errors = validateForm();

      if (Object.keys(errors).length === 0) {
        setIsLoading(true);
        setErrorMessage({});
        if (chosenCurrency === "USD") {
          if (!stripePromise) {
            toast.error("Stripe failed to initialize.");
            setIsLoading(false);
            return;
          }

          try {
            const stripe = await stripePromise;
            if (!stripe) {
              toast.error("Stripe failed to initialize.");
              setIsLoading(false);
              return;
            }

            const commonBookingData = {
              paymentTitle: paymentTitle,
              checkIn: checkIn,
              checkout: checkOut,
              postId: postId,
              name: getName(),
              nights: nights,
              bookerInfo: {
                first_name: bookerInfo.first_name,
                last_name: bookerInfo.last_name,
                phone_number: bookerInfo.phone_number,
                email: bookerInfo.email,
                country: bookerInfo.country,
                city: bookerInfo.city,
              },
              uid: currentUser.uid || "",
              postImg: post?.outside?.normalImages[0],
              location: post?.location,
            };

            let bookingData;
            if (HotelAndGuestHouses) {
              bookingData = {
                ...commonBookingData,
                bookedRooms: bookedRooms,
                type: "HotelAndGuestHouses",
              };
            } else if (BookingProperty) {
              bookingData = {
                ...commonBookingData,
                guests: guests,
                bookingPrice: bookingPrice,
                bookingPriceExchanged: bookingPriceExchanged,
                type: "BookingProperty",
              };
            }

            const createStripeCheckout = httpsCallable(
              functions,
              "createStripeCheckout"
            );
            const response = await createStripeCheckout({
              price: totalPrice.toFixed(2),
              paymentTitle: paymentTitle,
              bookingData: bookingData,
            });

            if (response.data && response.data.id) {
              await stripe.redirectToCheckout({ sessionId: response.data.id });
            } else {
              toast.error("Stripe session ID not returned in the response.");
            }
          } catch (error) {
            toast.error("Error during checkout:", error.message);
          } finally {
            setIsLoading(false);
          }
        } else if (chosenCurrency === "ETB") {
          navigate("/ETB-checkout");
          setIsLoading(false);
        }
      } else {
        setErrorMessage(errors);
        setIsLoading(false);
      }
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="min-h-screen pb-20 bg-backgroundColor dark:bg-backgroundDark">
      {/* Header */}
      <nav className="sticky top-0 w-full shadow p-4 flex justify-between items-center bg-white dark:bg-gray-800 border-b border-gray-200 dark:border-gray-700">
        <button
          onClick={() => window.history.back()}
          className="text-gray-700 dark:text-white flex items-center space-x-2"
        >
          <ChevronLeft className="h-5 w-5" />
          <span>Back</span>
        </button>
        <h1 className="text-lg font-semibold text-gray-800 dark:text-white">
          Book
        </h1>
      </nav>

      <main className="max-w-7xl mx-auto py-6 px-4 lg:px-8">
        <section className="flex flex-col lg:flex-row items-center space-y-6 pb-4">
          <div className="lg:w-2/5 w-full space-y-8 p-6 border-b-2 border-gray-300 dark:border-gray-700 lg:border-b-0">
            <h2 className="text-xl font-bold text-gray-800 dark:text-white mb-4 pb-2 border-b border-gray-300 dark:border-gray-700">
              Booking Details
            </h2>
            <div className="space-y-6 ml-6">
              <div>
                <h3 className="font-semibold text-black dark:text-white mb-2">
                  Dates
                </h3>
                <div className="flex flex-col space-y-2 w-4/5 ml-5">
                  <div className="flex justify-between items-center">
                    <span className="text-sm font-medium text-gray-600 dark:text-gray-400">
                      Check-in
                    </span>
                    <span className="text-lg text-gray-800 dark:text-white">
                      {checkIn
                        ? new Intl.DateTimeFormat("en-US", {
                            day: "2-digit",
                            month: "short",
                            year: "numeric",
                          }).format(new Date(checkIn))
                        : "N/A"}
                    </span>
                  </div>
                  <div className="flex justify-between items-center">
                    <span className="text-sm font-medium text-gray-600 dark:text-gray-400">
                      Check-out
                    </span>
                    <span className="text-lg text-gray-800 dark:text-white">
                      {checkOut
                        ? new Intl.DateTimeFormat("en-US", {
                            day: "2-digit",
                            month: "short",
                            year: "numeric",
                          }).format(new Date(checkOut))
                        : "N/A"}
                    </span>
                  </div>
                </div>
              </div>

              {BookingProperty && (
                <div className="flex flex-col w-4/5">
                  <h3 className=" font-semibold text-black dark:text-white mb-2">
                    Guests
                  </h3>
                  <p className="pl-6 text-sm text-gray-800 dark:text-white">
                    {guestSummary(guests)}
                  </p>
                </div>
              )}

              {HotelAndGuestHouses && (
                <div>
                  <h3 className="font-semibold text-black dark:text-white mb-2 border-t border-gray-300 dark:border-gray-700 pt-2">
                    Rooms
                  </h3>
                  <ul className="space-y-1 text-gray-800 dark:text-white pl-5">
                    {bookedRooms.map((room, index) => {
                      const getRoomType = () =>
                        getLocalizedText(room?.roomType, language);

                      return (
                        <li key={index} className="py-2">
                          <div className="flex flex-row justify-between items-center">
                            <h4 className="text-base font-semibold">
                              {getRoomType()}
                            </h4>
                            <p className="text-xs text-gray-600 dark:text-gray-400">
                              Price:{" "}
                              <span className="font-medium text-gray-700 dark:text-gray-300">
                                {chosenCurrency === "USD"
                                  ? `${room.roomPriceExchanged} $`
                                  : `${room.roomPrice} ${t("birr")}`}
                              </span>
                            </p>
                          </div>

                          {/* Guests */}
                          <p className="mt-2 text-sm text-gray-600 dark:text-gray-400 ml-4">
                            Guests:{" "}
                            <span className="font-medium text-gray-800 dark:text-white">
                              {guestSummary(room.guests)}
                            </span>
                          </p>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
            </div>
          </div>

          <div className="flex flex-col space-y-2 lg:border-l-2 border-gray-300 dark:border-gray-700 p-6 lg:w-2/3">
            <div className="flex space-x-4 items-center mb-4">
              <img
                src={post?.outside.normalImages[0]}
                alt={getName()}
                className="w-44 h-24 object-cover overflow-hidden rounded-lg"
              />

              <div>
                <h2 className="text-xl font-bold text-gray-800 dark:text-white">
                  {getName()}
                </h2>

                <div className="mt-2 space-y-2">
                  <p className="text-gray-600 dark:text-gray-400">
                    {post?.category}
                  </p>
                </div>
              </div>
            </div>

            <div className="p-3 border-t border-gray-300 dark:border-gray-700">
              <h2 className="text-xl font-bold text-gray-800 dark:text-white">
                Price details
              </h2>

              {BookingProperty && (
                <div className="w-full py-2">
                  <div className="flex justify-between items-center mt-4 text-sm text-gray-800 dark:text-gray-300 pb-1 px-4">
                    {/* Price per night */}
                    <div>
                      {new Intl.NumberFormat().format(
                        chosenCurrency === "USD"
                          ? bookingPriceExchanged
                          : bookingPrice
                      )}{" "}
                      {chosenCurrency === "USD" ? "$" : t("birr")} X {nights}{" "}
                      nights
                    </div>

                    {/* Total Price */}
                    <div className="underline">
                      {new Intl.NumberFormat().format(totalPrice)}{" "}
                      {chosenCurrency === "USD" ? "$" : t("birr")}
                    </div>
                  </div>

                  {/* Total Price Display */}
                  <div className="flex justify-end px-4 mt-8 text-gray-800 dark:text-gray-200 space-x-2">
                    <strong>Total Price: </strong>
                    <span>
                      {new Intl.NumberFormat().format(totalPrice)}{" "}
                      {chosenCurrency === "USD" ? "$" : t("birr")}
                    </span>
                  </div>

                  {/* Includes Tax */}
                  <div className="flex justify-end text-xs px-4 text-gray-800 dark:text-gray-200">
                    Includes tax and service fee
                  </div>
                </div>
              )}

              {HotelAndGuestHouses && (
                <div>
                  {bookedRooms.map((room, index) => {
                    const roomPrice =
                      chosenCurrency === "USD"
                        ? room.roomPriceExchanged
                        : room.roomPrice;
                    const roomTotal = roomPrice * nights;

                    return (
                      <div
                        key={index}
                        className="flex justify-between items-center mt-4 text-sm text-gray-800 dark:text-gray-300 px-4"
                      >
                        <div>
                          {new Intl.NumberFormat().format(roomPrice)}{" "}
                          {chosenCurrency === "USD" ? "$" : t("birr")} X{" "}
                          {nights} nights
                        </div>

                        <div className="underline">
                          {new Intl.NumberFormat().format(roomTotal.toFixed(2))}{" "}
                          {chosenCurrency === "USD" ? "$" : t("birr")}
                        </div>
                      </div>
                    );
                  })}

                  <div className="flex justify-end px-4 mt-8 text-gray-800 dark:text-gray-200 space-x-2">
                    <strong>Total Price: </strong>
                    <span>
                      {new Intl.NumberFormat().format(totalPrice)}{" "}
                      {chosenCurrency === "USD" ? "$" : t("birr")}
                    </span>
                  </div>

                  <div className="flex justify-end text-xs text-gray-800 dark:text-gray-200">
                    Includes tax and service fee
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>

        <div className="w-full border-t-2 border-gray-300 dark:border-gray-700" />

        {/* info about the payer  */}
        <section className="p-4 mt-6">
          <div className="mt-6 lg:w-2/3">
            <h3 className="text-lg font-medium text-gray-800 dark:text-white">
              Booker Information
            </h3>
            <form className="space-y-8 mt-8">
              <div className="grid grid-cols-2 gap-4">
                <div className="relative z-0 w-full group">
                  <input
                    type="text"
                    id="first_name"
                    name="first_name"
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    value={bookerInfo.first_name}
                    onChange={(e) => {
                      setBookerInfo({
                        ...bookerInfo,
                        first_name: e.target.value,
                      });
                    }}
                    required
                    disabled={isLoading}
                  />
                  <label
                    htmlFor="first_name"
                    className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 peer-focus:text-blue-600 peer-focus:dark:text-blue-500"
                  >
                    First Name
                  </label>
                  {errorMessage.first_name && (
                    <p className="text-red-500 text-sm">
                      {errorMessage.first_name}
                    </p>
                  )}
                </div>

                <div className="relative z-0 w-full group">
                  <input
                    type="text"
                    id="last_name"
                    name="last_name"
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    value={bookerInfo.last_name}
                    onChange={(e) => {
                      setBookerInfo({
                        ...bookerInfo,
                        last_name: e.target.value,
                      });
                    }}
                    required
                    disabled={isLoading}
                  />
                  <label
                    htmlFor="last_name"
                    className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 peer-focus:text-blue-600 peer-focus:dark:text-blue-500"
                  >
                    Last Name
                  </label>
                  {errorMessage.last_name && (
                    <p className="text-red-500 text-sm">
                      {errorMessage.last_name}
                    </p>
                  )}
                </div>
              </div>

              {/* Phone Number and Email */}
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <div className="relative z-0 w-full group">
                  <input
                    type="tel"
                    id="phone_number"
                    name="phone_number"
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    value={bookerInfo.phone_number}
                    onChange={(e) => {
                      setBookerInfo({
                        ...bookerInfo,
                        phone_number: e.target.value,
                      });
                    }}
                    required
                    disabled={isLoading}
                  />
                  <label
                    htmlFor="phone_number"
                    className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 peer-focus:text-blue-600 peer-focus:dark:text-blue-500"
                  >
                    Phone Number
                  </label>
                  {errorMessage.phone_number && (
                    <p className="text-red-500 text-sm">
                      {errorMessage.phone_number}
                    </p>
                  )}
                </div>

                <div className="relative z-0 w-full group">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    value={bookerInfo.email}
                    onChange={(e) => {
                      setBookerInfo({ ...bookerInfo, email: e.target.value });
                    }}
                    required
                    disabled={isLoading}
                  />
                  <label
                    htmlFor="email"
                    className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 peer-focus:text-blue-600 peer-focus:dark:text-blue-500"
                  >
                    Email
                  </label>
                  {errorMessage.email && (
                    <p className="text-red-500 text-sm">{errorMessage.email}</p>
                  )}
                </div>
              </div>

              {/* Country and City */}
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <div className="relative z-0 w-full group">
                  <input
                    type="text"
                    id="country"
                    name="country"
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    value={bookerInfo.country}
                    onChange={(e) => {
                      setBookerInfo({ ...bookerInfo, country: e.target.value });
                    }}
                    required
                    disabled={isLoading}
                  />
                  <label
                    htmlFor="country"
                    className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 peer-focus:text-blue-600 peer-focus:dark:text-blue-500"
                  >
                    Country
                  </label>
                  {errorMessage.country && (
                    <p className="text-red-500 text-sm">
                      {errorMessage.country}
                    </p>
                  )}
                </div>

                <div className="relative z-0 w-full group">
                  <input
                    type="text"
                    id="city"
                    name="city"
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    value={bookerInfo.city}
                    onChange={(e) => {
                      setBookerInfo({ ...bookerInfo, city: e.target.value });
                    }}
                    required
                    disabled={isLoading}
                  />
                  <label
                    htmlFor="city"
                    className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 peer-focus:text-blue-600 peer-focus:dark:text-blue-500"
                  >
                    City
                  </label>
                  {errorMessage.city && (
                    <p className="text-red-500 text-sm">{errorMessage.city}</p>
                  )}
                </div>
              </div>

              {/* Payment Method Section */}
              <div className="mt-8">
                <h3 className="text-sm font-medium text-gray-800 dark:text-gray-200 mb-4">
                  Choose Your Payment Method
                </h3>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                  {/* Stripe Payment (USD) */}
                  <button
                    type="button"
                    onClick={() =>
                      handlePaymentMethodSelection("USD_Payment_Options")
                    }
                    className={`flex items-center p-4 border rounded-lg transition-colors ${
                      selectedPaymentMethod === "USD_Payment_Options"
                        ? "border-blue-600 bg-blue-300 dark:bg-blue-900"
                        : "border-gray-300 dark:border-gray-600"
                    } hover:border-blue-600 dark:hover:border-blue-500`}
                    disabled={isLoading}
                  >
                    <div className="flex items-center space-x-3">
                      <div className="flex items-center space-x-1 text-blue-600 dark:text-blue-300">
                        <DollarSign className="w-5 h-5" />
                        <CreditCard className="w-5 h-5" />
                      </div>
                      <div>
                        <h4 className="text-sm font-medium text-gray-800 dark:text-gray-200">
                          Pay with Card
                        </h4>
                        <p className="text-xs text-gray-500 dark:text-gray-400">
                          Payment in USD
                        </p>
                      </div>
                    </div>
                  </button>

                  {/* ETB Payment */}
                  <button
                    type="button"
                    onClick={() =>
                      handlePaymentMethodSelection("ETB_Payment_Options")
                    }
                    className={`flex items-center p-4 border rounded-lg transition-colors ${
                      selectedPaymentMethod === "ETB_Payment_Options"
                        ? "border-blue-600 bg-blue-300 dark:bg-blue-900"
                        : "border-gray-300 dark:border-gray-600"
                    } hover:border-blue-600 dark:hover:border-blue-500`}
                    disabled={isLoading}
                  >
                    <div className="flex items-center space-x-3">
                      <span
                        className={`text-lg font-semibold ${
                          selectedPaymentMethod === "ETB_Payment_Options"
                            ? "text-blue-600 dark:text-blue-300"
                            : "text-gray-800 dark:text-gray-200"
                        }`}
                      >
                        ETB
                      </span>
                      <div>
                        <h4 className="text-sm font-medium text-gray-800 dark:text-gray-200">
                          Pay with Tele Birr or CBE
                        </h4>
                        <p className="text-xs text-gray-500 dark:text-gray-400">
                          Payment in ETB
                        </p>
                      </div>
                    </div>
                  </button>
                </div>
              </div>

              {/* Agreement Checkbox */}
              <div className="flex items-center space-x-2">
                <label
                  htmlFor="agreement"
                  className="flex items-center cursor-pointer space-x-2 text-sm text-gray-700 dark:text-gray-300"
                >
                  <div className="relative flex items-center justify-center">
                    <input
                      type="checkbox"
                      id="agreement"
                      onChange={handleCheckboxChange}
                      checked={isChecked}
                      className="hidden peer"
                    />
                    <div className="w-6 h-6 inline-flex items-center justify-center border-2 border-gray-300 dark:border-gray-600 rounded-md peer-checked:bg-mainColor peer-checked:border-mainColor transition-colors duration-200 ease-in-out">
                      {isChecked && <Check className="w-4 h-4 text-white" />}
                    </div>
                  </div>
                  <span>I agree to the payment and checkout policy</span>
                </label>
              </div>

              {/* Submit Button */}
              <button
                type="submit"
                onClick={handleBook}
                className="w-full bg-mainColor hover:bg-hovermainColor text-white font-bold py-3 rounded-lg shadow-lg disabled:bg-gray-500"
                disabled={!isChecked || isLoading}
              >
                {isLoading ? (
                  <div className="flex justify-center items-center space-x-3">
                    <l-squircle
                      size="25"
                      stroke="5"
                      stroke-length="0.2"
                      bg-opacity="0.1"
                      speed="0.9"
                      color="#ffffff"
                    ></l-squircle>
                    <span>Processing...</span>
                  </div>
                ) : (
                  "Confirm Booking"
                )}
              </button>
            </form>
          </div>

          {/* Agreement Modal */}
          {showModal && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
              <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg w-11/12 max-w-md p-6">
                <h2 className="text-lg font-bold text-gray-800 dark:text-white">
                  Payment and Checkout Policy
                </h2>
                <p className="mt-4 text-sm text-gray-600 dark:text-gray-300">
                  By proceeding, you agree to our payment terms and conditions,
                  including cancellation policies, refund rules, and other
                  relevant details.
                </p>
                <div className="mt-6 flex justify-end space-x-4">
                  <button
                    type="button"
                    onClick={() => {
                      setShowModal(false);
                      setIsChecked(true);
                    }}
                    className="bg-mainColor hover:bg-hovermainColor text-white px-4 py-2 rounded-lg"
                  >
                    Accept
                  </button>
                  <button
                    type="button"
                    onClick={() => setShowModal(false)}
                    className="bg-gray-300 hover:bg-gray-400 dark:bg-gray-700 dark:hover:bg-gray-600 text-gray-800 dark:text-white px-4 py-2 rounded-lg"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          )}
        </section>
      </main>

      <LoginModal
        isLoginModalVisible={isLoginModalVisible}
        setIsLoginModalVisible={setIsLoginModalVisible}
      />
      <footer></footer>
    </div>
  );
}

export default Booking;
