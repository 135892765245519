import React, { useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

function DetailsImages({ allImages, setCurrentImageIndex, setLightboxOpen }) {
  const [loadingStates, setLoadingStates] = useState(
    Array(allImages.length).fill(true)
  );
  const [currentSlide, setCurrentSlide] = useState(0);

  const imagesPerSlide = 5;
  const startIdx = currentSlide * imagesPerSlide;
  const totalSlides = Math.ceil(allImages.length / imagesPerSlide);

  const handleNextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % totalSlides);
  };

  const handlePrevSlide = () => {
    setCurrentSlide((prev) => (prev === 0 ? totalSlides - 1 : prev - 1));
  };

  const openLightbox = (index) => {
    setCurrentImageIndex(index);
    setLightboxOpen(true);
  };

  const handleImageLoad = (index) => {
    setLoadingStates((prev) =>
      prev.map((loading, i) => (i === index ? false : loading))
    );
  };

  return (
    <div id="images" className="mb-6 relative">
      <div className="flex flex-col lg:flex-row lg:space-x-3">
        <div className="flex-1">
          <div className="relative">
            {loadingStates[startIdx] && (
              <div className="absolute inset-0 flex justify-center items-center bg-gray-500 bg-opacity-50 dark:bg-black dark:bg-opacity-50 overflow-hidden h-[200px] lg:h-[400px] rounded-sm mb-3 lg:mb-0">
                <TailSpin color="#00a3cc" height={30} width={30} />
              </div>
            )}
            <div className="overflow-hidden h-[200px] lg:h-[400px] rounded-sm mb-3 lg:mb-0">
              <img
                src={allImages[startIdx]}
                alt={`Post img ${startIdx + 1}`}
                className="w-full h-full object-cover rounded-md cursor-pointer transition-transform duration-300 hover:scale-[1.02]"
                onClick={() => openLightbox(startIdx)}
                onLoad={() => handleImageLoad(startIdx)}
                loading="lazy"
              />
            </div>
          </div>
          <button
            onClick={handlePrevSlide}
            className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-gray-900 text-white p-2 rounded-full shadow-md hover:bg-gray-600 z-10 focus:outline-none transition-colors duration-300"
          >
            <FaChevronLeft />
          </button>
          <button
            onClick={handleNextSlide}
            className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-gray-900 text-white p-2 rounded-full shadow-md hover:bg-gray-600 z-10 focus:outline-none transition-colors duration-300"
          >
            <FaChevronRight />
          </button>
        </div>

        <div className="flex-none w-full lg:w-1/2 grid grid-cols-2 gap-3">
          {allImages.slice(startIdx + 1, startIdx + 5).map((image, index) => (
            <div
              key={index}
              className="relative h-[195px] overflow-hidden rounded-md"
            >
              {loadingStates[startIdx + index + 1] && (
                <div className="absolute inset-0 flex justify-center items-center bg-gray-500 bg-opacity-50 dark:bg-black dark:bg-opacity-50 overflow-hidden w-full h-full object-cover">
                  <TailSpin color="#00a3cc" height={30} width={30} />
                </div>
              )}
              <img
                src={image}
                alt={`Thumbnail ${index + 1}`}
                className="w-full h-full object-cover cursor-pointer transition-transform duration-300 hover:scale-[1.02]"
                onClick={() => openLightbox(startIdx + index + 1)}
                onLoad={() => handleImageLoad(startIdx + index + 1)}
                loading="lazy"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default DetailsImages;
