import React, { useState, useEffect, useContext, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  serverTimestamp,
  setDoc,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";
import { PiShareFatFill } from "react-icons/pi";
import { FaTimes } from "react-icons/fa";
import { HiOutlineBookmark, HiMiniBookmark } from "react-icons/hi2";
import { ThemeContext } from "../../context/ThemeContext";
import toast from "react-hot-toast";
import { useAuth } from "../../context/AuthContext";
import PostCard from "./PostCard";
import LocationDetail from "./post-detail/LocationDetail";
import LoginModal from "../common/login-modal/LoginModal";
import YetAnotherLightbox from "yet-another-react-lightbox";
import {
  Counter,
  Fullscreen,
  Zoom,
  Thumbnails,
} from "yet-another-react-lightbox/plugins";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/counter.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import { useTranslation } from "react-i18next";
import DetailSection from "./post-detail/DetailSection";
import DetailsImages from "./post-detail/DetailsImages";
import ContactReservation from "./post-detail/ContactReservation";
import ShareModal from "../common/share-modal/ShareModal";
import { Modal } from "antd";
import { SendHorizontal } from "lucide-react";
import RoomGallarySlider from "./room-gallary/RoomGallarySlider";
import OutSurrGallarySlider from "./outside-surrounding-gallary/OutSurrGallarySlider";
import PostDetailSideBar from "./post-detail/PostDetailSideBar";
import PostDetailLoader from "../common/Loader/PostDetailLoader";
import SmallScreenNavbar from "./post-detail/SmallScreenNavbar";
import { getLocalizedText } from "../../hooks/langSeparator";
import { Helmet } from "react-helmet-async";
import logoIcon from "../../assets/image/logo.png";

function PostDetail() {
  const { id } = useParams();
  const { currentUser, firstName, lastName } = useAuth();
  const { theme } = useContext(ThemeContext);
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [saved, setSaved] = useState(false);
  const [isShareModalVisible, setIsShareModalVisible] = useState(false);
  const [isContactModalVisible, setIsContactModalVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
  ////////////////////////////
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  ////////////////////////////
  const [isRoomGallaryOpen, setIsRoomGallaryOpen] = useState(false);
  const [selectedRoomId, setSelectedRoomId] = useState(null);
  ////////////////////////////
  const [isOutSurrGallaryOpen, setIsOutSurrGallaryOpen] = useState(false);
  const [selectedOutSurr, setSelectedOutSurr] = useState(null);
  ///////////////////////////
  const [selectedRooms, setSelectedRooms] = useState([]);
  const [roomsIds, setRoomsIds] = useState([]);
  const [currency, setCurrency] = useState("ETB");
  const [exchangeRate, setExchangeRate] = useState(1);
  const [currencyLoading, setCurrencyLoading] = useState(false);
  ////////////////////////////
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const navigate = useNavigate();

  const isDarkMode = theme === "dark";

  useEffect(() => {
    const fetchPost = async () => {
      const docRef = doc(db, "posts", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setPost(docSnap.data());
      } else {
        toast.error("Post Not Found!");
      }
      setLoading(false);
    };

    fetchPost();
  }, [id]);

  /////////////////////////////////////////////////////////////////////////////////////////
  //==================================/ Saved check /=====================================//

  useEffect(() => {
    const checkIfSaved = async () => {
      const uid = currentUser?.uid;
      const savedDocRef = doc(db, "saved", id);
      try {
        const docSnapshot = await getDoc(savedDocRef);
        if (docSnapshot.exists() && docSnapshot.data().uid === uid) {
          setSaved(true);
        } else {
          setSaved(false);
        }
      } catch (error) {
        console.error("Error checking if post is saved:", error);
      }
    };

    checkIfSaved();
  }, [id, currentUser]);

  const handleSaveClick = async () => {
    if (currentUser) {
      const postId = id;
      const uid = currentUser?.uid;
      const savedDocRef = doc(db, "saved", postId);

      try {
        if (!saved) {
          await setDoc(savedDocRef, {
            uid: uid,
            createdAt: new Date().toISOString(),
          });
        } else {
          await deleteDoc(savedDocRef);
        }
        setSaved(!saved);
      } catch (error) {
        console.error("Error saving post:", error);
      }
    } else {
      setIsLoginModalVisible(true);
    }
  };

  const handleShareClick = () => {
    setIsShareModalVisible(true);
  };

  //*************************************************************************************//

  /////////////////////////////////////////////////////////////////////////////////////////
  //==================================/ Gallary /========================================//

  const toggleRoomGallary = (roomId) => {
    setIsRoomGallaryOpen((prev) => !prev);
    setSelectedRoomId(roomId);
  };

  const toggleOutSurrGallary = (outSurr) => {
    setIsOutSurrGallaryOpen((prev) => !prev);
    setSelectedOutSurr(outSurr);
  };

  //*************************************************************************************//

  /////////////////////////////////////////////////////////////////////////////////////////
  //==================================/ Booking /========================================//

  const getRoomIdsFromURL = useCallback(() => {
    const params = new URLSearchParams(window.location.search);
    return params.get("roomIds")?.split(",").map(Number).filter(Boolean) || [];
  }, []);

  useEffect(() => {
    const urlRoomIds = getRoomIdsFromURL();
    if (urlRoomIds.length > 0 && roomsIds.length === 0) {
      setRoomsIds(urlRoomIds);
    }
  }, [getRoomIdsFromURL, roomsIds]);

  const getCurrencyFromURL = useCallback(() => {
    const params = new URLSearchParams(window.location.search);
    return params.get("currency") || "ETB";
  }, []);

  useEffect(() => {
    const urlCurrency = getCurrencyFromURL();
    if (urlCurrency !== currency) {
      setCurrency(urlCurrency);
    }
  }, [getCurrencyFromURL, currency]);

  useEffect(() => {
    const fetchExchangeRate = async () => {
      setCurrencyLoading(true);
      try {
        const response = await fetch(
          "https://api.exchangerate-api.com/v4/latest/USD"
        );
        const data = await response.json();

        if (data && data.rates && data.rates.ETB) {
          setExchangeRate(data.rates.ETB);
        } else {
          setExchangeRate(1);
        }
      } catch (error) {
        console.error("Error fetching exchange rate:", error);
        setExchangeRate(1);
        toast.error("Failed to fetch exchange rate");
      } finally {
        setCurrencyLoading(false);
      }
    };
    fetchExchangeRate();
  }, [currency]);

  const addRoom = (roomId) => {
    // Update the roomsIds state
    const updatedRoomsIds = [...new Set([...roomsIds, roomId])];
    setRoomsIds(updatedRoomsIds);

    // Update the selectedRooms state
    setSelectedRooms((prevSelectedRooms) => {
      if (prevSelectedRooms.some((room) => room.roomId === roomId)) {
        return prevSelectedRooms; // Room is already added
      }

      // Find the room details
      const roomDetails = post?.rooms?.find((room) => room.roomId === roomId);
      if (roomDetails) {
        return [
          ...prevSelectedRooms,
          {
            ...roomDetails,
            roomPriceExchanged:
              currency === "USD"
                ? (roomDetails.roomPrice / exchangeRate).toFixed(2)
                : roomDetails.roomPrice,
            guests: { adults: 1, children: 0, infants: 0, pets: 0 },
          },
        ];
      }
      return prevSelectedRooms;
    });

    // Update the URL parameters
    const updatedParams = new URLSearchParams(window.location.search);
    updatedParams.set("roomIds", updatedRoomsIds.join(","));

    window.history.replaceState(
      null,
      "",
      `${window.location.pathname}?${updatedParams.toString()}`
    );
  };

  const removeRoom = (roomId) => {
    // Update the roomsIds
    const updatedRoomsIds = roomsIds.filter((id) => id !== roomId);
    setRoomsIds(updatedRoomsIds);

    // Update the selectedRooms
    setSelectedRooms((prevSelectedRooms) =>
      prevSelectedRooms.filter((room) => room.roomId !== roomId)
    );

    // Update the URL parameters
    const updatedParams = new URLSearchParams(window.location.search);
    if (updatedRoomsIds.length > 0) {
      updatedParams.set("roomIds", updatedRoomsIds.join(","));
    } else {
      updatedParams.delete("roomIds");
    }

    window.history.replaceState(
      null,
      "",
      `${window.location.pathname}?${updatedParams.toString()}`
    );
  };

  useEffect(() => {
    const initializeSelectedRooms = () => {
      const roomIds = roomsIds.length > 0 ? roomsIds : getRoomIdsFromURL();
      const updatedRooms = roomIds
        .map((roomId) => {
          const room = post?.rooms?.find((room) => room.roomId === roomId);
          if (room) {
            return {
              ...room,
              guests: { adults: 1, children: 0, infants: 0, pets: 0 },
            };
          }
          return null;
        })
        .filter(Boolean);

      setSelectedRooms(updatedRooms);
    };

    initializeSelectedRooms();
  }, [roomsIds, getRoomIdsFromURL, post?.rooms, currency, exchangeRate]);

  //*************************************************************************************//

  /////////////////////////////////////////////////////////////////////////////////////////
  //==================================/ Contact /========================================//

  const handleContactClick = () => {
    if (currentUser) {
      setIsContactModalVisible(true);
    } else {
      setIsLoginModalVisible(true);
    }
  };

  const handleContactModalClose = () => {
    setIsContactModalVisible(false);
  };

  const handleInputChange = (e) => {
    setMessage(e.target.value);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const chatId = id + currentUser.uid;
    const customerName = firstName + " " + lastName;

    try {
      const conversationsRef = collection(db, "conversations");
      const q = query(conversationsRef, where("chatId", "==", chatId));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const toastId = toast.loading(
          "Chat already exists, Forwarding you to messages page"
        );
        setTimeout(() => {
          navigate(`/messages`);
          toast.dismiss(toastId);
        }, 2000);
      } else {
        const conversationDocRef = await addDoc(
          collection(db, "conversations"),
          {
            chatId: chatId,
            customerId: currentUser.uid,
            customerName: customerName,
            postId: id,
            postName: post.name,
            postImg: post.outside.normalImages[0],
            status: "pending",
            customerLastMessageStatus: "unread",
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp(),
          }
        );

        await addDoc(
          collection(db, "conversations", conversationDocRef.id, "messages"),
          {
            senderId: currentUser.uid,
            message: message,
            createdAt: serverTimestamp(),
          }
        );
        setMessage("");
        handleContactModalClose();
        navigate(`/messages`);
      }
    } catch (error) {
      console.error("Error submitting: ", error);
      toast.error("Error submitting, please try again.");
    }
  };

  //*************************************************************************************//

  if (loading) {
    return (
      <>
        <SmallScreenNavbar />
        <PostDetailLoader />
      </>
    );
  }

  if (!post) {
    return (
      <div className="h-screen text-center text-xl font-bold py-20 flex justify-center items-center dark:text-gray-200 text-black">
        Post not found
      </div>
    );
  }

  const allImages = [
    ...post.outside.normalImages,
    ...post.rooms.flatMap((room) =>
      room.instances.flatMap((instance) => instance.normalImages)
    ),
    ...post.surrounding.normalImages,
  ];

  const allSlides = allImages.map((img) => ({
    src: img,
  }));

  //*************************************************************************************//

  /////////////////////////////////////////////////////////////////////////////////////////
  //=============================/For SEO and Open Graph/================================//

  const getName = () => getLocalizedText(post?.name, language);

  const stripHtmlTags = (html) => {
    if (!html) return "";
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  const getDescription = () => {
    const location = post?.location;
    const locationInfo = location
      ? ` Located in ${location.name}, ${location.city}.`
      : "";

    // First get the description and then strip HTML tags
    const description =
      getLocalizedText(post?.description, language) + locationInfo;

    // Return stripped description
    return stripHtmlTags(description);
  };

  const getOgImage = () => post?.outside?.normalImages?.[0] || logoIcon;

  const getStructuredData = () => {
    if (!post) return null;

    const location = post?.location;
    return {
      "@context": "https://schema.org",
      "@type": "Place",
      name: getName(),
      description: getDescription(),
      image: post?.outside?.normalImages?.[0],
      address: {
        "@type": "PostalAddress",
        streetAddress: location?.name || "Unknown Address",
        addressLocality: location?.city || "Unknown City",
      },
      geo: {
        "@type": "GeoCoordinates",
        latitude: location?.coordinates?.lat || "9.03", // Fallback value
        longitude: location?.coordinates?.long || "38.74", // Fallback value
      },
      url: window.location.href,
    };
  };

  return (
    <>
      {/* Helmet for SEO and Open Graph */}
      <Helmet>
        <title>{getName() || "Properties Details"}</title>
        <meta name="description" content={getDescription()} />
        <meta property="og:title" content={getName()} />
        <meta property="og:description" content={getDescription()} />
        <meta property="og:image" content={getOgImage()} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />

        {/* JSON-LD Structured Data */}
        <script type="application/ld+json">
          {JSON.stringify(getStructuredData())}
        </script>
      </Helmet>

      <div className="pt-20 mx-auto px-4 pb-8 max-w-7xl">
        <PostDetailSideBar />
        <SmallScreenNavbar post={post} language={language} />
        <div className="justify-end mr-4 flex space-x-4 mb-5">
          <button onClick={handleShareClick}>
            <PiShareFatFill className="text-gray-500 text-xl hover:text-gray-700" />
          </button>
          <button onClick={handleSaveClick}>
            {saved ? (
              <HiMiniBookmark className="text-mainColor text-xl" />
            ) : (
              <HiOutlineBookmark className="text-gray-500 text-xl hover:text-gray-700" />
            )}
          </button>
        </div>

        <DetailsImages
          allImages={allImages}
          setCurrentImageIndex={setCurrentImageIndex}
          setLightboxOpen={setLightboxOpen}
        />

        <div className="flex flex-col md:flex-row gap-8 border-t mt-12 pt-12 dark:border-gray-700">
          <DetailSection
            post={post}
            setPost={setPost}
            toggleRoomGallary={toggleRoomGallary}
            toggleOutSurrGallary={toggleOutSurrGallary}
            id={id}
            addRoom={addRoom}
            t={t}
            language={language}
            exchangeRate={exchangeRate}
            selectedRooms={selectedRooms}
            currency={currency}
            setCurrency={setCurrency}
            currencyLoading={currencyLoading}
            isDarkMode={isDarkMode}
          />

          <ContactReservation
            post={post}
            handleContactClick={handleContactClick}
            selectedRooms={selectedRooms}
            setSelectedRooms={setSelectedRooms}
            removeRoom={removeRoom}
            t={t}
            language={language}
            currency={currency}
            navigate={navigate}
            id={id}
          />
        </div>

        <LocationDetail post={post} isDarkMode={isDarkMode} />

        <LoginModal
          isLoginModalVisible={isLoginModalVisible}
          setIsLoginModalVisible={setIsLoginModalVisible}
        />
        <ShareModal
          setIsShareModalVisible={setIsShareModalVisible}
          isShareModalVisible={isShareModalVisible}
        />

        {/* ///Messaging modal//// */}
        <Modal
          centered
          open={isContactModalVisible}
          onCancel={handleContactModalClose}
          footer={null}
          closeIcon={
            <FaTimes className="text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100" />
          }
          styles={{
            content: {
              backgroundColor: isDarkMode ? "#1f2937" : "#ffffff",
              borderRadius: "25px",
            },
          }}
        >
          <h1 className="text-xl py-3 font-bold leading-tight tracking-tight text-gray-900 dark:text-gray-100 md:text-2xl">
            Contact us
          </h1>
          <form onSubmit={handleFormSubmit} className="">
            <div className="flex flex-col">
              <div onClick={handleContactModalClose} className="z-30">
                <PostCard post={post} id={id} />
              </div>

              <p className="flex mt-10 mb-4 p-2 bg-gray-400 dark:bg-gray-700 border-l-4 border-black rounded-lg lg:text-sm text-base justify-center text-center text-gray-700 dark:text-gray-300">
                Start a conversation about this post. Our team will respond to
                you in 3 min.
              </p>

              <div className=" flex flex-col space-y-4 p-4">
                <div className="relative flex justify-start">
                  <div className="absolute w-5 h-5 -left-[0.4rem] bottom-0 bg-gray-500 dark:bg-gray-600 rounded-br-2xl"></div>
                  <div className="absolute w-5 h-5 -left-[1.25rem] bottom-0 bg-white dark:bg-gray-800 rounded-br-xl"></div>
                  <div className="max-w-xs py-2 px-4 bg-gray-500 dark:bg-gray-600 text-white rounded-2xl">
                    <span className="block">Our team is waiting for you.</span>
                  </div>
                </div>

                <div className="relative flex justify-start">
                  <div className="absolute w-5 h-5 -left-[0.4rem] bottom-0 bg-gray-500 dark:bg-gray-600 rounded-br-2xl"></div>
                  <div className="absolute w-5 h-5 -left-[1.25rem] bottom-0 bg-white dark:bg-gray-800 rounded-br-xl"></div>
                  <div className="max-w-xs py-3 px-4 bg-gray-500 dark:bg-gray-600 text-white rounded-2xl flex items-center space-x-1">
                    <div className="w-2 h-2 rounded-full bg-white animate-flash"></div>
                    <div
                      className="w-2 h-2 rounded-full bg-white animate-flash "
                      style={{ animationDelay: "0.2s" }}
                    ></div>
                    <div
                      className="w-2 h-2 rounded-full bg-white animate-flash"
                      style={{ animationDelay: "0.4s" }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col mt-4">
              <div className="flex w-full space-x-4">
                <input
                  id="message"
                  name="message"
                  type="text"
                  placeholder="Enter message ..."
                  className="w-full p-2 h-10 rounded-lg resize-none bg-white border border-gray-500 dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600"
                  value={message}
                  onChange={handleInputChange}
                />
                <button
                  type="submit"
                  name="submit"
                  disabled={!message}
                  className="w-fit text-sm bg-mainColor text-white p-2 rounded-lg hover:bg-hovermainColor disabled:bg-gray-600"
                >
                  <SendHorizontal />
                </button>
              </div>
            </div>
          </form>
        </Modal>

        {/* //lightbox */}

        {lightboxOpen && (
          <YetAnotherLightbox
            plugins={[Counter, Fullscreen, Zoom, Thumbnails]}
            open={lightboxOpen}
            close={() => setLightboxOpen(false)}
            slides={allSlides}
            index={currentImageIndex}
            styles={{ root: { "--yarl__color_backdrop": "rgba(0, 0, 0, .8)" } }}
          />
        )}

        <RoomGallarySlider
          isRoomGallaryOpen={isRoomGallaryOpen}
          id={id}
          selectedRoomId={selectedRoomId}
          toggleRoomGallary={toggleRoomGallary}
        />

        <OutSurrGallarySlider
          isOutSurrGallaryOpen={isOutSurrGallaryOpen}
          id={id}
          selectedOutSurr={selectedOutSurr}
          toggleOutSurrGallary={toggleOutSurrGallary}
        />
      </div>
    </>
  );
}

export default PostDetail;
