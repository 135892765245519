import React, { useState } from "react";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../../../firebase";
import { CircleX } from "lucide-react";
import Editor from "./Editor";
import CurrencyInput from "react-currency-input-field";

function RoomForm({ room, onChange, HotelAndGuestHouses }) {
  const [loadingNormalImages, setLoadingNormalImages] = useState(
    Array(room.instances.length).fill(false)
  );
  const [loadingPanoramaImages, setLoadingPanoramaImages] = useState(
    Array(room.instances.length).fill(false)
  );

  const handleRoomTypeChange = (e) => {
    onChange({ ...room, roomType: e.target.value });
  };

  const handleRoomInstancesCountChange = (newCount) => {
    const updatedInstances = room.instances.slice(0, newCount);

    while (updatedInstances.length < newCount) {
      updatedInstances.push({
        instanceId: updatedInstances.length + 1,
        normalImages: [],
        panoramaImage: "",
      });
    }

    onChange({
      ...room,
      roomInstancesCount: newCount,
      instances: updatedInstances,
    });

    setLoadingNormalImages((prev) => [...prev, false].slice(0, newCount));
    setLoadingPanoramaImages((prev) => [...prev, false].slice(0, newCount));
  };

  const handleInstanceFileChange = async (instanceIndex, field, files) => {
    const fileURLs = [];
    if (field === "normalImages") {
      setLoadingNormalImages((prev) => {
        const newLoadingState = [...prev];
        newLoadingState[instanceIndex] = true;
        return newLoadingState;
      });
    } else {
      setLoadingPanoramaImages((prev) => {
        const newLoadingState = [...prev];
        newLoadingState[instanceIndex] = true;
        return newLoadingState;
      });
    }

    for (const file of files) {
      const storageRef = ref(storage, `PostImages/${file.name}`);
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);
      fileURLs.push(downloadURL);
    }

    const updatedInstances = room.instances.map((instance, idx) =>
      idx === instanceIndex
        ? { ...instance, [field]: [...instance[field], ...fileURLs] }
        : instance
    );

    onChange({ ...room, instances: updatedInstances });

    if (field === "normalImages") {
      setLoadingNormalImages((prev) => {
        const newLoadingState = [...prev];
        newLoadingState[instanceIndex] = false;
        return newLoadingState;
      });
    } else {
      setLoadingPanoramaImages((prev) => {
        const newLoadingState = [...prev];
        newLoadingState[instanceIndex] = false;
        return newLoadingState;
      });
    }
  };

  const removeImage = (instanceIndex, field, imageIndex) => {
    const updatedInstances = room.instances.map((instance, idx) => {
      if (idx === instanceIndex) {
        const updatedImages = instance[field].filter(
          (_, imgIdx) => imgIdx !== imageIndex
        );
        return { ...instance, [field]: updatedImages };
      }
      return instance;
    });
    onChange({ ...room, instances: updatedInstances });
  };

  const removeInstance = (instanceIndex) => {
    const updatedInstances = room.instances.filter(
      (_, idx) => idx !== instanceIndex
    );
    onChange({ ...room, instances: updatedInstances });

    handleRoomInstancesCountChange(updatedInstances.length);
  };

  return (
    <div className="mb-14">
      <label className="block text-sm font-medium text-gray-200 border-t-4 pt-4">
        Room Name
      </label>
      <input
        type="text"
        value={room.roomType}
        onChange={handleRoomTypeChange}
        className="mt-1 block w-full p-2 border border-gray-700 bg-gray-800 text-white rounded-md"
        placeholder="Enter room name"
      />

      <label className="block text-sm font-medium text-gray-200 mt-4">
        Number of {room.roomType}
      </label>
      <div className="flex items-center space-x-4">
        <button
          type="button"
          onClick={() => {
            const currentCount = room.roomInstancesCount || 0;
            if (currentCount > 0) {
              handleRoomInstancesCountChange(currentCount - 1);
            }
          }}
          className="px-4 py-2 bg-gray-700 text-white rounded-md hover:bg-gray-600"
        >
          -
        </button>

        <input
          type="number"
          value={room.roomInstancesCount || 0}
          onChange={(e) => {
            const count = Math.max(0, parseInt(e.target.value, 10));
            handleRoomInstancesCountChange(count);
          }}
          className="mt-1 block w-full p-2 border border-gray-700 bg-gray-800 text-white rounded-md text-center"
          placeholder="Instances"
          min={0}
        />

        <button
          type="button"
          onClick={() => {
            const currentCount = room.roomInstancesCount || 0;
            handleRoomInstancesCountChange(currentCount + 1);
          }}
          className="px-4 py-2 bg-gray-700 text-white rounded-md hover:bg-gray-600"
        >
          +
        </button>
      </div>

      {/* Render fields for each instance */}
      {room.instances.map((instance, instanceIndex) => (
        <div
          key={instance.instanceId}
          className="mt-10 py-4 border-t-2 border-gray-500"
        >
          <div className="flex justify-between p-2 bg-gray-700 rounded-t-lg">
            <h4 className="text-base mb-2 font-medium text-gray-200">
              {room.roomType} Instance {instanceIndex + 1}
            </h4>
            <button
              type="button"
              onClick={() => removeInstance(instanceIndex)}
              className="text-red-500"
            >
              <CircleX />
            </button>
          </div>
          <div className="flex space-x-4">
            <div className="flex flex-col w-1/2">
              <span className="block text-sm font-medium text-gray-200">
                Normal Images
              </span>

              <label>
                <div
                  className={`mt-1 flex items-center justify-center h-24 border border-gray-700 bg-gray-800 text-white rounded-md cursor-pointer ${
                    loadingNormalImages[instanceIndex] ? "opacity-50" : ""
                  }`}
                >
                  <input
                    type="file"
                    multiple
                    onChange={(e) =>
                      handleInstanceFileChange(
                        instanceIndex,
                        "normalImages",
                        e.target.files
                      )
                    }
                    className="hidden"
                    disabled={loadingNormalImages[instanceIndex]}
                  />
                  <span className="text-gray-400">Click to upload images</span>
                </div>
              </label>

              {loadingNormalImages[instanceIndex] && (
                <div className="mt-2 text-yellow-400">Uploading images...</div>
              )}
              {instance.normalImages.length > 0 && (
                <div className="mt-2">
                  {instance.normalImages.map((image, imgIndex) => (
                    <div
                      key={imgIndex}
                      className="flex items-center justify-between"
                    >
                      <img
                        src={image}
                        alt={`Normal ${imgIndex}`}
                        className="w-1/2 h-16 object-cover mb-2 border border-gray-300 rounded-lg"
                      />
                      <button
                        type="button"
                        onClick={() =>
                          removeImage(instanceIndex, "normalImages", imgIndex)
                        }
                        className="ml-2 text-red-500"
                      >
                        <CircleX />
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className="flex flex-col w-1/2">
              <span className="block text-sm font-medium text-gray-200">
                Panorama Image
              </span>

              <label>
                <div
                  className={`mt-1 flex items-center justify-center h-24 border border-gray-700 bg-gray-800 text-white rounded-md cursor-pointer ${
                    loadingPanoramaImages[instanceIndex] ? "opacity-50" : ""
                  }`}
                >
                  <input
                    type="file"
                    onChange={(e) =>
                      handleInstanceFileChange(
                        instanceIndex,
                        "panoramaImage",
                        e.target.files
                      )
                    }
                    className="hidden"
                    disabled={loadingPanoramaImages[instanceIndex]}
                  />
                  <span className="text-gray-400">
                    Click to upload panorama image
                  </span>
                </div>
              </label>

              {loadingPanoramaImages[instanceIndex] && (
                <div className="mt-2 text-yellow-400">
                  Uploading panorama...
                </div>
              )}
              {instance.panoramaImage && (
                <div className="mt-2 flex justify-between">
                  <img
                    src={instance.panoramaImage}
                    alt="Panorama"
                    className="w-fit h-44 object-cover border border-gray-300 rounded-lg"
                  />
                  <button
                    type="button"
                    onClick={() =>
                      removeImage(instanceIndex, "panoramaImage", 0)
                    }
                    className="ml-2 text-red-500"
                  >
                    <CircleX />
                  </button>
                </div>
              )}
            </div>
          </div>

          {HotelAndGuestHouses && (
            <div className="mt-10 space-y-6">
              <div className="flex space-x-6">
                <div className="flex-1">
                  <span className="text-sm font-medium text-gray-300">
                    Minimum number of booking days
                  </span>
                  <input
                    type="number"
                    value={room.roomMinDays}
                    onChange={(e) =>
                      onChange({ ...room, roomMinDays: e.target.value })
                    }
                    className="mt-2 block w-full p-3 border border-gray-600 bg-gray-800 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>

                <div className="flex-1">
                  <span className="text-sm font-medium text-gray-300">
                    Maximum number of guests
                  </span>
                  <input
                    type="number"
                    value={room.roomMaxGuests}
                    onChange={(e) =>
                      onChange({ ...room, roomMaxGuests: e.target.value })
                    }
                    className="mt-2 block w-full p-3 border border-gray-600 bg-gray-800 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>
              </div>

              <div>
                <span className="text-sm font-medium text-gray-300">
                  Room Pets Allowed
                </span>
                <div className="flex items-center mt-2">
                  <label
                    htmlFor="roomPetsAllowed"
                    className="relative inline-block w-12 mr-2 hover:cursor-pointer"
                  >
                    <input
                      type="checkbox"
                      id="roomPetsAllowed"
                      checked={room.roomPetsAllowed === "Yes"}
                      onChange={(e) =>
                        onChange({
                          ...room,
                          roomPetsAllowed: e.target.checked ? "Yes" : "No",
                        })
                      }
                      className="sr-only"
                    />
                    <span
                      className={`block w-10 h-6 rounded-full transition-all duration-300 ${
                        room.roomPetsAllowed === "Yes"
                          ? "bg-mainColor"
                          : "bg-gray-600"
                      }`}
                    ></span>
                    <span
                      className={`absolute left-0 top-0 w-6 h-6 bg-white rounded-full transition-all duration-300 transform ${
                        room.roomPetsAllowed === "Yes"
                          ? "translate-x-4"
                          : "-translate-x-1"
                      }`}
                    ></span>
                  </label>
                  <span
                    className={`text-sm ml-2 ${
                      room.roomPetsAllowed === "Yes"
                        ? "text-green-500"
                        : "text-red-500"
                    }`}
                  >
                    {room.roomPetsAllowed}
                  </span>
                </div>
              </div>

              {/* Room price */}
              <div className="my-4">
                <span className="text-sm font-medium text-gray-300">
                  Room Price
                </span>
                <CurrencyInput
                  name="roomPrice"
                  id="roomPrice"
                  value={room.roomPrice}
                  decimalsLimit={2}
                  onValueChange={(value) =>
                    onChange({ ...room, roomPrice: value })
                  }
                  className="mt-2 block w-full sm:w-1/2 p-3 border border-gray-600 bg-gray-800 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Enter room price"
                  prefix="Birr "
                  intlConfig={{ locale: "en-ET", currency: "ETB" }}
                />
              </div>
            </div>
          )}

          <div className="mt-8">
            <span className="text-sm font-medium text-gray-300">
              Room Description
            </span>
            <Editor
              value={room.roomDescription}
              onChange={(value) =>
                onChange({ ...room, roomDescription: value })
              }
            />
          </div>
        </div>
      ))}
    </div>
  );
}

export default RoomForm;
